import { AError } from "../../classes/AError.js";
import { AExportMap } from "../../classes/AExportMap.js";
import { genLegend } from "../../classes/ALegend.js";
import { AEngine } from "../../core/AEngine.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { ARouteDrawingService } from "../../services/ARouteDrawingService.js";
import { createMap, RequestMapRouteFull } from "../../utils/maps.js";
import { DeviceMultiOptions, generateTreeDropdown, mergeDeep } from "../../utils/tools.js";
const routeDrawingService = AEngine.get(ARouteDrawingService);
export class APage {
    get category() { return $('#Category').val(); }
    // window: { $window: JQuery<HTMLElement>; elements: JQuery<HTMLElement>[] }
    // form: AFormInstance<ARouteThresholds>
    constructor() {
        this.RouteList = [];
        this.SessionMarkers = {};
        this.map = createMap('map', {
            zoom: 14,
            streetViewControl: true
        });
    }
    async init() {
        FilterManager.load();
        nodeSessionService.bindSessionsToMap({
            interpolate: false,
            mapMarkers: this.SessionMarkers,
            map: this.map
        });
        await Loading.waitForPromises([
            generateTreeDropdown('#DeviceMulti', DeviceMultiOptions(), {
                useIndices: false,
                attributeToUse: 'unificationindex',
            }),
        ]);
        await mapHelperService.prepareMapItems(MAP_OPTIONS.All, {
            showLegend: false,
            showSearch: true,
            createLabel: true
        });
        this.legend = await genLegend({
            map: this.map,
            smallTitle: true,
            profileKey: this.category,
        });
        this.map.fit();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    // private async embedInRefreshForm(opt: { execute: (event: any|null, thresholds: ARouteThresholds) => Promise<void> }) {
    //   const isFirstRun = !(this.form || this.window)
    //   if (isFirstRun) {
    //     let formData: ARouteThresholds = {
    //       minGpsDistance: PageScript.minGpsDistance ?? 0.5,
    //       maxGpsDistance: PageScript.maxGpsDistance ?? 50,
    //       maxTimeDifference: PageScript.maxTimeDifference ?? 6.0
    //     }
    //     const form = new AFormInstance<ARouteThresholds>({
    //       ignoreWildcards: true,
    //       formInputs: [
    //         {id: 'minGpsDistance', type: 'number', step: "0.1" },
    //         {id: 'maxGpsDistance', type: 'number', step: "0.1" },
    //         {id: 'maxTimeDifference', type: 'number', step: "0.1" },
    //       ],
    //     })
    //     const $form = await form.generate({classList: 'column', translate: false, wrapInColumns: true})
    //     await form.injectFormData({ formData, triggerChange: false })
    //     await form.initFormValidation()
    //     let w = await purgatoryService.showInfoWindowSingleCustom({
    //       nest: $('#AjaxContent'),
    //       parent: $('body'),
    //       table: '',
    //       options: {height: '500px', buttons: [{id: 'btn1', text: 'Apply'}]},
    //       hardwire: true,
    //     })
    //     w.$window.find('.table-content').html('')
    //     w.$window.find('.table-content').append($form)
    //     w.$window.find('#btn1').on('click', (e) => {
    //       opt.execute(e, form.extractFormData({cleanData: true, setInternalFormData: true})).catch(AError.handle)
    //     })
    //     this.window = w
    //     this.form = form
    //   }
    //   await opt.execute(null, this.form.extractFormData({cleanData: true, setInternalFormData: true})).catch(AError.handle)
    // }
    refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        return Loading.waitForPromises(RequestMapRouteFull(mergeDeep({
            ShowRoute: true,
            Limit: filters.RouteLimit,
        }, filters))).then(async (routeRes) => {
            mapHelperService.destroy(this.RouteList);
            AEngine.log(`Destroyed ps.RouteList`);
            if (routeRes.Rows.length === 0) {
                return Alerts.noResults();
            }
            this.bounds = new google.maps.LatLngBounds();
            this.legend = await genLegend({
                map: this.map,
                smallTitle: true,
                profileKey: this.category,
            });
            this.RouteList = [];
            this.RouteList = await routeDrawingService.showMapRoute(this.map, routeRes, {
                legend: this.legend,
                category: this.category,
                bounds: this.bounds,
                // invisible: true,
            });
            // routeDrawingService.animateRoute().catch(AError.handleSilent)
            AEngine.log(`Populated ps.RouteList`);
            const exportMap = new AExportMap('gpsstats', { scales: true });
            exportMap.addLines(this.RouteList);
            exportMap.allowExport();
            this.map.fit(this.bounds);
        }).finally(() => {
            FilterManager.setActive(true);
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="DeviceMulti">Device</label>
          <div id="DeviceMulti" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="Category">Category</label>
          <select class="form-select" id="Category">
            <option value="speed">Speed</option>
            <option value="precision">Precision</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="RouteLimit" value="5000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child">
      <div id="map" class="aci-map"></div>
    </div>
  `);
}
