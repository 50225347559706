import { AError } from "../../classes/AError.js";
import { AExportMap } from "../../classes/AExportMap.js";
import { ADetectionState, AIllegallyParked, AParkingRight, AVerification } from "../../classes/AUnificationTypes.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { createMap, DefaultBounds, RequestMapScans, ShowMapScans } from "../../utils/maps.js";
import { DetectionsViewport } from "../../utils/query.js";
import { generateTreeDropdown } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.SessionMarkers = {};
        this.map = createMap('map', { zoom: 24 });
        this.markers = [];
        this.bounds = DefaultBounds();
    }
    async init() {
        FilterManager.load();
        await Loading.waitForPromises([
            generateTreeDropdown('#ParkingRight', new AParkingRight()),
            generateTreeDropdown('#Verification', new AVerification()),
            generateTreeDropdown('#IllegallyParked', new AIllegallyParked()),
            generateTreeDropdown('#DetectionState', new ADetectionState()),
        ]);
        await mapHelperService.prepareMapItems(MAP_OPTIONS.Default, {
            showLegend: true
        }).catch(AError.handle);
        this.map.fit();
        nodeSessionService.bindSessionsToMap({
            interpolate: false,
            mapMarkers: this.SessionMarkers,
            map: this.map
        });
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        mapHelperService.destroy(this.markers);
        this.bounds = DefaultBounds();
        Object.assign(filters, mapHelperService.getMapBounds(this.map));
        return Loading.waitForPromises(RequestMapScans(filters, DetectionsViewport)).then(response => {
            FilterManager.setActive(true);
            if (response.Rows.length === 0) {
                return Alerts.noResults();
            }
            const { map, bounds } = this;
            this.markers = ShowMapScans({
                response,
                map,
                bounds // Bounds for the map
            });
            const exportMap = new AExportMap('scans', { scales: true, markersArePolygons: true });
            // exportMap.addMarkers(this.Markers)
            exportMap.prepareCache();
            exportMap.allowExport();
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ParkingRight">ParkingRight</label>
          <div id="ParkingRight" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="Verification">Verification</label>
          <div id="Verification" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="IllegallyParked">IllegallyParked</label>
          <div id="IllegallyParked" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
    
        <div class="form-group">
          <label class="form-label" for="DetectionState">DetectionState</label>
          <div id="DetectionState" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="DeviceName">Device</label>
          <select class="form-select" id="DeviceName">
            <option value="%">All</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Area">Area</label>
          <select class="form-select" id="Area">
            <option value="%" SELECTED>All</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>

        <div class="form-group">
          <label class="form-switch">
            <input id="ShowRoute" type="checkbox">
            <i class="form-icon"></i> Show Route
          </label>
        </div>

        <div class="form-group">
          <label class="form-label" for="RouteLimit">Max Route Points</label>
          <input enabled-if="ShowRoute" class="form-input" type="number" id="RouteLimit" value="5000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child">
      <div id="map" class="aci-map"></div>
    </div>
  `);
}
