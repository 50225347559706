import { AResponse } from "../classes/AResponse.js";
import { AParkingRight } from "../classes/AUnificationTypes.js";
export const MYSQL_TRUE = `CAST('true' AS JSON)`;
export const MYSQL_FALSE = `CAST('false' AS JSON)`;
function AMapParseToSelect({ field, name }) {
    let colName = field.indexOf('.') !== -1 ? field : `\`${field}\``;
    if (field === name || name === undefined) {
        return colName;
    }
    if (field === '') {
        return `"" AS '${name ?? field}'`;
    }
    return `${colName} AS '${name ?? field}'`;
}
let ParkingPlaceDetections = (`
	SELECT DetectionId,DetectionDeviceId 
	FROM detections_geo_view g 
	INNER JOIN parkingspaces p ON(ST_Contains(p.Bounds,g.Pos)) 
	WHERE DetectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16) *1000) 
		and (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16) *1000)`).trim();
function AMakeQuery(Fields, filter, Joins) {
    return "SELECT df.DetectionId, df.DetectionDeviceId " + Fields + " FROM detections_final df " + Joins + " " + filter + " ORDER BY df.DetectionId";
}
function AMakeDetectionsFull(filter) {
    return AMakeQuery(DetectionFields + DetectionsFinalFields + DetectionsUserTagsFields + DetectionGeoFields + ParkingrightFields + VerificationFields + FineFields + ChannelFields, filter, DetectionsJoin + DetectionsUserTagsJoin + DetectionsGeoJoin + DetectionsGeoBoundsJoin + DetectionsGeoBoundsOriginalJoin + CorrectionsJoin + ParkingrightsJoin + VerificationsJoin);
}
function AMakeDetectionsFullImages(filter) {
    return AMakeQuery(DetectionFields + DetectionsFinalFields + DetectionsUserTagsFields + DetectionGeoFields + ParkingrightFields + VerificationFields + FineFields + PlateImageFields + ChannelFields, filter, DetectionsJoin + DetectionsUserTagsJoin + DetectionsGeoJoin + DetectionsGeoBoundsJoin + DetectionsGeoBoundsOriginalJoin + CorrectionsJoin + ParkingrightsJoin + VerificationsJoin + PlateImagesJoin);
}
function AMakeDetectionsPreGeoCorrection(filter) {
    return AMakeQuery(DetectionFields + DetectionsFinalFields + DetectionsUserTagsFields + DetectionGeoFields + DetectionGeoOriginalFields + ParkingrightFields + VerificationFields + FineFields + ChannelFields, filter, DetectionsJoin + DetectionsUserTagsJoin + DetectionsGeoJoin + DetectionsGeoBoundsJoin + DetectionsGeoBoundsOriginalJoin + CorrectionsJoin + ParkingrightsJoin + VerificationsJoin);
}
function AMakeFinesFull(filter) {
    return AMakeQuery(DetectionFields + DetectionsFinalFields + DetectionsUserTagsFields + DetectionGeoFields + ParkingrightFields + VerificationFields + FineFields + ChannelFields, filter, DetectionsJoin + DetectionsUserTagsJoin + DetectionsGeoJoin + DetectionsGeoBoundsJoin + DetectionsGeoBoundsOriginalJoin + CorrectionsJoin + ParkingrightsJoin + VerificationsJoin);
}
function AGetSelectFromQuery(PreparedQuery) {
    const prefix = "SELECT ";
    return PreparedQuery.substring(0, PreparedQuery.indexOf('FROM (SELECT df.DetectionId')).substr(prefix.length).split(', ');
}
// export function AGetSelectNamesFromQuery(PreparedQuery) {
//       const selectArray = AGetSelectFromQuery(PreparedQuery)
//       return selectArray.map(select => {
//             const parts = select.split(' AS ')
//             let output = (parts.length > 1) ? parts.pop() : select.split('.').pop()
//             return output.replace(/`+/g, '').trim()
//       })
// }
function ATestQueryResponseValuesInSet() {
    return Loading.waitForPromises(globalThis.requestService.query({
        Query: (`
				  SELECT * FROM (${DetectionsFullRanged}) b
				  LIMIT :Limit
			`),
        Params: {
            FromDate: '2017-08-01',
            ToDate: '2017-08-28',
            Limit: 5000
        }
    })).then(response => {
        const res = new AResponse(response);
        console.log(res);
        const possibleValues = {};
        res.loop((row) => {
            Object.keys(row).map(key => {
                if (!possibleValues.hasOwnProperty(key)) {
                    possibleValues[key] = new Set();
                }
                possibleValues[key].add(row[key]);
            });
        });
        console.log('values ', possibleValues);
    });
}
let UnificationTypes = ['Digital', 'TimeLimitedParking', 'IllegallyParked', 'ParkingRight', 'Verification', 'DetectionState'];
let DetectionsJoin = " LEFT JOIN detections                        d   USING (DetectionId,DetectionDeviceId) ";
let DetectionsUserTagsJoin = " LEFT JOIN detections_usertags               du  USING (DetectionId,DetectionDeviceId) ";
let DetectionsGeoJoin = " LEFT JOIN detections_geo_view               dg  USING (DetectionId,DetectionDeviceId) ";
let DetectionsGeoBoundsJoin = " LEFT JOIN detections_geo_bounds             gb  USING (DetectionId,DetectionDeviceId) ";
let DetectionsGeoBoundsOriginalJoin = " LEFT JOIN detections_geo_bounds_original    gbo USING (DetectionId,DetectionDeviceId) ";
let CorrectionsJoin = " LEFT JOIN corrections                       dc  USING (DetectionId,DetectionDeviceId,DetectionVersion) ";
let ParkingrightsJoin = " LEFT JOIN parkingrights                     p   USING (DetectionId,DetectionDeviceId,ParkingRightVersion) ";
let VerificationsJoin = " LEFT JOIN verifications                     v   USING (DetectionId,DetectionDeviceId,VerificationVersion) ";
let PlateImagesJoin = " LEFT JOIN plateimages                       pi  USING (DetectionId,DetectionDeviceId) ";
let CardinalDirection = "FLOOR(IF(d.Side='L',(d.ScanDeviceDirection+270+45)%360,(d.ScanDeviceDirection+90+45)%360)/90)";
let ParkingRightDef = new AParkingRight;
// TODO: Check which should be used for DetectionFields
let DetectionFields = ", d.VehicleType, d.DetectionValid, d.DetectionDevice, d.DetectionUser, d.SessionId, CAST(d.DetectionTime as DateTime) AS DetectionTime, TIME_FORMAT(CAST(d.DetectionTime as DateTime),\"%H %i %s\") AS Time, ROUND(d.Confidence,3) AS Confidence, d.DuplicateCodeLp, ROUND(d.DuplicateCodeLpConf,3) AS DuplicateCodeLpConf, IF(d.Side='R','→',IF(d.Side='L','←','-')) AS Side, d.CameraIds, d.BestCameraId, d.NearMatches, d.VehicleSpeed, ST_AsGeoJSON(IFNULL(gb.VehicleBounds,gbo.VehicleBounds)) AS VehicleBounds, d.VehicleBoundsJson, IF(d.VehicleMoving,'Yes','No') AS VehicleMoving, d.ScanDeviceLatitude, d.ScanDeviceLongitude, d.GpsPrecision, CONCAT(ROUND(FLOOR(d.GpsPrecision*10)/10.0,1),'/',ROUND(CEIL(d.GpsPrecision*10)/10.0,1)) AS GpsPrecisionGroup, d.GpsValid, d.ScanDeviceDirection, CASE " + CardinalDirection + " WHEN 0 THEN 'North' WHEN 1 THEN 'East' WHEN 2 THEN 'South' ELSE 'West' END AS CardinalDirection, DATE_FORMAT(d.DetectionTime,'%H') AS `Hour`, DATE_FORMAT(d.DetectionTime,'%Y-%m-%d') AS `Day`, DATE_FORMAT(d.DetectionTime,'%Y Week %v') AS `Week`, DATE_FORMAT(d.DetectionTime,'%M %Y') AS `Month`, DATE_FORMAT(d.DetectionTime,'%W') AS `Weekday`, WEEKDAY(d.DetectionTime) AS `WeekdayNumber` ";
// let DetectionFields            = ", d.VehicleType, d.DetectionDevice, d.DetectionUser, d.SessionId, CAST(d.DetectionTime as DateTime) AS DetectionTime, TIME_FORMAT(CAST(d.DetectionTime as DateTime),\"%H %i %s\") AS Time, ROUND(d.Confidence,3) AS Confidence, d.DuplicateCodeLp, ROUND(d.DuplicateCodeLpConf,3) AS DuplicateCodeLpConf, IF(d.Side='R','→',IF(d.Side='L','←','-')) AS Side, d.CameraIds, d.BestCameraId, d.NearMatches, d.VehicleSpeed, ST_AsGeoJSON(IFNULL(gb.VehicleBounds,gbo.VehicleBounds)) AS VehicleBounds, ST_AsGeoJSON(IFNULL(gb.VehicleBounds,gbo.VehicleBounds)) AS VehicleBoundsJson, IF(d.VehicleMoving,'Yes','No') AS VehicleMoving, d.ScanDeviceLatitude, d.ScanDeviceLongitude, d.GpsPrecision, CONCAT(ROUND(FLOOR(d.GpsPrecision*10)/10.0,1),'/',ROUND(CEIL(d.GpsPrecision*10)/10.0,1)) AS GpsPrecisionGroup, d.GpsValid, d.ScanDeviceDirection, CASE " + CardinalDirection + " WHEN 0 THEN 'North' WHEN 1 THEN 'East' WHEN 2 THEN 'South' ELSE 'West' END AS CardinalDirection, DATE_FORMAT(d.DetectionTime,'%H') AS `Hour`, DATE_FORMAT(d.DetectionTime,'%Y-%m-%d') AS `Day`, DATE_FORMAT(d.DetectionTime,'Week %v %Y') AS `Week`, DATE_FORMAT(d.DetectionTime,'%M %Y') AS `Month`, DATE_FORMAT(d.DetectionTime,'%W') AS `Weekday` ";
let DetectionsUserTagsFields = ", du.Label ";
let DetectionGeoFields = ", dg.IsIllegallyParked, dg.ParkingAreaType, dg.TaxRequired, dg.AreaId, IFNULL(dg.AreaName,'Unknown') AS Area, dg.AreaConfidence, dg.AreaAttributes, dg.ParkingSpaceId, IFNULL(dg.ParkingSpaceName,'Unknown') AS ParkingSpace, dg.ParkingSpaceConfidence, dg.ParkingSpaceAttributes, dg.SegmentId, dg.SegmentTimestamp, dg.SegmentConfidence, dg.SegmentAttributes, dg.ZoneId, IFNULL(dg.ZoneName,'Unknown') AS Zone, dg.ZoneConfidence, dg.ZoneTimestamp, dg.ZoneAttributes, dg.AddressId, IFNULL(dg.AddressName,'Unknown') AS Address, dg.AddressConfidence, dg.AddressTimeStamp, dg.AddressAttributes, dg.RegionId, IFNULL(dg.RegionName,'Unknown') AS Region, dg.RegionConfidence, dg.RegionTimeStamp, dg.RegionAttributes, dg.RouteAreaId, IFNULL(dg.RouteAreaName,'Unknown') AS RouteArea, dg.RouteAreaConfidence, dg.RouteAreaTimeStamp, dg.RouteAreaAttributes, dg.SegmentName as DetectionStreet, dg.WaySegmentTimeStamp is not null as MappedOnRoute, dg.SplitParkingSpaceTimeStamp is not null as UsedInOccupancy, dg.SplitParkingSpaceId ";
let DetectionGeoOriginalFields = ", ST_AsGeoJSON(gbo.VehicleBounds) AS OriginalVehicleBounds ";
let ParkingrightFields = ", IFNULL(p.VehicleCenterLatitude,d.VehicleCenterLatitude) AS VehicleCenterLatitude, IFNULL(p.VehicleCenterLongitude,d.VehicleCenterLongitude) AS VehicleCenterLongitude, p.HasParkingRight, IFNULL(p.LicensePlate,IFNULL(dc.LicensePlate,d.LicensePlate)) AS LicensePlate, IFNULL(p.CountryCode,IFNULL(dc.CountryCode,d.CountryCode)) AS CountryCode, p.ParkingRightType, p.ParkingRightResults, p.IsSuspect, p.MustFollowUp ";
let VerificationFields = ", v.VerificationResult, v.VerificationResultText, v.VerificationUser, v.VerificationDevice, v.VerificationStartTime, v.VerificationEndTime, v.VerificationLatitude, v.VerificationLongitude, v.OffenceCode, v.OffenceText, v.OffencePrice, v.OffenceDescription ";
let FineFields = `, v.FineNumber, IF(v.FineData IS NOT NULL, ${MYSQL_TRUE}, ${MYSQL_FALSE}) AS HasFineData, IF(v.FineImage IS NOT NULL, ${MYSQL_TRUE}, ${MYSQL_FALSE}) AS HasFineImage `;
let PlateImageFields = ", pi.Image ";
let DetectionsFinalFields = ", df.Digital, df.TimeLimitedParking, df.IllegallyParked, df.ParkingRight, df.Verification, df.DetectionState, df.Digital as keyDigital, df.TimeLimitedParking as keyTimeLimitedParking, df.IllegallyParked as keyIllegallyParked, df.ParkingRight as keyParkingRight, df.Verification as keyVerification, df.DetectionState as keyDetectionState, df.FinalVerificationUser, df.FinalVerificationDevice, df.FinalVerificationDistanceToVehicle, df.HourOfDay, df.FinalizedSuccess"
    //  + `, IF(df.ParkingRight='NoParkingRight' || df.ParkingRight between ${ParkingRightDef.Options.Indecisive.FirstIndex} and  ${ParkingRightDef.Options.Indecisive.LastIndex},'Non Paying Visitor', IF(df.ParkingRight between ${ParkingRightDef.Options.ParkingRight.Options.Visitor.FirstIndex} and  ${ParkingRightDef.Options.ParkingRight.Options.Visitor.LastIndex}, 'Paying Visitor', null)) AS Visitor`
    + `, IF(df.ParkingRight='NoParkingRight' || df.ParkingRight between ${ParkingRightDef.Options.Indecisive.FirstIndex} and  ${ParkingRightDef.Options.Indecisive.LastIndex},'Non Paying Visitor',IF(df.ParkingRight between ${ParkingRightDef.Options.ParkingRight.Options.Visitor.FirstIndex} and  ${ParkingRightDef.Options.ParkingRight.Options.Visitor.LastIndex},'Paying Visitor',null)) AS Visitor`;
let DetectionsFinalFieldsClienArr = [
    ...UnificationTypes.map(unify => {
        return { field: unify };
    }),
    { field: 'FinalVerificationUser' },
    { field: 'FinalVerificationDevice' },
    { field: 'FinalVerificationDistanceToVehicle' },
];
let DetectionsFinalFieldsClean = DetectionsFinalFieldsClienArr.map(AMapParseToSelect).join(', ');
// let DetectionsFinalFieldsClean = `, ${ UnificationTypes.join(', ') }, FinalVerificationUser, FinalVerificationDistanceToVehicle,`
let ChannelFields = ", df.VerificationChannel as ChannelCode, df.VerificationChannel ";
let filterTimeRange = "WHERE df.DetectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000)";
let filterViewport = "WHERE df.DetectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000) AND (ScanDeviceLatitude between :South AND :North) AND (ScanDeviceLongitude BETWEEN :West AND :East)";
let filterTimeRangeFine = "WHERE df.DetectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000) AND IF(IFNULL(:FineNumber, '')='', true, FineNumber LIKE :FineNumber) AND IF(Length(:LicensePlate)=0, true, d.LicensePlate LIKE :LicensePlate)";
let filterLicensePlate = "WHERE df.DetectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000) AND IF(IFNULL(:LicensePlate, '')='', true, d.LicensePlate LIKE CONCAT(:LicensePlate, '%'))"; // d.LicensePlate LIKE CONCAT('%', :LicensePlate, '%')";
let filterSearch = "WHERE df.DetectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000) AND IF(IFNULL(:DetectionId, '')='', true, :DetectionId = df.DetectionId) AND IF(IFNULL(:DetectionDeviceId, '')='', true, :DetectionDeviceId = df.DetectionDeviceId) and IF(IFNULL(:LicensePlate, '')='', true, d.LicensePlate LIKE CONCAT(:LicensePlate, '%'))";
let filterSingle = "WHERE df.DetectionId=:DetectionId AND df.DetectionDeviceId=:DetectionDeviceId";
// let FinesSelectField         = `DetectionDevice AS 'Device', Day, DetectionTime AS Time, LicensePlate AS 'LicensePlate', CountryCode AS 'Country', OffenceCode, OffenceText, OffenceDescription, OffencePrice, VerificationChannel, VerificationChannel as ChannelName, Zone, Area, DetectionUser AS 'D-User', ParkingRightType AS 'Right', ParkingAreaType, VerificationResultText AS 'Result', Address, VerificationUser AS 'V-User', ScanDeviceLatitude, ScanDeviceLongitude, VehicleCenterLatitude, VehicleCenterLongitude ${ DetectionsFinalFieldsClean } DetectionTime, DetectionValid, DetectionDeviceId, DetectionId`
// let ReportsSelectFields      = `DetectionDevice AS 'Device', Day, DetectionTime AS Time, LicensePlate AS 'LicensePlate', HasParkingRight, IsIllegallyParked, CountryCode AS 'Country', Confidence, Side, Zone, Area, RouteArea, DetectionUser AS 'D-User', VehicleMoving AS 'Moving', ParkingRightType AS 'Right', ParkingAreaType, VerificationResultText AS 'Result', Address, GpsPrecision, DuplicateCodeLp AS 'Dup', VerificationUser AS 'V-User', ScanDeviceLatitude, ScanDeviceLongitude, VehicleCenterLatitude, VehicleCenterLongitude ${ DetectionsFinalFieldsClean } DetectionTime, DetectionValid, DetectionDeviceId, DetectionId, OffenceCode, OffenceText, OffencePrice, OffenceDescription `
// TODO: Recreate column order logic
export const ReportSelectFieldsArr = [
    { field: 'DetectionDevice', name: `Device` },
    { field: 'Day' },
    { field: 'DetectionTime', name: `Time` },
    { field: 'Image', name: `Image` },
    { field: 'LicensePlate', name: `LicensePlate` },
    { field: 'DuplicateCodeLp', name: `Dup`, hidden: true },
    { field: 'CountryCode', name: `Country` },
    { field: 'VehicleMoving', name: `Moving` },
    { field: 'Address' },
    { field: 'ParkingRight' },
    { field: 'IllegallyParked' },
    { field: 'TimeLimitedParking' },
    { field: 'ParkingRightType', name: `Right` },
    { field: 'Verification' },
    { field: 'VerificationResultText', name: `Result` },
    { field: 'DetectionUser', name: `D-User` },
    { field: 'VerificationUser', name: `V-User` },
    { field: 'ParkingAreaType' },
    { field: 'ParkingSpace' },
    { field: 'Zone' },
    { field: 'Area' },
    { field: 'RouteArea' },
    { field: 'DetectionStreet' },
    { field: 'MappedOnRoute' },
    { field: 'UsedInOccupancy' },
    { field: 'DetectionValid' },
    { field: 'GpsPrecision' },
    { field: 'Confidence' },
    { field: 'Side' },
    { field: 'DetectionTime' },
    { field: 'DetectionDeviceId' },
    { field: 'DetectionId' },
    { field: 'OffenceText' },
    { field: 'OffenceDescription' },
    { field: 'OffencePrice' },
    { field: 'ParkingRight', name: 'keyParkingRight', hidden: true },
    { field: 'IllegallyParked', name: 'keyIllegallyParked', hidden: true },
    { field: 'TimeLimitedParking', name: 'keyTimeLimitedParking', hidden: true },
    { field: 'Verification', name: 'keyVerification', hidden: true },
    { field: 'DetectionState', name: 'keyDetectionState', hidden: true },
    { field: 'OffenceCode', hidden: true },
    { field: 'ScanDeviceLatitude', hidden: true },
    { field: 'ScanDeviceLongitude', hidden: true },
    { field: 'VehicleCenterLatitude', hidden: true },
    { field: 'VehicleCenterLongitude', hidden: true },
    { field: 'DetectionState' },
];
let ReportsSelectFields = ReportSelectFieldsArr.map(c => {
    return AMapParseToSelect(c.field === 'Image' ? Object.assign({ ...c }, { field: '', hidden: true }) : c);
}).join(', ');
let ReportsImageSelectFields = ReportSelectFieldsArr.map(c => {
    return AMapParseToSelect(c.field === 'Image' ? Object.assign({ ...c }, { field: 'Image', hidden: false }) : c);
}).join(', ');
export const FinesSelectFieldsArr = [
    { field: 'DetectionDevice', name: 'Device' },
    { field: 'DetectionTime' },
    { field: 'DetectionUser', name: 'D-User' },
    { field: `IFNULL(u.ExternalId, '')`, name: 'ExternalId' },
    { field: 'LicensePlate' },
    { field: 'CountryCode', name: 'Country' },
    { field: 'OffenceCode' },
    { field: 'OffenceText' },
    { field: 'OffencePrice' },
    { field: 'ParkingRight' },
    { field: 'ParkingRightType', name: 'Right' },
    { field: 'IllegallyParked' },
    { field: 'VerificationDevice', name: 'ResultDevice' },
    { field: 'VerificationEndTime', name: 'ResultTime' },
    { field: 'VerificationUser', name: 'ResultUser' },
    { field: 'VerificationChannel', name: 'Channel' },
    { field: 'Verification', name: 'Result' },
    { field: 'DetectionState' },
    { field: 'FineNumber' },
    { field: 'HasFineData' },
    { field: 'HasFineImage', hidden: true },
    // { field: 'FineData' },
    { field: 'ParkingSpace' },
    { field: 'Zone' },
    { field: 'Area' },
    { field: 'RouteArea' },
    { field: 'Address' },
    { field: 'DetectionDeviceId' },
    { field: 'DetectionId' },
    { field: 'ParkingRight', name: 'keyParkingRight', hidden: true },
    { field: 'IllegallyParked', name: 'keyIllegallyParked', hidden: true },
    { field: 'TimeLimitedParking', name: 'keyTimeLimitedParking', hidden: true },
    { field: 'Verification', name: 'keyVerification', hidden: true },
    { field: 'DetectionState', name: 'keyDetectionState', hidden: true },
];
export let FinesSelectFields = FinesSelectFieldsArr.map(c => {
    return AMapParseToSelect(c);
}).join(', ');
// let ReportsSelectFields      = `DetectionDevice AS 'Device', Day, DetectionTime AS Time, LicensePlate AS 'LicensePlate', HasParkingRight, IsIllegallyParked, CountryCode AS 'Country', Confidence, Side, Zone, Area, DetectionUser AS 'D-User', VehicleMoving AS 'Moving', ParkingRightType AS 'Right', ParkingAreaType, VerificationResultText AS 'Result', Address, GpsPrecision, DuplicateCodeLp AS 'Dup', VerificationUser AS 'V-User', ScanDeviceLatitude, ScanDeviceLongitude, VehicleCenterLatitude, VehicleCenterLongitude ${ DetectionsFinalFieldsClean } DetectionTime, DetectionValid, DetectionDeviceId, DetectionId`
// let ReportsImageSelectFields = `DetectionDevice AS 'Device', Day, DetectionTime AS Time, Image, LicensePlate AS 'LicensePlate', HasParkingRight, IsIllegallyParked, CountryCode AS 'Country', Confidence, Side, Zone, Area, RouteArea, DetectionUser AS 'D-User', VehicleMoving AS 'Moving', ParkingRightType AS 'Right', ParkingAreaType, VerificationResultText AS 'Result', Address, GpsPrecision, DuplicateCodeLp AS 'Dup', VerificationUser AS 'V-User', ScanDeviceLatitude, ScanDeviceLongitude, VehicleCenterLatitude, VehicleCenterLongitude, ${ DetectionsFinalFieldsClean }, DetectionTime, DetectionValid, DetectionDeviceId, DetectionId`
// Time Range
let DetectionsFullRanged = AMakeDetectionsFull(filterTimeRange);
let DetectionsFullImagesRanged = AMakeDetectionsFullImages(filterTimeRange);
let DetectionsFullMapOptimized = (`
  SELECT
    df.*,
    df.Digital as keyDigital,
    df.TimeLimitedParking as keyTimeLimitedParking,
    df.IllegallyParked as keyIllegallyParked,
    df.ParkingRight as keyParkingRight,
    df.Verification as keyVerification,
    df.DetectionState as keyDetectionState,
    ST_AsGeoJSON(IFNULL(gb.VehicleBounds,gbo.VehicleBounds)) AS VehicleBounds
  FROM detections_final df
  INNER JOIN detections_geo_bounds gb USING (DetectionId, DetectionDeviceId)
  INNER JOIN detections_geo_bounds gbo USING (DetectionId, DetectionDeviceId)
  WHERE DetectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000)
  LIMIT :Limit
`).trim();
let DetectionsFullReportOptimized = (`
  SELECT
    d.DetectionDevice as 'Device',
    DATE_FORMAT(d.DetectionTime,'%Y-%m-%d') AS 'Day',
    CAST(d.DetectionTime as DateTime) AS 'Time',
    IFNULL(p.LicensePlate,IFNULL(dc.LicensePlate,d.LicensePlate)) AS 'LicensePlate',
    p.HasParkingRight,
    dg.IsIllegallyParked,
    IFNULL(p.CountryCode,IFNULL(dc.CountryCode,d.CountryCode)) AS 'Country',
    ROUND(d.Confidence,3) AS 'Confidence',
    IF(d.Side='R','→',IF(d.Side='L','←','-')) AS 'Side',
	  ` /*CASE FLOOR(IF(d.Side='L',(d.ScanDeviceDirection+270+45)%360,(d.ScanDeviceDirection+90+45)%360)/90) WHEN 0 THEN 'North' WHEN 1 THEN 'East' WHEN 2 THEN 'South' ELSE 'West' END AS 'CardinalDirection',*/ + `
    IFNULL(dg.ZoneName,'Unknown') AS 'Zone',
    IFNULL(dg.AreaName,'Unknown') AS 'Area',
    IFNULL(dg.RouteAreaName,'Unknown') AS 'RouteArea',
    d.DetectionUser AS 'D-User',
    IF(d.VehicleMoving,'Yes','No') AS 'Moving',
    p.ParkingRightType AS 'Right',
    p.ParkingRightResults AS 'Result',
    IFNULL(dg.AddressName,'Unknown') AS 'Address',
    d.GpsPrecision as 'GpsPrecision',
    d.DuplicateCodeLp as 'DuplicateCodeLp',
    v.VerificationUser as 'V-User',
    d.ScanDeviceLatitude as 'ScanDeviceLatitude',
    d.ScanDeviceLongitude as 'ScanDeviceLongitude',
    IFNULL(p.VehicleCenterLatitude,d.VehicleCenterLatitude) AS 'VehicleCenterLatitude',
    IFNULL(p.VehicleCenterLongitude,d.VehicleCenterLongitude) AS 'VehicleCenterLongitude',
    df.Digital,
    df.TimeLimitedParking,
    df.IllegallyParked,
    df.ParkingRight,
    df.Verification,
    df.DetectionState,
    df.FinalVerificationUser,
    df.FinalVerificationDevice,
    df.FinalVerificationDistanceToVehicle,
    d.DetectionTime,
    d.DetectionValid,
    d.DetectionDeviceId,
    d.DetectionId,
    v.OffenceCode,
    v.OffenceText,
    v.OffencePrice,
    v.OffenceDescription,
    v.FineNumber,
    v.FineData
  FROM detections_final df
  INNER JOIN detections d USING (DetectionId,DetectionDeviceId)
  LEFT JOIN detections_geo_view dg USING (DetectionId,DetectionDeviceId)
  LEFT JOIN corrections dc USING (DetectionId,DetectionDeviceId,DetectionVersion)
  LEFT JOIN parkingrights p USING (DetectionId,DetectionDeviceId,ParkingRightVersion)
  LEFT JOIN verifications v USING (DetectionId,DetectionDeviceId,VerificationVersion)
  WHERE df.DetectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000)
  LIMIT :Limit
`);
// Detections full ranged including pre geo correction
let DetectionsPreGeoCorrection = AMakeDetectionsPreGeoCorrection(filterTimeRange);
// Viewport 
let DetectionsViewport = AMakeDetectionsFull(filterViewport);
// License Plate search 
let DetectionsSearchRanged = AMakeDetectionsFull(filterLicensePlate);
let DetectionsSearchImagesRanged = AMakeDetectionsFullImages(filterLicensePlate);
// License Plate or DetectionId &  DetectionDeviceId search
let DetectionsSearchIdRanged = AMakeDetectionsFull(filterSearch);
// DetectionId & DetectionDeviceId (without time)
let DetectionsFullSingle = AMakeDetectionsFull(filterSingle);
let DetectionsFullSingleImage = AMakeDetectionsFullImages(filterSingle);
// Fines
let FinesFullRanged = AMakeFinesFull(filterTimeRangeFine);
export { DetectionsFullRanged, DetectionsFullImagesRanged, DetectionsFullMapOptimized, DetectionsFullReportOptimized, DetectionsPreGeoCorrection, DetectionsViewport, DetectionsSearchRanged, DetectionsSearchImagesRanged, DetectionsSearchIdRanged, DetectionsFullSingle, DetectionsFullSingleImage, FinesFullRanged, ParkingPlaceDetections, ReportsSelectFields, ReportsImageSelectFields, UnificationTypes };
