import { AEngine } from "../../core/AEngine.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { ATimeService } from "../../services/ATimeService.js";
import { escapeHtml } from "../../utils/json.js";
import { toast } from "../../utils/toasts.js";
import { debounce } from "../../utils/tools.js";
import { AError } from "../AError.js";
import { AWindowBase } from "../windows/AWindowBase.js";
const markdownExample = (`
## Markdown in the browser!

##### Text & paragaphs:
Rendered in **bold!**.\\
Second line of paragraph!\\
Third line yay!

##### Ordered List
1. Apple
2. Mango
3. Milk
4. Destruction of our enemies

##### List
- Workout
- Make Appointment
- Make Dinner
- Destruction of our enemies

##### Links

My favorite search engine is [Duck Duck Go](https://duckduckgo.com "The best search engine for privacy").

##### Seperators
***
---
_________________

##### Block Quote
> P.S. DON'T FORGET THE MILK!! :)
`).trim();
export class ATipsTutorialWindow extends AWindowBase {
    constructor(opt) {
        super();
        this.opt = opt;
        this.timeService = AEngine.get(ATimeService);
    }
    async show() {
        let id = idAllocatorService.getNextId({ prefix: 'win-table-' });
        await super.generate({
            content: ( /*html*/`
        <div id="${id}" class="fw fh" style="padding: 0.4rem">
          <div class="columns mb-1" style="position: relative; height: calc(100% - 42px)">
            <div class="column col-6 fh" style="overflow-y: auto">
              <div class="markdown-renderer"></div>
            </div>
            <div class="column col-6 fh">
              <textarea class="form-input fw-i fh-i" id="markdown-input" placeholder="Markdown"></textarea>
            </div>
          </div>
          <div class="columns" style="height: 36px">
            <div class="column col-6">
              <a href="https://www.markdownguide.org/basic-syntax/#overview" target="_blank" class="btn btn-white fw">
                <i class="fa-solid fa-up-right-from-square"></i>
                View Markdown Cheatsheet
              </a>
            </div>
            <div class="column col">
              <select class="form-select" id="markdown-saved" name="markdown-saved">
              </select>
            </div>
            <div class="column col-auto">
              <button class="btn btn-grey fw btn-load">
                <i class="fa-solid fa-sd-card"></i>
                Load
              </button>
            </div>
            <div class="column col-auto">
              <button class="btn btn-primary fw btn-save">
                <i class="fa-regular fa-folder-open"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      `),
            size: { width: 1200, height: 580 },
            minSize: { width: 740, height: 180 },
            alignTo: 'center',
            lifeCycle: 'CONTINUOUS'
        });
        this.init(this.$internal);
        // this.eventId = Events.hardwire(EVENTS.SESSION_UPDATE_DATA, async () => { })
        return window;
    }
    fetchCache() {
        return preferenceService.load('tiptutorials_cached', [{ title: 'Example', body: markdownExample }]);
    }
    addToCache(markdown) {
        let changedCache = [markdown].concat(this.fetchCache().filter(s => s.title != markdown.title));
        preferenceService.save('tiptutorials_cached', changedCache);
        this.renderSelect(this.$internal, markdown);
    }
    $eles() {
        let $win = this.$internal;
        return {
            $input: $win.find('#markdown-input'),
            $selectSaved: $win.find('#markdown-saved'),
            $btnSave: $win.find('.btn-save'),
            $btnLoad: $win.find('.btn-load'),
        };
    }
    init($win) {
        let ctx = this.$eles();
        // let $input = $win.find('#markdown-input')
        // let $selectSaved = $win.find('#markdown-saved')
        this.renderSelect($win);
        ctx.$btnSave.on('click', () => this.showSaveModal(ctx.$selectSaved.val(), ctx.$input.val()));
        ctx.$btnLoad.on('click', () => {
            ctx.$input.val(this.fetchCache().find(s => s.title == ctx.$selectSaved.val()).body);
            this.renderPreview($win, ctx.$input.val());
            ctx.$btnSave.prop('disabled', true);
        });
        const trigger = debounce(() => {
            this.renderPreview($win, ctx.$input.val());
            ctx.$btnSave.prop('disabled', false);
        }, 300);
        ctx.$input.on('keyup', () => { trigger(); });
    }
    showSaveModal(currProfileSelect, body) {
        let e = Alerts.show({
            title: ALERT_TITLES.None,
            buttons: ALERT_BUTTONS.saveCancel,
            content: ( /*HTML*/`
        <form>
          <div class="form-group">
            <label class="form-checkbox">
              <input id="override-selected" type="checkbox">
              <i class="form-icon"></i> Override Current Save
            </label>
          </div>

          <input id="markdown-title" class="form-input fw" type="text">
          <input class="form-input fw hidden" type="text" value="${escapeHtml(currProfileSelect)}" disabled="disabled">
        </form>
      `),
        });
        let $override = e.$ele.find('#override-selected');
        let $title = e.$ele.find('#markdown-title');
        $override.on('change', () => {
            let checked = $override.prop('checked');
            // $title.prop('disabled', checked)
            e.$ele.find('.form-input').toggleClass('hidden');
        });
        e.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
            let title = $override.prop('checked') ? currProfileSelect : ($title.val() || '');
            if (title.length === 0) {
                return false;
            }
            this.addToCache({ title, body });
        });
    }
    renderSelect($win, selected) {
        const savedArray = this.fetchCache();
        const { $selectSaved } = this.$eles();
        $selectSaved.html(savedArray.map(s => {
            return ( /*HTML*/`
          <option value="${escapeHtml(s.title)}">${escapeHtml(s.title)}</option>
        `);
        }).join(''));
        $selectSaved.val(selected?.title ?? 'Example');
    }
    renderPreview($win, markdown) {
        let $renderer = $win.find('.markdown-renderer');
        Loading.waitForPromises(Promise.resolve().then(() => marked.parse(markdown)).then((val) => {
            $renderer.html(val);
        })).catch(AError.handle);
    }
    close() {
        toast({ msg: 'Not Implemented Yet!' });
        this.$internal.remove();
        this.destruct();
    }
    destruct() {
        // Events.off(EVENTS.SESSION_UPDATE_DATA, this.eventId)
        this.handler.destruct();
    }
}
