import { AError } from "../../classes/AError.js";
import { COLUMN_ACTION } from "../../classes/AGridTypes.js";
import { ASearchHandler } from "../../classes/grid/ASearchHandler.js";
import { AEnforceUnitOrm } from "../../orm/AEnforceUnitOrm.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
import { AFormInstance } from "../../core/form/AFormInstance.js";
export class APage {
    set state(value) {
        this._state = value;
        switch (this._state) {
            case "tab-grid-view":
                filterService.setActive(true);
                break;
            case "tab-form-view":
                filterService.setActive(false, { silent: true });
                break;
        }
    }
    changePageState(opt) {
        this.state = opt.tab;
        const $tab = $(`[tab="${opt.tab}"]`);
        if ($tab.length === 0) {
            AError.handle(`Couldn't find tab [tab="${opt.tab}"]`);
        }
        $tab.trigger('click');
    }
    constructor() {
        this.enforceUnitOrm = new AEnforceUnitOrm();
        this.searchHandler = new ASearchHandler();
    }
    async init() {
        $('#create-btn').on('click', (e) => this.displayCreateModal());
        Loading.waitForPromises(this.refresh());
    }
    async displayCreateModal(formData) {
        const formInputs = [
            { id: 'EnforceUnitCode', type: 'text', minlength: 1, maxlength: 64, disabled: formData !== undefined },
            { id: 'EnforceUnitName', type: 'text', minlength: 1, maxlength: 64 },
        ];
        const form = new AFormInstance({ ignoreWildcards: true, formInputs });
        const events = Alerts.show({
            title: ALERT_TITLES.Info,
            buttons: ALERT_BUTTONS.saveCancel,
            content: await Loading.waitForPromises(form.generate({ translate: true }))
        });
        const $form = form.$form();
        await form.injectFormData({ formData });
        await form.initFormValidation();
        // const $form = events.$ele.find('form')
        // await AForm.injectFormData($form, { formData, formInputs })
        // await AForm.initFormValidation($form, formInputs)
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                if (!form.validate()) {
                    Alerts.incomplete();
                    return false;
                }
                const options = form.extractFormData({ cleanData: true });
                // const options: AEnforceUnit = AForm.extractFormData($form, { ignoreWildcards: false }) as any
                const success = await Loading.waitForPromises(formData === undefined ? this.enforceUnitOrm.create(options) : this.enforceUnitOrm.update(options));
                if (!success) {
                    Alerts.show({
                        title: ALERT_TITLES.Error,
                        content: await Translate.get(`${options.EnforceUnitCode || 'Entry'} Already Exists!`)
                    });
                }
                return success;
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async displayDeleteModal(record) {
        const alert = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Delete Enforce Unit')),
            buttons: ALERT_BUTTONS.yesNo,
            content: await Loading.waitForPromises(Translate.get(/*html*/ `
        Are you sure you want to delete this Enforce Unit?
      `))
        });
        alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                await Loading.waitForPromises(this.enforceUnitOrm.delete(record));
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async refresh() {
        try {
            validateEnforcementProcessService.validate();
            const ares = await this.enforceUnitOrm.fetchAll();
            const response = ares.Original;
            // appendResponseRows(response, ['ActionEdit', 'ActionDelete'])
            ares.addColumns(['ActionEdit', 'ActionDelete']);
            this.grid = AShowTable({
                appendTo: 'bryntum-table',
                aci: {
                    resizeToFit: true,
                    resizeToFitReverse: true,
                    flex: 1,
                },
                features: {
                    search: true
                },
                tbar: [
                    {
                        type: 'text',
                        ref: 'searchField',
                        clearable: true,
                        label: '<i class="b-icon b-icon-search"></i>',
                        showHitIndex: false,
                        listeners: {
                            // input: 'onSearchFieldInput',
                            change: 'onSearchFieldChange',
                            clear: 'onSearchFieldClear',
                            thisObj: this.searchHandler.bind({ ref: 'searchField' })
                        }
                    },
                ],
                selectionMode: {
                    multiSelect: false
                },
                columns: AConvertToGridColumns(response, {
                    'ActionEdit': COLUMN_ACTION({ iconCls: 'fa-solid fa-pencil text-primary', btnCls: 'btn-white' }),
                    'ActionDelete': COLUMN_ACTION({ iconCls: 'fa-regular fa-trash text-red', btnCls: 'btn-white' }),
                }),
                data: AConvertToGridData(response),
            });
            this.grid.on('search', (e) => this.searchHandler.applyFilter(e));
            this.grid.on('clearsearch', (e) => this.searchHandler.onSearchFieldClear(e));
            this.grid.on('cellclick', ({ record, column }) => {
                console.log(record.originalData);
                if (record.id && record.id.indexOf('group-header') !== -1) {
                    return;
                }
                switch (column.data.field) {
                    case 'ActionEdit':
                        this.displayCreateModal(record.data);
                        break;
                    case 'ActionDelete':
                        this.displayDeleteModal(record.data);
                        break;
                }
            });
        }
        catch (err) {
            AError.handle(err);
        }
    }
}
export function render() {
    return ( /*html*/`
    <div id="Rapport" class="flex-child bryntum-container has-footer-2" style="width: 100%">
      <div class="fh">
        <div class="fh" style="overflow-y: auto">
          <div class="aci-tabs hidden" tabgroup="views">
            <button class="aci-tab active" tab="tab-grid-view"><span>Grid View</span></button>
            <button class="aci-tab" tab="tab-form-view"><span>Form View</span></button>
          </div>
          <div class="columns col-gapless fh">
            <div class="column col-12">
              <div tabgroup="views" tabview="tab-grid-view" class="fh">
                <div id="bryntum-table" class="fh"></div>
              </div>
              <div tabgroup="views" tabview="tab-form-view" class="fh" style="overflow-y: auto;">
              </div>
            </div>
          </div>
        </div>
        <!--
        <div tabgroup="views" tabview="tab-form-view" style="overflow-y: auto;">
          <div class="columns footer aci">
            <div class="column col-6">
              <button id="save-kpi" class="btn btn-success col-12">Save</button>
            </div>
            <div class="column col-6">
              <button id="cancel-kpi" class="btn btn-grey col-12">Cancel</button>
            </div>
          </div>
        </div>
        -->
      </div>
      <div tabgroup="views" tabview="tab-grid-view">
        <div class="columns footer aci">
          <div class="column col-2">
            <div id="count" class="text">Viewing <span>0</span> Enforce Units</div>
          </div>
          <div class="column col-2 col-ml-auto">
            <button id="create-btn" class="btn btn-primary col-12">Create Enforce Unit</button>
          </div>
        </div>
      </div>
    </div>
  `);
}
