import { AEngine } from "../core/AEngine.js";
import { AConfig } from "../classes/AConfig.js";
import { ADropDown } from "../core/form/ADropDown.js";
import { ADropDownTree } from "../core/form/ADropDownTree.js";
import { AError } from "../classes/AError.js";
import { AResponse } from "../classes/AResponse.js";
import { TextEncoderLite } from "../core/text-encoder-lite.js";
import { AAlertService, ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../services/AAlertService.js";
import { ADetectionService } from "../services/ADetectionService.js";
import { AEventService, EVENTS } from "../services/AEventService.js";
import { ALoadingService } from "../services/ALoadingService.js";
import { ATranslateService } from "../services/ATranslateService.js";
import { assertHasValue } from "./assert.js";
import { _getEle$, getCenterAny } from "./maps.js";
const { Events, Translate } = getLegacyClasses();
export function SetCookie(name, value, days, shareSubdomains) {
    let expires = '';
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "expires=" + date.toUTCString();
    }
    let domain = '';
    if (shareSubdomains === true) {
        const parentDomain = location.host.split('.').splice(1).join('.');
        domain = (parentDomain.endsWith('.scanacar.com')) ? `domain=${parentDomain}` : '';
    }
    const cookie = [
        `${name}=${value}`,
        `SameSite=Strict`,
        `path=/`,
        expires,
        domain
    ].filter(v => v != '').join(';');
    // const cookie = name + "=" + value + "; SameSite=Strict" + expires + "; path=/" + domain
    console.log('cookie', cookie);
    document.cookie = cookie;
}
export function GetCookie(name, fallback = (v) => v) {
    let nameEQ = name + "=";
    let parts = document.cookie.split(';');
    for (let i = 0; i < parts.length; i++) {
        let q = parts[i];
        while (q.charAt(0) == ' ')
            q = q.substring(1, q.length);
        if (q.indexOf(nameEQ) == 0)
            return q.substring(nameEQ.length, q.length);
    }
    return fallback(null);
}
export function checkIfFullScreen() {
    const d = document;
    return (d.fullScreenElement && d.fullScreenElement !== null) || (d.mozFullScreen || d.webkitIsFullScreen);
    // return (window.innerWidth == screen.width && window.innerHeight == screen.height)
}
export function toggleMapSearch(opt) {
    let $map = _getEle$(opt.ele);
    const $search = $map.find('.map-overlay-search');
    const isHidden = ($search.length === 0);
    if (isHidden) {
        // Show search bar
        const $topLeft = $map.find('.map-overlay-controls.top-left');
        const $topRight = $map.find('.map-overlay-controls.top-right');
        const width = ($topRight.position().left) - ($topLeft.position().left + $topLeft.width()) - 15;
        const $ele = $(`
      <div class="map-overlay-search">
        <input type="text" class="form-input" />
        <div class="autocomplete-list c-scroll hidden">
        </div>
      </div>
    `);
        const AUTOCOMPLETE_THRESHOLD = 3;
        const $input = $ele.find('input');
        const $autoComplete = $ele.find('.autocomplete-list');
        $input.on('click', (e) => {
            $autoComplete.toggleClass('hidden', $input.val().length < AUTOCOMPLETE_THRESHOLD || $autoComplete.children().length === 0);
            // $input.trigger('keyup')
        });
        $input.on('keydown', (e) => {
            console.log('keydown', e.keyCode);
            switch (e.keyCode) {
                case 13: // RETURN OR ENTER
                    var $selected = $autoComplete.find('.selected');
                    if ($selected.length > 0) {
                        $input.val($selected.text());
                        console.log({ select: `"${$selected.text()}"` });
                        $selected.trigger('click');
                    }
                    break;
                case 27: // ESCAPE
                    if (!$autoComplete.is('.hidden')) {
                        $autoComplete.addClass('hidden');
                    }
                    break;
                case 35: // END
                    changeMapSearchSelected({ $autoComplete, increment: 1000, fallbackIndex: $autoComplete.children().length - 1 });
                    break;
                case 36: // HOME
                    changeMapSearchSelected({ $autoComplete, increment: -1000, fallbackIndex: 0 });
                    break;
                case 38: // ARROW UP
                    changeMapSearchSelected({ $autoComplete, increment: -1, fallbackIndex: $autoComplete.children().length - 1 });
                    break;
                case 40: // ARROW DOWN
                    changeMapSearchSelected({ $autoComplete, increment: 1, fallbackIndex: 0 });
                    break;
            }
        });
        $input.on('keyup', (e) => {
            console.log('keyup', e.keyCode);
            switch (e.keyCode) {
                case 13: // RETURN OR ENTER
                case 27: // ESCAPE
                    e.preventDefault();
                    break;
                case 35: // END
                case 36: // HOME
                case 38: // ARROW UP
                case 40: // ARROW DOWN
                    e.preventDefault();
                    if ($autoComplete.is('.hidden')) {
                        $autoComplete.removeClass('hidden');
                    }
                    break;
                default:
                    $autoComplete.toggleClass('hidden', $input.val().length < AUTOCOMPLETE_THRESHOLD);
                    if (($input.val() + '').length >= AUTOCOMPLETE_THRESHOLD) {
                        refreshMapSearchSuggestions({ ...opt, $input, $autoComplete }).catch(AError.handle);
                    }
                    break;
            }
        });
        $ele.css({
            left: ($topLeft.position().left + $topLeft.width() + 8) + 'px',
            width: width + 'px'
        });
        $map.append($ele);
        $input.trigger('focus');
    }
    else {
        // Hide search bar
        $search.remove();
    }
    return isHidden;
}
function changeMapSearchSelected(opt) {
    const { $autoComplete, increment, fallbackIndex } = opt;
    const $selected = $autoComplete.find('.selected');
    if ($selected.length > 0) {
        $selected.removeClass('selected');
        var $neighbour = $autoComplete.children().eq($selected.index() + increment);
        if ($neighbour.length > 0) {
            $neighbour.addClass('selected');
        }
        else {
            $autoComplete.children().eq(fallbackIndex).addClass('selected');
        }
    }
    const $newSelected = $autoComplete.find('.selected');
    if ($newSelected.length > 0) {
        $autoComplete.scrollTop($newSelected.offset().top + $autoComplete.scrollTop() - ($autoComplete.height() / 1.2));
    }
}
async function refreshMapSearchSuggestions(opt) {
    const { map, $input, $autoComplete } = opt;
    return requestService.query({
        Query: (`
      SELECT
        Name,
        Attributes,
        ST_AsGeoJSON(Geo) as Geo
      FROM geo_objects
      WHERE GeoType='Address' AND Active=1 AND Name LIKE :Name
      ORDER BY LENGTH(Name), Name ASC
      LIMIT 100
    `),
        Params: {
            Name: `%${$input.val()}%`
        }
    }).then((res) => {
        $autoComplete.addClass('hidden');
        const ares = new AResponse(res);
        $autoComplete.html('');
        ares.toArray().sort((a, b) => {
            const diff = (a.Attributes.street || '').localeCompare(b.Attributes.street || '');
            if (diff === 0) {
                return (a.Attributes.housenumber || 0) - (b.Attributes.housenumber || 0);
            }
            return diff;
        }).map((row, i) => {
            const $addressListing = $(i === 0 ? `<div class="selected">${row.Name}</div>` : `<div>${row.Name}</div>`);
            $addressListing.on('click', (e) => {
                const { center } = mapHelperService.geoJsonToPolygonCoords(row.Geo);
                map.setCenter(center);
                if (map.getZoom() <= 17)
                    map.setZoom(20);
                $autoComplete.addClass('hidden');
            });
            $autoComplete.append($addressListing);
        });
        $autoComplete.toggleClass('hidden', res.Rows.length === 0);
    });
}
// export function toggleMapFullScreen(ele: string | Element | JQuery): boolean {
export function toggleMapFullScreen(opt) {
    const isfull = checkIfFullScreen();
    if (isfull) {
        exitFullScreen();
    }
    else {
        requestFullScreen(opt.mapElement);
    }
    return isfull;
}
function requestFullScreen(ele) {
    if (ele.requestFullscreen) {
        ele.requestFullscreen();
    }
    else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen();
    }
    else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
    }
    else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen();
    }
}
function exitFullScreen() {
    let d = document;
    if (d.exitFullscreen) {
        d.exitFullscreen();
        // @ts-ignore
    }
    else if (d.msExitFullscreen) {
        // @ts-ignore
        d.msExitFullscreen();
        // @ts-ignore
    }
    else if (d.mozCancelFullScreen) {
        // @ts-ignore
        d.mozCancelFullScreen();
        // @ts-ignore
    }
    else if (d.webkitExitFullscreen) {
        // @ts-ignore
        d.webkitExitFullscreen();
    }
    else {
        alert("No ExitFullScreen function found!");
    }
}
export function ADeviceIdToName(id) {
    const keys = Object.keys(globalThis.ScanDeviceIds);
    for (let key of keys) {
        if (ScanDeviceIds[key] === id) {
            return key;
        }
    }
}
export function getMeta(url) {
    return new Promise((resolve, reject) => {
        try {
            let img = new Image();
            img.addEventListener("load", function () {
                return resolve({
                    width: this.naturalWidth,
                    height: this.naturalHeight
                });
            });
            img.src = url;
        }
        catch (err) {
            return reject(err);
        }
    });
}
export function scaleMetaSize(size, max) {
    let { width, height } = size;
    if (width > height) {
        let w = width / max;
        return {
            width: width / w,
            height: height / w
        };
    }
    else {
        let h = height / max;
        return {
            width: width / h,
            height: height / h
        };
    }
}
export function lerp(a, b, t) {
    t = Math.max(Math.min(t, 1), 0);
    return a + (b - a) * t;
}
export function normalizeValue(x, min, max) {
    return (x <= min) ? 0.0 : (x >= max) ? 1.0 : (x - min) / (max - min);
}
export function AHasValue(val) {
    return (!['%', '', ' '].includes(val) && val) ? true : false;
}
/**
 * Converts complex data to formatted human readable text (for ex. tables)
 * @param {*} text input object
 * @param {*} options format params
 */
export function AConvert(text, options) {
    if (text == null)
        return text;
    let functions = Object.assign({
        AFormatDate
    }, options);
    switch (text.constructor.name) {
        case 'Number':
            return text;
        case 'String':
            if (AIsDate(text)) {
                return functions.AFormatDate(new Date(text));
            }
            return text;
        case 'Array':
            return text;
        case 'Object':
            return text;
        case 'Boolean':
            return text.toString();
    }
    console.error(new Error(`Type '${typeof text}' Is Not Implemented Yet!`));
    return text;
}
export function AIsDate(text) {
    if (typeof text !== 'string')
        return false;
    return (text.charAt(4) == '-' && text.charAt(7) == '-' && text.charAt(10) == 'T');
}
export function AFormatNumber(n, invalidValue = '0') {
    if (n === null || n === undefined || Number.isNaN(n))
        return invalidValue;
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '<div class="number-seperator"></div>');
}
export function ATryFormatDate(input) {
    return input && AIsDate(input) ? AFormatDate(new Date(input)) : '';
}
export function AFormatDate(d, s = " ", ds = "-", ts = ":", tzs = " ") {
    if (!d)
        return "";
    if (d.getTime() === 0)
        return "";
    if (isNaN(d.getTime()))
        return "Invalid Date";
    let Year = d.getFullYear();
    let Month = d.getMonth() + 1;
    let Day = d.getDate();
    let Hour = d.getHours();
    let Minute = d.getMinutes();
    let Second = d.getSeconds();
    if (Month < 10)
        Month = "0" + Month;
    if (Day < 10)
        Day = "0" + Day;
    if (Hour < 10)
        Hour = "0" + Hour;
    if (Minute < 10)
        Minute = "0" + Minute;
    if (Second < 10)
        Second = "0" + Second;
    return Year + ds + Month + ds + Day + s + Hour + ts + Minute + ts + Second;
}
export function AFormatDateTimezone(d, s = " ", ds = "-", ts = ":", tzs = " ") {
    let Year = d.getFullYear();
    let Month = d.getMonth() + 1;
    let Day = d.getDate();
    let Hour = d.getHours();
    let Minute = d.getMinutes();
    let Second = d.getSeconds();
    if (Month < 10)
        Month = "0" + Month;
    if (Day < 10)
        Day = "0" + Day;
    if (Hour < 10)
        Hour = "0" + Hour;
    if (Minute < 10)
        Minute = "0" + Minute;
    if (Second < 10)
        Second = "0" + Second;
    let FormatedDateTime = Year + ds + Month + ds + Day + s + Hour + ts + Minute + ts + Second;
    if (ds != '_') {
        let TimeZoneOffsetSign = '+';
        let TimeZoneOffset = -d.getTimezoneOffset() / 60;
        if (TimeZoneOffset < 0) {
            TimeZoneOffsetSign = '-';
            TimeZoneOffset = -TimeZoneOffset;
        }
        if (TimeZoneOffset < 10) {
            TimeZoneOffset = '0' + TimeZoneOffset;
        }
        FormatedDateTime += tzs + TimeZoneOffsetSign + TimeZoneOffset;
    }
    return FormatedDateTime;
}
export function AFormatDateFull(d, s = " ", ds = "-", ts = ":", mss = ".", tzs = " ") {
    let Year = d.getFullYear();
    let Month = d.getMonth() + 1;
    let Day = d.getDate();
    let Hour = d.getHours();
    let Minute = d.getMinutes();
    let Second = d.getSeconds();
    let MSecond = d.getMilliseconds();
    if (Month < 10)
        Month = "0" + Month;
    if (Day < 10)
        Day = "0" + Day;
    if (Hour < 10)
        Hour = "0" + Hour;
    if (Minute < 10)
        Minute = "0" + Minute;
    if (Second < 10)
        Second = "0" + Second;
    if (MSecond < 10)
        MSecond = "00" + MSecond;
    else if (MSecond < 100)
        MSecond = "0" + MSecond;
    let FormatedDateTime = Year + ds + Month + ds + Day + s + Hour + ts + Minute + ts + Second + mss + MSecond;
    if (ds != '_') {
        let TimeZoneOffsetSign = '+';
        let TimeZoneOffset = -d.getTimezoneOffset() / 60;
        if (TimeZoneOffset < 0) {
            TimeZoneOffsetSign = '-';
            TimeZoneOffset = -TimeZoneOffset;
        }
        if (TimeZoneOffset < 10) {
            TimeZoneOffset = '0' + TimeZoneOffset;
        }
        FormatedDateTime += tzs + TimeZoneOffsetSign + TimeZoneOffset;
    }
    return FormatedDateTime;
}
export function AFormatIsoDate(d) {
    return AFormatDate(d, 'T', '-', ':', '');
}
export function AFormatIsoDateFull(d) {
    return AFormatDateFull(d, 'T', '-', ':', '.', '');
}
export function AConvertMillisecondsToHM(ms) {
    const seconds = ms / 1000;
    const ss = Math.floor(seconds);
    const hh = Math.floor(seconds / 3600);
    const mm = Math.floor(seconds / 60);
    return (`
        ${(hh).toString().padStart(2, '0')}:${(mm % 60).toString().padStart(2, '0')}:${(ss % 60).toString().padStart(2, '0')}
    `).trim();
}
export function AStripUrl(cssurl) {
    if (!cssurl.includes('url(')) {
        console.warn(`Couldn't strip url: ${cssurl}`);
        return cssurl;
    }
    if (cssurl.startsWith('url("')) {
        return cssurl.substring(5, cssurl.length - 2);
    }
    else {
        return cssurl.substring(4, cssurl.length - 1);
    }
}
export function AFormat2Dec(n) {
    return n.toString().padStart(2, '0');
}
export function AInputDate(date) {
    let yyyy = date.getFullYear();
    let mm = AFormat2Dec((date.getMonth() + 1));
    let dd = AFormat2Dec(date.getDate());
    return yyyy + "-" + mm + "-" + dd;
}
export function AToHHMMSS(d) {
    let hh = AFormat2Dec(d.getHours());
    let mm = AFormat2Dec(d.getMinutes());
    let ss = AFormat2Dec(d.getSeconds());
    return hh + ":" + mm + ':' + ss;
}
export function AInputTime(d) {
    let hh = AFormat2Dec(d.getHours());
    let mm = AFormat2Dec(d.getMinutes());
    return hh + ":" + mm;
}
export function AInputSeconds(d) {
    const seconds = d.getSeconds();
    return seconds < 10 ? '0' + seconds : seconds;
}
Object.assign(globalThis, {
    AInputDate,
    AInputTime,
    AInputSeconds,
    isObject,
    AShowTable
});
export function AInputCleanDateTime(d) {
    return `${AInputDate(d)} ${AInputTime(d)}`;
}
export function AInputDateTime(d) {
    return `${AInputDate(d)} ${AInputTime(d)}:${AInputSeconds(d)}`;
}
export function APrepareMysqlDate(d) {
    return AInputDateTime(d).replace(/-/g, '').replace(/ /g, '').replace(/:/g, '');
}
export function ACombineDateTime(date, time) {
    return new Date(date + ' ' + time + ':00');
}
export function ATryCombineDateTime(date, time, opt) {
    if (date === undefined || time === undefined)
        return opt.elseUse;
    if (date.length === 0 || time.length === 0)
        return opt.elseUse;
    return ACombineDateTime(date, time);
}
export function ARound(val, decimals = 0) {
    const pow = Math.pow(10, decimals);
    return Math.round(val * pow) / pow;
}
export function CreateUtfDownloadUrl(str, type) {
    var blob = new Blob([str], { type: type });
    return window.URL.createObjectURL(blob);
}
export function APascal(input) {
    if (!input)
        return '';
    return input[0].toUpperCase() + input.substr(1);
}
export function ACamel(input) {
    return input[0].toLowerCase() + input.substr(1);
}
export function AArrayDiff(a1, a2) {
    let a = [];
    let diff = [];
    for (let i = 0; i < a1.length; i++) {
        // @ts-ignore
        a[a1[i]] = true;
    }
    for (let i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        }
        else {
            // @ts-ignore
            a[a2[i]] = true;
        }
    }
    for (let k in a) {
        // @ts-ignore
        diff.push(k);
    }
    return diff;
}
export const isVariablePrimitive = (v) => (v !== Object(v));
export function createArray(length, defaultValue = undefined) {
    let output = [];
    if (typeof defaultValue === 'function') {
        for (let i = 0; i < length; i++) {
            output.push(defaultValue(i));
        }
    }
    else {
        // if (isPrimitive(defaultValue)) { }
        for (let i = 0; i < length; i++) {
            output.push(defaultValue);
        }
    }
    return output;
}
export function createMatrix(width, height, defaultValue) {
    return createArray(width, () => {
        return createArray(height, defaultValue);
    });
}
Object.assign(globalThis, { createArray, createMatrix });
/**
 * Implementation of Bryntum Grid
 * For Event handling see: https://bryntum.com/docs/scheduler/#Grid/data/ColumnStore#event-change
 * @param gridOptions
 */
export function AShowTable(gridOptions) {
    let grid;
    const $bryntum = $(`#${gridOptions.appendTo}`);
    // Prevents modal grid -> PageScript.grid hijacking
    if ($bryntum.closest('.modal').length === 0) {
        const $displayOnce = $('.display-once');
        $displayOnce.remove();
        $bryntum.removeClass('hidden');
        $bryntum.html('');
    }
    const $export = $('.footer.aci #export');
    if ($export.length) {
        $export.removeAttr('disabled');
        if ($export.attr('exportListener') !== 'added') {
            $export.attr('exportListener', 'added');
        }
        $export.off('click');
        $export.on('click', (e) => {
            userActionService.logAction('USER', 'EXPORT TABLE', FilterManager.saveExplicit({ cacheFilters: false }));
            const exporter = new bryntum.grid.TableExporter({ target: grid ?? script.grid });
            let { columns, rows } = exporter.export();
            const fields = columns.map(c => c.field);
            const hiddenFields = grid.columns.data.map((c) => c.hideInCSV === true).map((c) => c.field);
            // const hiddenFields = gridOptions.columns.filter(c => c.hideInCSV === true).map(c => c.field!)
            const hiddenIndices = hiddenFields.map(c => fields.indexOf(c)).filter(i => i !== -1);
            // TODO: Optimize export
            const csv = [];
            csv.push(columns /*.filter((c, i) => !hiddenIndices.includes(i))*/.map(({ value }) => (value instanceof Date) ? AFormatIsoDate(value) : value).join(','));
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                csv.push(row.map((value, colI) => {
                    if (hiddenIndices.includes(colI)) {
                        return '';
                    }
                    if (value instanceof Date) {
                        // Transform date object to string
                        return AFormatIsoDate(value);
                    }
                    // Transform progress bar element to percentage
                    if (value != null && value.hasOwnProperty('className') && value.className == 'b-percent-bar-outer') {
                        try {
                            return value.children[0].style.width;
                        }
                        catch (err) {
                            console.error(err);
                            return '%?';
                        }
                    }
                    // Remove html tags from element
                    let strOut = String(value).replace(/<\/?[^>]+(>|$)/g, ''); //.replace(/(\r\n)*[\r\n]+/g, ' ')
                    if (strOut.includes('\n')) {
                        // Escape newlines
                        strOut = `" ${strOut} "`;
                    }
                    else if (strOut.includes(',')) {
                        // Escape comma's 
                        strOut = `"${strOut.replace(/"/g, `""`)}"`;
                    }
                    if (strOut == 'null') {
                        // Replace null strings to empty strings
                        strOut = '';
                    }
                    return strOut.replace(/\s+/g, ' ');
                }).join(','));
            }
            const filename = `${document.title.replace(/[^a-zA-Z ]/g, '').replace(/\s+/g, ' ')} ${AInputDate(new Date()).replace(/\s/, '-')}.csv`;
            // Create blob in order to export csv data
            const file = new Blob(['\ufeff' + csv.join('\r\n')], { type: 'text/csv;charset=utf-8;' });
            // Create temporary url to export file
            const url = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.innerHTML = "Click Here to download";
            document.body.appendChild(link); // Required for FF
            link.click();
            setTimeout(() => {
                // Delete temporary download href
                link.remove();
                // Deconstruct temporary file url
                URL.revokeObjectURL(url);
            }, 0);
        });
    }
    const bryntumGridOptions = mergeDeep({}, {
        aci: {
            updateCount: true,
            showLimit: false,
            resizeToFit: true,
            skipResizeColumns: undefined,
            flex: undefined,
            overrideFooterText: undefined
        },
        selectionMode: {
            multiSelect: false,
            row: true,
        },
        subGridConfigs: {
            default: {},
            right: {},
        },
        features: {
            filter: true,
            quickFind: true,
            cellEdit: {
                disabled: true
            },
            // API 4.0
            cellMenu: {
                items: {
                    removeRow: false
                },
                processItems(args) {
                    const { record, items, cellElement, column } = args;
                    const { data } = record;
                    // Add a custom item to certain records
                    if (cellElement.innerText?.length > 0) {
                        items.copyText = {
                            text: Translate.getCacheFast('copy text'),
                            icon: 'b-fa b-fa-copy',
                            cls: 'b-seperator color',
                            name: 'custom',
                            onItem: (obj) => copyToClipboard(obj.targetElement.innerText)
                        };
                    }
                    if (data.DetectionId && data.DetectionDeviceId) {
                        const id = detectionService.toUniqueId(data); // const id = (`${data.DetectionDeviceId}_${data.DetectionId}`)
                        if (cellElement.innerText?.length > 0) {
                            items.showInPopup = {
                                text: Translate.getCacheFast('show text in popup window'),
                                icon: 'fa-solid fa-sheet-plastic',
                                cls: 'b-seperator color',
                                name: 'custom2',
                                onItem: (obj) => {
                                    const tryParseJson = (str) => { try {
                                        return JSON.stringify(JSON.parse(str), null, 4);
                                    }
                                    catch (err) {
                                        return str;
                                    } };
                                    Alerts.show({
                                        title: ALERT_TITLES.Info,
                                        buttons: ALERT_BUTTONS.ok,
                                        type: ALERTS.Medium,
                                        content: `<code style="color: var(--main-color) !important;">${tryParseJson(obj.targetElement.innerText)}</code>`
                                    });
                                }
                            };
                        }
                        if (PageScript.map && id) { // PageScript.DeskControl?.cachedIdentifiers?.hasOwnProperty(id)) {
                            items.centerOnMap = {
                                text: Translate.getCacheFast('center on map'),
                                icon: 'fa fa-map-pin',
                                name: 'centerOnMap',
                                onItem: (obj) => {
                                    const map = PageScript.map;
                                    const markers = mapHelperService.fetchMarkers();
                                    const found = markers.find(m => m.get('Id') === id);
                                    if (found !== undefined) {
                                        map.setCenter(getCenterAny(found));
                                        if (map.getZoom() <= 16) {
                                            map.setZoom(20);
                                        }
                                    }
                                    // const marker = PageScript.DeskControl?.cachedIdentifiers[id]
                                }
                            };
                        }
                        if (data['TrafficSign'] === undefined) {
                            if (PageScript.map === undefined) {
                                items.lookup = {
                                    text: Translate.getCacheFast('show on map'),
                                    icon: 'fa-regular fa-map-location',
                                    cls: 'b-seperator color',
                                    name: 'customLookup',
                                    onItem: (obj) => {
                                        debugger;
                                        const { DetectionId, DetectionDeviceId, DetectionTime, TrafficSign } = obj.record.data;
                                        if (gridOptions.aci?.isModal) {
                                            Alerts.closeAllActiveModals();
                                        }
                                        // menuHelperService.pressMenuItem('_mapsearch', { DetectionId, DetectionDeviceId, DetectionTime })
                                        detectionService.modalShowOnMap({ DetectionId, DetectionDeviceId, DetectionTime }).catch(AError.handle);
                                    }
                                };
                            }
                            items.history = {
                                text: Translate.getCacheFast('show history'),
                                icon: 'b-fa b-fa-history',
                                cls: 'b-seperator color',
                                name: 'custom',
                                onItem: (obj) => {
                                    const detectionService = AEngine.getOrCreateInstance(ADetectionService), Alerts = AEngine.getOrCreateInstance(AAlertService);
                                    const { DetectionId, DetectionDeviceId } = obj.record;
                                    if (!DetectionId || !DetectionDeviceId) {
                                        return Alerts.noResults();
                                    }
                                    detectionService.findHistory({
                                        DetectionDeviceId,
                                        DetectionId
                                    });
                                }
                            };
                        }
                    }
                }
            }
        },
    }, gridOptions);
    if (bryntumGridOptions.aci.flex !== undefined) {
        bryntumGridOptions.columns = bryntumGridOptions.columns.map(c => {
            if (!c.flex && !c.width) {
                c.flex = bryntumGridOptions.aci.flex;
            }
            return c;
        });
    }
    else if (bryntumGridOptions.aci.columnWidth !== undefined) {
        bryntumGridOptions.columns = bryntumGridOptions.columns.map(c => {
            c.width = bryntumGridOptions.aci.columnWidth;
            return c;
        });
    }
    const $count = $('#count.text > span');
    if ($count.length && bryntumGridOptions.aci.updateCount === true) {
        const length = bryntumGridOptions.store
            ? bryntumGridOptions.store._data.length
            : bryntumGridOptions.data.length;
        const showLimit = bryntumGridOptions.aci.showLimit;
        const limit = globalThis.FilterSettings.Limit;
        let inputText = '';
        if (bryntumGridOptions.aci.overrideFooterText) {
            console.warn('// TODO: Implement translation & allow distinction between span & entire text block, also make footer.count column width wider');
            inputText = bryntumGridOptions.aci.overrideFooterText({
                length,
                showLimit,
                limit
            });
            $count.closest('#count').text(inputText);
        }
        else {
            inputText = (bryntumGridOptions.aci.showLimit === true) ? `${length} / ${limit}` : length;
            $count.text(inputText);
        }
    }
    grid = new bryntum.grid.Grid(bryntumGridOptions);
    grid.on({
        cellclick(args) {
            const { record, column } = args;
            if (record.id && record.id.indexOf('group-header') !== -1) {
                return;
            }
            const found = bryntumGridOptions.columns.find(c => c.field === column.field);
            if (found && found.onClick) {
                let onClick = found.onClick;
                onClick.apply(PageScript, [{ record }]);
            }
        },
        // horizontalscroll(event) { },
        renderrows(event) {
            const $count = $('#count.text > span');
            if ($count.length && bryntumGridOptions.aci.updateCount === true) {
                const length = grid.store.records.length;
                const showLimit = bryntumGridOptions.aci.showLimit;
                const limit = globalThis.FilterSettings.Limit;
                let inputText = '';
                if (bryntumGridOptions.aci.overrideFooterText) {
                    inputText = bryntumGridOptions.aci.overrideFooterText({
                        length,
                        showLimit,
                        limit
                    });
                }
                else {
                    inputText = (bryntumGridOptions.aci.showLimit === true) ? `${length} / ${limit}` : `${length}`;
                }
                $count.text(inputText);
            }
        }
    });
    let gridEvents = globalThis.gridEvents ?? new Set();
    globalThis.gridEvents = gridEvents;
    if (Events.logLevel === 2) {
        grid.on('catchAll', (opt) => {
            if (!gridEvents.has(opt.type)) {
                gridEvents.add(opt.type);
                AEngine.log('grid.catchAll', gridEvents);
            }
        });
    }
    let { resizeToFit, resizeToFitReverse, skipResizeColumns } = bryntumGridOptions.aci;
    if (resizeToFit === true) {
        if (Array.isArray(skipResizeColumns) && skipResizeColumns.length && typeof skipResizeColumns[0] === 'string') {
            skipResizeColumns = skipResizeColumns.map(str => bryntumGridOptions.columns.findIndex(v => v.field === str));
        }
        if ($(grid.element).closest('[tabview]').length > 0) {
            _autoResizeGridToFitTabs(grid, resizeToFitReverse, skipResizeColumns);
        }
        AResizeToFit(grid, resizeToFitReverse, skipResizeColumns);
    }
    return grid;
}
export function AResizeToFit(grid, reverse = false, skipResizeColumns) {
    let resizeExceptions = [];
    const columns = grid.columns.allRecords;
    columns.map(t => {
        try {
            if (!t.originalData.hasOwnProperty('width') && !t.originalData.hasOwnProperty('noresize')) {
                t.resizeToFitContent();
            }
        }
        catch (err) {
            resizeExceptions.push(err);
        }
    });
    if (reverse) {
        if (Array.isArray(skipResizeColumns)) {
            skipResizeColumns.map(index => {
                grid.columns.allRecords[(typeof index === 'string') ? grid.columns.allRecords.findIndex(r => r.field === index) : index ?? 0].flex = 1;
            });
        }
        else {
            grid.columns.allRecords[(typeof skipResizeColumns === 'string')
                ? grid.columns.allRecords.findIndex(r => r.field === skipResizeColumns) : skipResizeColumns ?? 0].flex = 1;
        }
    }
}
export function AGetGridRecord(id) {
    if (!PageScript) {
        throw new Error(`Pagescript is not defined!`);
    }
    if (!PageScript.grid) {
        throw new Error(`Pagescript.Grid is not defined!`);
    }
    const element = document.querySelector(`#${id}`);
    return PageScript.grid.getRecordFromElement(element);
}
/**
 * @deprecated
 * Use AResponse.addColumns instead!
 */
export function appendResponseRows(response, names) {
    if (response instanceof AResponse) {
        response.addColumns(names);
    }
    else {
        for (let name of names) {
            appendResponseRow(response, name);
        }
    }
    return response;
}
export function appendResponseRow(response, nameRow) {
    response.Columns.push(nameRow);
    response.ColumnsTranslated.push(nameRow);
    response.Rows = response.Rows.map(row => {
        row.push(nameRow);
        return row;
    });
}
export function prependResponseRow(response, nameRow) {
    response.Columns.unshift(nameRow);
    response.ColumnsTranslated.unshift(nameRow);
    response.Rows = response.Rows.map(row => {
        row.unshift(nameRow);
        return row;
    });
}
export async function AGridColumns(columnOptions) {
    const cols = Object.keys(columnOptions);
    const colsTranslated = await Translate.get(cols);
    let output = cols.map(col => {
        return Object.assign({
            field: col,
            text: colsTranslated[col]
        }, columnOptions[col]);
    });
    return output;
}
export function AGridData(data, map, options) {
    const { makeCopy } = options || {};
    if (makeCopy === true) {
        data = JSON.parse(JSON.stringify(data));
    }
    return data.map(d => {
        Object.keys(map).map(key => {
            d[key] = map[key](d[key]);
        });
        return d;
    });
}
export function AConvertToGridColumns(response, columnOptions, columnDefaults) {
    const { Columns, ColumnsTranslated } = response;
    let output = [];
    const options = Object.assign({}, columnOptions);
    if (Object.keys(options).length > 0) {
        let editorFlag = false;
        for (let i = 0; i < Columns.length; i++) {
            const currentColumn = Columns[i];
            const columnToInsert = {
                field: Columns[i],
                text: ColumnsTranslated[i]
            };
            if (options.hasOwnProperty(currentColumn)) {
                if (options.hasOwnProperty('editor')) {
                    editorFlag = true;
                }
                Object.assign(columnToInsert, options[currentColumn]);
            }
            else if (options['*']) {
                Object.assign(columnToInsert, options['*']);
            }
            output.push(columnToInsert);
        }
        if (editorFlag) {
            output = output.map((column) => {
                if (!column.hasOwnProperty('editor')) {
                    column.editor = false;
                }
                return column;
            });
        }
    }
    else {
        for (let i = 0; i < Columns.length; i++) {
            output.push({
                field: Columns[i],
                text: ColumnsTranslated[i],
            });
        }
    }
    // if (options["*"]) {
    //   const missingColumns = Columns.filter(c => output.find(f => f.field === c) === undefined)
    //   missingColumns.map(c => {
    //     output.push({
    //       field: c,
    //       ...options["*"]!
    //     })
    //   })
    // }
    columnDefaults?.map(({ field, name, hidden }) => {
        const key = name ?? field;
        const found = output.find((c) => c.field === key);
        if (found === undefined) {
            output.push({
                field: name,
                text: name,
                hidden: hidden
            });
        }
        else {
            if (hidden !== undefined) {
                found.hidden = hidden;
            }
        }
    });
    return output;
}
export function AConvertToGridData(response, options) {
    const { Columns, Rows } = response;
    const output = [];
    const columns = Object.assign({}, options);
    const formatIndexes = {};
    // Format display text
    Object.keys(columns).map((columnName) => {
        for (let i = 0; i < Columns.length; i++) {
            if (Columns[i] === columnName) {
                formatIndexes[i] = columns[columnName];
            }
        }
    });
    for (let i = 0; i < Rows.length; i++) {
        const obj = {};
        for (let x = 0; x < Columns.length; x++) {
            obj[Columns[x]] = Rows[i][x];
            if (formatIndexes.hasOwnProperty(x)) {
                obj[Columns[x]] = formatIndexes[x](Rows[i][x]);
            }
        }
        output.push(obj);
    }
    return output;
}
export async function AConvertToGridDataAsync(response, options) {
    const { Columns, Rows } = response;
    const output = [];
    const columns = Object.assign({}, options);
    const formatIndexes = {};
    // Format display text
    Object.keys(columns).map((columnName) => {
        for (let i = 0; i < Columns.length; i++) {
            if (Columns[i] === columnName) {
                formatIndexes[i] = columns[columnName];
            }
        }
    });
    for (let i = 0; i < Rows.length; i++) {
        const obj = {};
        for (let x = 0; x < Columns.length; x++) {
            obj[Columns[x]] = Rows[i][x];
            if (formatIndexes.hasOwnProperty(x)) {
                obj[Columns[x]] = await Promise.resolve().then(_ => formatIndexes[x](Rows[i][x]));
            }
        }
        output.push(obj);
    }
    return output;
}
export async function AConvertMatrixToResponse(matrix, skipRotate) {
    let output;
    if (!skipRotate) {
        const Columns = [matrix[0][0]].concat(matrix.slice(1).map(row => row[0]));
        output = {
            Columns: Columns,
            ColumnsTranslated: await Promise.all(Columns.map((c) => (typeof c === 'string' && c.length) ? Translate.get(c) : c + '')),
            Rows: matrix[0].slice(1).map((column, colI) => {
                let output = [column];
                for (let i = 1; i < matrix.length; i++) { // rows
                    output.push(matrix[i][colI + 1]);
                }
                return output;
            })
        };
    }
    else {
        output = {
            Columns: matrix[0],
            // TODO: Translate all beforehard, but ScanAuto500 translates to Scan Auto 500 in some cases and Scan Auto500 in other
            ColumnsTranslated: await Promise.all(matrix[0].map((c) => (typeof c === 'string' && c.length) ? Translate.get(c) : c + '')),
            Rows: matrix.slice(1)
        };
    }
    return output;
}
export function AGetGridIds(id, keys) {
    const $row = $(`[data-id="${id}"]`);
    const output = {};
    keys.map((key) => {
        output[key] = $row.find(`[data-column="${key}"]`).text().trim();
    });
    return output;
}
export function AGetGridDetectionIds(id) {
    const $row = $(`#table-bryntum [data-id="${id}"]`);
    return {
        DetectionId: $row.find('[data-column="DetectionId"]').text().trim(),
        DetectionDeviceId: $row.find('[data-column="DetectionDeviceId"]').text().trim()
    };
}
export function base64EncodingUTF8(str) {
    // @ts-ignore
    let encoded = new TextEncoderLite('utf-8').encode(str);
    let b64Encoded = "77u/" + _arrayBufferToBase64(encoded);
    return b64Encoded;
}
export function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}
export function AUrlEncodedImageFromBase64(Base64) {
    if (Base64 == null || Base64.length == 0) {
        return "";
    }
    if (Base64.substr(0, 5) == "data:") {
        return Base64;
    }
    switch (Base64.substr(0, 2)) {
        case "/9": return "data:image/jpg;base64," + Base64;
        case "Qk": return "data:image/bmp;base64," + Base64;
        case "iV": return "data:image/png;base64," + Base64;
    }
    return "data:image/unknown;base64," + Base64;
}
export function ADurationToSeconds(text) {
    const parts = text.split(' ');
    if (parts.length < 2) {
        throw new Error(`Need atleast 2 parts for a duration!`);
    }
    const key = parts[1].toLowerCase().endsWith('s') ? parts[1].toLowerCase() : parts[1].toLowerCase() + 's';
    switch (key) {
        case 'microseconds':
            return parseFloat(parts[0]) / 1000000;
        case 'milliseconds':
            return parseFloat(parts[0]) / 1000;
        case 'seconds':
            return parseFloat(parts[0]);
        case 'minutes':
            return parseFloat(parts[0]) * 60;
        case 'hours':
            return parseFloat(parts[0]) * 3600;
        case 'days':
            return parseFloat(parts[0]) * 3600 * 24;
        case 'weeks':
            return parseFloat(parts[0]) * 3600 * 24 * 7;
        default:
            return parseFloat(parts[0]);
    }
}
/**
 * @deprecated
 * @param Selector
 * @param Key
 * @returns
 */
export function AGetCssRule(Selector, Key) {
    try {
        let foundCss = null;
        // @ts-ignore
        for (let css of document.styleSheets) {
            if (css.href && css.href.endsWith(`/css/style.css`)) {
                foundCss = css;
                break;
            }
        }
        let rules;
        try {
            rules = foundCss.cssRules || foundCss.rules;
        }
        catch (err) {
            console.error(err);
        }
        let attributes = {};
        for (let r = 0; r < rules.length; r++) {
            let text = rules[r].cssText;
            if (text) {
                let rule = text.split("{");
                if (rule && rule.length == 2) {
                    let selectors = rule[0].split(",");
                    for (let i = 0; i < selectors.length; i++) {
                        if (selectors[i].trim() == Selector) {
                            let data = rule[1].trim();
                            if (data.length && data[data.length - 1] == "}") {
                                data = data.substr(0, data.length - 1);
                            }
                            let items = data.split(";");
                            for (let j = 0; j < items.length; j++) {
                                let attribute = items[j].split(":");
                                if (attribute.length >= 2) {
                                    attributes[attribute.shift().trim()] = attribute.join(":").trim();
                                }
                            }
                        }
                    }
                }
            }
        }
        if (Key) {
            return attributes[Key];
        }
        return attributes;
    }
    catch (e) {
        console.error(e);
        return null;
    }
}
export function MakePercentageColorGent(Value) {
    if (Value <= 0.)
        return "rgb(255,0,0)";
    if (Value >= 1.)
        return "rgb(0,200,0)";
    Value *= 2;
    if (Value <= 1.) {
        let R = 1.;
        let G = Value;
        return "rgb(0," + Math.floor(R * 200.) + "," + Math.floor(G * 200.) + ")";
    }
    else {
        let R = 2. - Value;
        let G = 1.;
        return "rgb(0," + Math.floor(R * 200.) + "," + Math.floor(G * 200.) + ")";
    }
}
export function MakePercentageColor(Value) {
    Value = 1. - Value;
    if (Value <= 0.)
        return "rgb(0,255,0)";
    if (Value >= 1.)
        return "rgb(255,0,0)";
    Value *= 2;
    if (Value <= 1.) {
        let G = 1.;
        let R = Value;
        let Sum = Math.sqrt(G + R);
        G /= Sum;
        R /= Sum;
        return "rgb(" + Math.floor(R * 255.) + "," + Math.floor(G * 255.) + ",0)";
    }
    else {
        let G = 2. - Value;
        let R = 1.;
        let Sum = Math.sqrt(G + R);
        G /= Sum;
        R /= Sum;
        return "rgb(" + Math.floor(R * 255.) + "," + Math.floor(G * 255.) + ",0)";
    }
}
export function hoursToHHMMSS(input) {
    return secondsToHHMMSS(input * 3600);
}
export function secondsToHHMMSS(input) {
    var sec_num = parseInt(input, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
}
export function secondsToHHMM(input) {
    const hhmmss = secondsToHHMMSS(input);
    return hhmmss.split(':').slice(0, 2).join(':');
}
export function addTabListeners($tabs) {
    $tabs.removeWhiteSpaces();
    const $children = $tabs.find('.aci-tab');
    $children.each((i, tab) => {
        const $singleTab = $(tab);
        $singleTab.on('click', (e) => {
            $children.removeClass('active');
            $singleTab.addClass('active');
            const tabgroup = $tabs.attr('tabgroup');
            const tabview = $singleTab.attr('tab');
            $(`[tabgroup="${tabgroup}"][tabview]`).hide();
            const $tabToView = $(`[tabgroup="${tabgroup}"][tabview="${tabview}"]`);
            $tabToView.show();
            $tabs.trigger('ACI_TABS_CHANGED', { tabview });
            Events.tryInvoke(`ACI_TABS_CHANGED->${tabgroup}`, { tabgroup, tabview, $tabview: $tabToView });
            Events.tryInvoke(`ACI_TABS_CHANGED->${tabgroup}->${tabview}`, { tabgroup, tabview, $tabview: $tabToView });
        });
    });
    const $found = $tabs.find('.aci-tab.active');
    $found.trigger('click');
}
export function addTabListenersFind($container) {
    const $aciTabs = $container.find('.aci-tabs');
    if ($aciTabs.length > 0) {
        $(`[tabview]`).hide();
        $aciTabs.each((i, tabs) => {
            addTabListeners($(tabs));
        });
    }
}
export function autoReflowChart(chart) {
    assertHasValue(chart, `No chart found for autoReflow!`);
    // assertHasValue(chart.renderTo, `Chart.renderTo is not defined for autoReflow!`)
    try {
        _autoReflowChart(chart);
        _autoReflowChartTabs(chart);
    }
    catch (err) {
        console.error(err);
    }
    return chart;
}
export function _autoReflowChart(chart) {
    let eventName = EVENTS.CONTENT_RESIZE;
    let eventId = null;
    eventId = Events.on(eventName, () => {
        if (!chart) {
            console.warn(`Chart not found, disabling autoReflow`);
            Events.off(eventName, eventId);
            return;
        }
        chart.reflow();
    });
}
export function _autoReflowChartTabs(chart) {
    const $tabview = $(chart.renderTo).closest('[tabgroup][tabview]');
    const eventName = `ACI_TABS_CHANGED->${$tabview.attr('tabgroup')}->${$tabview.attr('tabview')}`;
    let eventId = null;
    eventId = Events.on(eventName, ({ tabgroup, tabview }) => {
        if (!chart) {
            console.warn(`Chart not found, disabling autoReflow [tabgroup=${tabgroup}][tabview=${tabview}]`);
            Events.off(eventName, eventId);
            return;
        }
        chart.reflow();
    });
}
// TODO: See if autoresize still functions & doesnt become annoying everytime you switch tab
export function _autoResizeGridToFitTabs(grid, reverse, skipResizeColumns) {
    const $tabview = $(grid.appendTo).closest('[tabgroup][tabview]');
    const eventName = `ACI_TABS_CHANGED->${$tabview.attr('tabgroup')}->${$tabview.attr('tabview')}`;
    let eventId = null;
    eventId = Events.once(eventName, ({ tabgroup, tabview }) => {
        if (!grid) {
            console.warn(`Grid not found, disabling autoResizeToFit [tabgroup=${tabgroup}][tabview=${tabview}]`);
            Events.off(eventName, eventId);
            return;
        }
        AResizeToFit(grid, reverse, skipResizeColumns);
    });
}
export function getLegacyClasses() {
    return {
        Alerts: AEngine.getOrCreateInstance(AAlertService),
        Loading: AEngine.getOrCreateInstance(ALoadingService),
        Events: AEngine.getOrCreateInstance(AEventService),
        Translate: AEngine.getOrCreateInstance(ATranslateService)
    };
}
/**
 * Example:
 * const treeBreakdown = createTreeBreakdown('level-display', 'levels', {
 *   level0: {
 *     level1: {
 *       level2a: '',
 *       level2b: '',
 *     }
 *   }
 * }
 */
export function createTreeBreakdownObj(rootLabel, obj, options) {
    const treeBreakdown = convertToTreeBreakdown(obj, rootLabel);
    options.idPrefix = options.idPrefix.replace(/\W+/g, '-');
    const html = createTreeBreakdownPartial(treeBreakdown, options);
    return ( /*html*/`<ul class="tree_breakdown" prefix="${options.idPrefix}">${html}</ul>`);
}
export function createTreeBreakdown(treeBreakdown, options) {
    options.idPrefix = options.idPrefix.replace(/\W+/g, '-');
    const html = createTreeBreakdownPartial(treeBreakdown, options);
    return ( /*html*/`<ul class="tree_breakdown" prefix="${options.idPrefix}">${html}</ul>`);
}
function convertToTreeBreakdown(obj, label) {
    const keys = Object.keys(obj).filter(k => obj.hasOwnProperty(k));
    let suffix = null;
    const breakdowns = keys.map(key => {
        if (key === 'suffix') {
            suffix = obj[key];
            return null;
        }
        return convertToTreeBreakdown(obj[key], key);
    }).filter(v => v !== null);
    return { data: label + (suffix || '') || '?', children: breakdowns };
}
function createTreeBreakdownPartial({ data: label, children, collapse }, options, id = 0) {
    const { idPrefix, lock } = options;
    const initialId = idPrefix + id;
    const childPartials = (children || []).map(child => {
        return createTreeBreakdownPartial(child, options, ++id);
    });
    if (childPartials.length === 0) {
        return ( /*html*/`<li><span class="tree_breakdown_label">${label}</span></li>`);
    }
    const icons = ( /*html*/`
    <span class="fa-stack aci-plus">
      <i class="fa-solid fa-circle fa-stack-2x"></i>
      <i class="fa-solid fa-plus fa-stack-1x fa-inverse"></i>
    </span>
    <span class="fa-stack aci-minus">
      <i class="fa-solid fa-circle fa-stack-2x"></i>
      <i class="fa-solid fa-minus fa-stack-1x fa-inverse"></i>
    </span>
  `);
    const attrs = [
        lock === true ? 'disabled="disabled"' : '',
        (collapse === true && lock !== true) ? '' : `checked="checked"`,
    ].join(' ');
    return ( /*html*/`
    <li>
      <input type="checkbox" id="c1-${initialId}" ${attrs} />
      <label class="tree_breakdown_label" for="c1-${initialId}">
        ${icons}
        ${label}
      </label>
      <ul>${childPartials.join('')}</ul>
    </li>
  `);
}
export function getBoundsZoomLevel(map, bounds) {
    let WORLD_DIM = { height: 256, width: 256 };
    let ZOOM_MAX = 21;
    function latRad(lat) {
        let sin = Math.sin(lat * Math.PI / 180);
        let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }
    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }
    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    let latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
    let lngDiff = ne.lng() - sw.lng();
    let lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;
    let latZoom = zoom(map.getDiv().offsetHeight + 80, WORLD_DIM.height, latFraction);
    let lngZoom = zoom(map.getDiv().offsetWidth + 80, WORLD_DIM.width, lngFraction);
    return Math.min(latZoom, lngZoom, ZOOM_MAX);
}
export function getMarkerBounds(markers) {
    let bounds = new google.maps.LatLngBounds();
    if (markers.length) {
        if (markers[0].position !== undefined) {
            for (let marker of markers) {
                bounds.extend(marker.position);
            }
        }
        else if (typeof markers[0].getPosition === 'function') {
            for (let marker of markers) {
                bounds.extend(marker.getPosition());
            }
        }
        else {
            for (let marker of markers) {
                const points = mapHelperService.getPoints(marker);
                for (let [lng, lat] of points) {
                    bounds.extend({ lng, lat });
                }
            }
        }
    }
    return bounds;
}
/**
 * @deprecated
 * @param {*} response
 * @returns
 */
export async function TransformResponseToObject(response) {
    const { Rows, Columns } = response;
    if (!Rows.length)
        return {};
    const out = {};
    for (let columnIndex in Columns) {
        out[Columns[columnIndex]] = Rows[0][columnIndex];
    }
    return out;
}
/**
* @deprecated
* @param {*} response
* @returns
*/
export async function TransformResponseToObjects(response) {
    const { Rows, Columns } = response;
    const output = [];
    for (let i = 0; i < Rows.length; i++) {
        const out = {};
        for (let columnIndex in Columns) {
            out[Columns[columnIndex]] = Rows[i][columnIndex];
        }
        output.push(out);
    }
    return output;
}
export async function TransformObjectsToResponse(items) {
    const objsKeys = Object.keys(items);
    if (objsKeys.length === 0) {
        return {
            Columns: [],
            ColumnsTranslated: [],
            Rows: []
        };
    }
    const firstKey = objsKeys[0];
    const translated = await Loading.waitForPromises(Translate.get(Object.keys(items[firstKey])));
    const response = {
        Columns: Object.keys(items[firstKey]),
        ColumnsTranslated: Object.values(translated),
        Rows: []
    };
    Object.keys(items).map(key => {
        const obj = items[key];
        response.Rows.push(Object.values(obj));
    });
    return response;
}
// export async function TransformObjectsToResponse(opt: {items: any[], keys?: string[]}): Promise<IResponse> {
export async function TransformObjectToResponse(item) {
    const keys = Object.keys(item);
    const translated = await Loading.waitForPromises(Translate.get(keys));
    const response = {
        Columns: keys,
        ColumnsTranslated: Object.values(translated),
        Rows: [Object.values(item)]
    };
    return response;
}
export function calculateWindowPosition({ $target, $popover }, offset) {
    const $body = $('body');
    let { left, top } = $target.offset();
    top += $target.outerHeight();
    const [bodyHeight, bodyWidth] = [$body.height() || 0, $body.width() || 0];
    const [_h, _w] = [$popover.height(), $popover.width()];
    if (top + _h > bodyHeight) {
        top = bodyHeight - _h;
    }
    left -= (_w - $target.width());
    if (left + _w > bodyWidth) {
        left = bodyWidth - _w;
    }
    return {
        left: (left + (offset?.left ?? 0)) + 'px',
        top: (top + (offset?.top ?? 0)) + 'px'
    };
}
export function isUserACI() {
    return _.getUser().UserGroups.includes(ACI_ADMIN);
}
export function copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
export function mergeDeep(target, ...sources) {
    if (!sources.length)
        return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return mergeDeep(target, ...sources);
}
export function asyncMapArray(array, partitionCount, callback) {
    if (array.length < partitionCount) {
        partitionCount = array.length;
    }
    return _asyncMap(array, callback, partitionCount);
}
function _asyncMap(realArray, callback, partitionCount) {
    const SIZE = realArray.length;
    if (SIZE === 0) {
        return Promise.resolve([]);
    }
    const partitionSize = Math.ceil(SIZE / partitionCount);
    const arrayPartitions = [];
    let array = Object.assign([], realArray);
    do {
        const partition = array.splice(0, partitionSize);
        arrayPartitions.push(partition);
    } while (array.length > 0);
    return Loading.waitForPromises(Promise.resolve().then(async (_) => {
        let promises = [];
        for (let i = 0; i < arrayPartitions.length; i++) {
            const progress = (i * 100 / arrayPartitions.length);
            if (Events.logLevel === 2)
                AEngine.log(`AsyncMapArray %p[%c${createArray(10, null).map((_, i) => (i * 10) >= progress ? '⣀' : '⣿').join('')}%p]%n ${ARound(progress, 2).toFixed(2)}%`);
            promises.push(processPartition(arrayPartitions, i, callback));
            await waitForChromeFrame();
        }
        if (Events.logLevel === 2)
            AEngine.log(`AsyncMapArray %p[%c${createArray(10, '⣿').join('')}%p]%n 100.00%`);
        const arrays = await Promise.all(promises);
        return [].concat.apply([], arrays);
    }));
}
async function processPartition(arrayPartitions, partitionIndex, callback) {
    const partitionResults = await Promise.all(arrayPartitions[partitionIndex].map(((value, index) => Promise.resolve().then(() => callback(value, arrayPartitions[0].length * partitionIndex + index)))));
    return partitionResults;
}
/**
 * Converts object to array by removing the keys & returning the values in the same order of the keys
 * @param obj
 * @returns
 */
export function convertObjectToArray(obj) {
    const keys = Object.keys(obj);
    const output = new Array(keys.length);
    for (let i = 0; i < keys.length; i++) {
        output[i] = obj[keys[i]];
    }
    return output;
}
export function getDummyElement() {
    return document.createElement('div');
}
export function stringify(val, depth, replacer, space) {
    depth = isNaN(+depth) ? 1 : depth;
    function _build(key, val, depth, o, a) {
        return !val || typeof val != 'object' ? val : (a = Array.isArray(val), JSON.stringify(val, function (k, v) { if (a || depth > 0) {
            if (replacer)
                v = replacer(k, v);
            if (!k)
                return (a = Array.isArray(v), val = v);
            !o && (o = a ? [] : {});
            o[k] = _build(k, v, a ? depth : depth - 1);
        } }), o || (a ? [] : {}));
    }
    return JSON.stringify(_build('', val, depth), null, space);
}
export function getApiBaseUrl() {
    return convertToApiBaseUrl(location.href);
}
export function getApiDescriptionUrl() {
    return getApiBaseUrl() + '/description/openapi.json';
}
export function convertToApiBaseUrl(urlString) {
    const url = new URL(urlString);
    const [_, ...rest] = url.host.split('.');
    url.host = (['api']).concat(rest).join('.');
    return url.origin;
}
export function createSelectScanDeviceListHtmlActive() {
    const scandevices = Sessions.filter((session) => {
        return (session.NodeType === "ScanAuto" || session.NodeType === "ScanScooter") &&
            ([
                "Connected", "ReadyToCount", "ReadyToEnforce",
                "ReadyToFollowUp", "LoggedIn", "Info", "SessionStarted",
                "SessionContinued", "LoggedIn"
            ]).includes(session.Status);
    });
    const deviceNames = scandevices.map(session => session.DeviceName);
    const options = [`<option selected value=""></option>`].concat(deviceNames.map((device) => (`<option value="${device}">${device}</option>`)));
    const html = (`<select class="form-input" name="devices-dropdown" id="devices-dropdown">${options.join('')}</select>`);
    return html;
}
export function createSelectScanDeviceListHtmlAll(id) {
    var $html = /*html*/ `<select class="form-input" name="devices-dropdown" id=${id}><option selected value=""></option>`;
    for (let device in AllDevices) {
        if (device.startsWith("Scan")) {
            $html += `<option value=${device}>${device}</option>`;
        }
    }
    $html += `</select>`;
    return $html;
}
export function waitForChromeFrame() {
    const promise = new Promise(requestAnimationFrame);
    return promise.then(function frameCallback(timestamp) {
        return timestamp;
    });
}
Object.assign(globalThis, { waitForChromeFrame });
export function secondsPassed(date1, date2) {
    let second = 1000;
    let date1_ms = date1.getTime();
    let date2_ms = date2.getTime();
    let difference_ms = date2_ms - date1_ms;
    return Math.round(difference_ms / second);
}
export function weeksPassed(date1, date2) {
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7);
    return Math.abs(Math.round(diff));
}
export function formatPrice(str, prefix = '€', suffix = '') {
    const price = Number(str);
    if (!isNaN(price)) {
        let fixedNum = ARound(price, 2).toFixed(2);
        const formattedPrice = ((fixedNum.endsWith('00')) ? fixedNum.substring(0, fixedNum.length - 2) + '-' : fixedNum);
        return [prefix, formattedPrice, suffix].filter(v => v !== undefined && v !== '').join(' ');
    }
    AError.handleSilent(`Invalid Price ${str}`);
    return str ? str + '' : '';
}
export async function saveFilterProfile() {
    let filterProfiles = AConfig.get('filter-profiles', {}) || {};
    const overrideExistingProfileText = await Loading.waitForPromises(Translate.get('Override Existing Profile'));
    const hasExistingProfiles = (filterProfiles.hasOwnProperty(routeService.url.hash));
    const alert = Alerts.show({
        translatedTitle: await Translate.get('Save Filters'),
        content: ( /*html*/`
      <form>
        <div class="form-group">
          <label class="form-checkbox">
            <input id="show-dropdown" type="checkbox" ${hasExistingProfiles ? '' : `disabled="disabled"`}>
            <i class="form-icon"></i> ${overrideExistingProfileText}
          </label>
        </div>

        <input id="profile-name" class="form-input" placeholder="..." />

        <select id="profile-dropdown" class="form-select hidden">
        </select>
      </form>
    `),
        buttons: ALERT_BUTTONS.saveCancel
    });
    const { $ele } = alert;
    const $profileName = $ele.find('#profile-name');
    const $profileDropdown = $ele.find('#profile-dropdown');
    $profileDropdown.html('');
    if (filterProfiles.hasOwnProperty(routeService.url.hash)) {
        const profile = filterProfiles[routeService.url.hash];
        $profileDropdown.html(Object.keys(profile).map(key => `<option value="${key}">${key}</option>`).join(''));
    }
    const $showdd = $ele.find('#show-dropdown');
    $showdd.on('change', (e) => {
        console.log('show dropdown: ', $showdd.prop('checked'));
        $profileName.toggleClass('hidden', $showdd.prop('checked'));
        $profileDropdown.toggleClass('hidden', !$showdd.prop('checked'));
    });
    alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
        let name = ($showdd.prop('checked') ? $profileDropdown.val() : $profileName.val()).trim();
        if (!name || name.length === 0) {
            Alerts.show({
                title: ALERT_TITLES.Warning,
                content: await Loading.waitForPromises(Translate.get(`Please input a name for the filter settings`))
            });
            return false;
        }
        if (/[^a-zA-Z0-9 ]/.test(name)) {
            Alerts.show({
                title: ALERT_TITLES.Warning,
                content: await Loading.waitForPromises(Translate.get(`Please refrain from using symbols in the filter settings name`))
            });
            return false;
        }
        filterProfiles = AConfig.get('filter-profiles', {}) || {};
        if (!filterProfiles.hasOwnProperty(routeService.url.hash)) {
            filterProfiles[routeService.url.hash] = {};
        }
        const filters = FilterManager.save({ cacheFilters: false });
        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                const $dd = $(`#${key}`);
                const dd = $dd.data('DropDown');
                if ($dd.length && dd !== undefined) {
                    if (dd instanceof ADropDown) {
                        filters[key] = dd.selectedKeys;
                    }
                    else {
                        filters[key] = dd.selectedTextsQuery;
                    }
                }
            }
        }
        filterProfiles[routeService.url.hash][name] = filters;
        await AConfig.update('filter-profiles', filterProfiles);
        // Reinitialize popover events
        await initQuickSelectShortcutPopovers();
        // Update disabled attribute
        updateFilterProfileUI();
    });
}
let popover = undefined;
export async function initQuickSelectShortcutPopovers() {
    // Load profiles
    const savedProfileArr = loadFilterMap();
    // Create clickable list of load-profiles 
    const $loadProfileHrefs = Object.keys(savedProfileArr).map(title => {
        return $(/*html*/ `<a href="#">${title}</a>`).on('click', (e) => {
            e.preventDefault();
            FilterManager.loadFromSavedProfile(title);
        });
    });
    // Destroy previous popover
    popover?.destroy();
    popover = await menuService.addPopover($loadProfileHrefs, {
        $relativeParent: $('#AjaxContent'),
        uid: 'filters-load',
        trigger: 'click',
    });
    FilterManager.highlightSelectedShortcut();
    updateFilterProfileUI();
}
export function loadFilterMap(hash = routeService.url.hash) {
    let filterProfiles = (AConfig.get('filter-profiles', {}) || {});
    if (!filterProfiles.hasOwnProperty(hash)) {
        return {};
    }
    return filterProfiles[hash];
}
/**
 * @deprecated
 */
export async function loadFilterMapDialog() {
    const filterProfiles = loadFilterMap();
    if (Object.keys(filterProfiles).length === 0) {
        Alerts.show({
            title: ALERT_TITLES.Warning,
            content: await Loading.waitForPromises(Translate.get(`There are no saved filtersettings for this page!`))
        });
        return;
    }
    const alert = Alerts.show({
        translatedTitle: await Translate.get('Load Filters'),
        content: ( /*html*/`<form><select id="profile-dropdown" class="form-select"></select></form>`),
        buttons: ALERT_BUTTONS.okCancel
    });
    const { $ele } = alert;
    const $profileDropdown = $ele.find('#profile-dropdown');
    $profileDropdown.html('');
    const profileMap = filterProfiles[routeService.url.hash];
    $profileDropdown.html(Object.keys(profileMap).map(key => `<option value="${key}">${key}</option>`).join(''));
    alert.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
        FilterManager.loadFromSavedProfile($profileDropdown.val());
    });
}
export async function editFilterProfile() {
    let filterProfiles = AConfig.get('filter-profiles', {}) || {};
    if (!filterProfiles.hasOwnProperty(routeService.url.hash)) {
        Alerts.show({
            title: ALERT_TITLES.Warning,
            content: await Loading.waitForPromises(Translate.get(`There are no saved filtersettings for this page!`))
        });
        return;
    }
    const profileMap = filterProfiles[routeService.url.hash];
    const alert = Alerts.show({
        title: ALERT_TITLES.None,
        content: ( /*html*/`
      <form>
        <table class="table table-striped table-hover table-pointer">
        ${Object.keys(profileMap).map(profileName => {
            return ( /*html*/`
            <tr>
              <td>
                <div class="input-group">
                  <div class="noselect ns-children">
                    <label class="form-checkbox" style="margin: 0">
                      <input profile="${profileName}" type="checkbox" class="hidden noselect">
                      <i class="form-icon"></i> ${profileName}
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            `);
        }).join('')}
        </table>
      </form>
    `),
        buttons: ALERT_BUTTONS.deleteCancel
    });
    const { $ele } = alert;
    const $delBtn = alert.$ele.find('#option1');
    let $chckArr = [];
    $ele.find('tr').on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        let $t = $(e.target);
        const $tr = ((!$t.is('tr')) ? $t.closest('tr') : $t);
        const $chck = $tr.find('[type="checkbox"]');
        const $lbl = $tr.find('label');
        $chck.prop('checked', !$chck.prop('checked'));
        $lbl.css('text-decoration', ($chck.prop('checked')) ? 'line-through' : '');
        $chckArr = $('input[profile][type="checkbox"]:checked').toArray().map(e => $(e));
        alert.$ele.find('#option1').prop('disabled', $chckArr.length === 0);
    });
    $delBtn.prop('disabled', $chckArr.length === 0);
    alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
        const profilesToDelete = $chckArr.map($c => { return $c.attr('profile'); });
        AEngine.log('profilesToDelete', profilesToDelete);
        profilesToDelete.map(profile => { delete profileMap[profile]; });
        filterProfiles[routeService.url.hash] = profileMap;
        // Update database & local cache
        await AConfig.update('filter-profiles', filterProfiles);
        // Reinitialize popover events
        await initQuickSelectShortcutPopovers();
        // Update disabled attribute
        updateFilterProfileUI();
    });
}
export function updateFilterProfileUI() {
    const $filters = $('#Filters');
    let filterProfiles = AConfig.get('filter-profiles', {});
    if (!filterProfiles.hasOwnProperty(routeService.url.hash) || Object.keys(filterProfiles[routeService.url.hash]).length === 0) {
        $filters.find('#filters-load, #filters-edit').attr('disabled', 'disabled');
    }
    else {
        $filters.find('#filters-load, #filters-edit').removeAttr('disabled');
    }
}
export function generateTreeDropdown(ele, unification, opt) {
    filterService.initDropDown(ele, opt);
    return ADropDownTree.generateTreeDropdown(ele, unification, opt);
}
export function copyFormData($form1, $form2) {
    $('[type="checkbox"][name]', $form2).prop('checked', function () {
        return $(':input[name=' + $(this).attr('name') + ']', $form1).prop('checked');
    });
    // @ts-ignore
    $(':input[name]', $form2).val(function () {
        return $(':input[name=' + $(this).attr('name') + ']', $form1).val();
    });
}
// export function setFormData($form: JQuery, data: {[k: string]: any }) {
//   Object.keys(data).map(key => {
//     $form.find(`[name="${key}"]`)
//   })
// }
export function mainColor() {
    return getComputedStyle(document.documentElement).getPropertyValue('--main-color');
}
export function initAccordions($container, opt) {
    const clickableSelector = opt?.clickableSelector ?? '.h6';
    const $accordions = $container.find(`.accordion-wrapper ${clickableSelector}:not(.accordion_initialized)`);
    $accordions.addClass('accordion_initialized');
    $accordions.on('click', (e) => {
        e.preventDefault();
        const $conf = $(e.target).closest('.accordion-wrapper');
        const $group = $conf.closest('.accordion-group');
        $group.find('.accordion-wrapper').each((i, wr) => {
            const $acwrp = $(wr);
            if ($acwrp.is($conf)) {
                // Clicked this one so this should ope
            }
            else {
                $acwrp.find('.hidable').slideUp(150);
                $acwrp.find('.icon-arrow-right,icon-arrow-down,icon-arrow-left,icon-arrow-up').toggleClass('rotated-90', false);
            }
        });
        $conf.find('.hidable').slideToggle(150);
        $conf.find('.icon-arrow-right,icon-arrow-down,icon-arrow-left,icon-arrow-up').toggleClass('rotated-90');
    });
}
export function secondsToDurationTextHHMM(seconds) {
    let hours = 0;
    let minutes = 0;
    let result = "";
    if (seconds > 3600) {
        hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        result = (hours + "h ");
    }
    if (seconds > 60) {
        minutes = Math.floor(seconds / 60);
        seconds = 0;
        result += (minutes + "m");
    }
    return result;
}
export function metersToKilometerText(meters) {
    let result = (meters / 1000).toFixed(2);
    result += " km";
    return result;
}
export function transformButtonCls(color) {
    switch (color) {
        case null:
        case undefined:
        case "default":
        case "blue":
            return 'btn-primary';
        case "grey":
            return 'btn-grey';
        case "green":
        case "success":
            return 'btn-success';
        case "orange":
            return 'btn-orange';
        case "yellow":
            return 'btn-yellow';
        case "red":
        case "error":
            return 'btn-red';
        case 'purple':
            return 'btn-purple';
        default:
            console.warn(`AVerificationButtonCls "${color}" is not recognized!`);
            return '';
    }
}
export function transformSpanCls(color) {
    const map = {
        'default': 'label-primary',
        'blue': 'label-blue',
        'grey': 'label-grey',
        'green': 'label-green',
        'yellow': 'label-yellow',
        'orange': 'label-orange',
        'red': 'label-red',
        'success': 'label-green',
        'error': 'label-red',
        'purple': 'label-purple',
    };
    if (color !== undefined && map.hasOwnProperty(color)) {
        return map[color];
    }
    return map['default'];
}
export function transformTextCls(color) {
    const map = {
        'default': 'text-default',
        'blue': 'text-default',
        'grey': 'text-grey',
        'green': 'text-green',
        'yellow': 'text-yellow',
        'orange': 'text-orange',
        'red': 'text-red',
        'success': 'text-green',
        'error': 'text-red',
        'purple': 'text-purple',
    };
    if (color !== undefined && map.hasOwnProperty(color)) {
        return map[color];
    }
    return map['default'];
}
export function flipInputValues(inp1, inp2, parent) {
    const $parent = _getEle$(parent ?? 'body');
    const $h = $parent.find(_getEle$(inp1)), $v = $parent.find(_getEle$(inp2));
    const h = $h.val();
    $h.val($v.val());
    $v.val(h);
    $v.trigger('change');
}
export function enableMaterialTheme() {
    var r = document.documentElement;
    if (r === null) {
        return;
    }
    // var rs = getComputedStyle(r)
    r.style.setProperty('--header-margin-bottom', '0px');
    r.style.setProperty('--sidebar-margin-right', '0px');
    r.style.setProperty('--main-border-radius', '0px');
    r.style.setProperty('--header-background-color', '#6690ff');
    r.style.setProperty('--header-icon-color', '#e3e3e3');
    r.style.setProperty('--header-font-color', '#e3e3e3');
    r.style.setProperty('--sidebar-font-color', '#efefef');
    r.style.setProperty('--sidebar-icon-color', '#efefef');
    r.style.setProperty('--sidebar-font-hover-color', '#efefef');
    r.style.setProperty('--sidebar-icon-hover-color', '#efefef');
    r.style.setProperty('--header-font-hover-color', '#efefef');
    $('body').addClass('material-theme');
    $('.sidebar-menu').css({
        'background': '#463bfb',
        'box-shadow': '#00000029 0 1px 8px 0px',
        'z-index': '15',
    });
    $('.header-strip').css({
        'position': 'sticky',
        'box-shadow': '#00000061 0 1px 13px -7px',
        'z-index': '16',
    });
}
Object.assign(globalThis, { enableMaterialTheme });
export function estimateRouteDurationFromDistance(route_dist_meter) {
    let avarage_speed_mps = 15 / 3.6; // TODO ergens vandaan halen oid, (21 is te snel -> 15 km/h)
    return Math.ceil((route_dist_meter / avarage_speed_mps) / 60) * 60;
}
export function estimateRouteDistanceFromParkingStreetDistance(parking_street_meter) {
    return parking_street_meter * 1.9; // Route is 'roughtly' this longer 
}
export function estimateRouteDurationFromParkingStreetDistance(parking_street_meter) {
    return estimateRouteDurationFromDistance(estimateRouteDistanceFromParkingStreetDistance(parking_street_meter));
}
export function escapeHtmlChars(input) {
    return input
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
        .replace(/\//g, '&#x2F;');
}
export function toShortNodeName(NodeName, opt) {
    const matches = /[0-9]+/.exec(NodeName);
    const NodeIndex = (matches) ? matches[0] : '';
    const NodeType = NodeName.replace(/[0-9]+/, '').trim();
    let NodeNameShort;
    if (opt.translate === false) {
        NodeNameShort = [NodeType.replace(/\W+/, ' ').split(' ').map(str => str[0]).join(''), ...(matches ? [NodeIndex] : [])].join('');
        return { NodeIndex, NodeName, NodeType, NodeNameShort };
    }
    return Translate.get(NodeType).then((str) => {
        const NodeNameShort = [str.replace(/\W+/, ' ').split(' ').map(str => str[0]).join(''), ...(matches ? [NodeIndex] : [])].join('');
        return { NodeIndex, NodeName, NodeType, NodeNameShort };
    });
}
export function formatStringToPascal(string) {
    return APascal(string
        .replace(/([a-z])([A-Z0-9])/g, '$1 $2')
        .replace(/\s+/g, ' ')
        .trim()
        .replace(/([ ])(.)/g, (_, g1, g2) => g1 + g2.toUpperCase()));
}
export function formatNodeName(nodeName) {
    return nodeName
        .replace(/([a-z])([A-Z0-9])/g, '$1 $2')
        // .replace(/([A-z])([0-9])/g, '$1 $2')
        .replace(/\s+/g, ' ')
        .trim()
        .replace(/([ ])(.)/g, (_, g1, g2) => g1 + g2.toUpperCase());
}
// export function getScanDevicesSorted(nodeNames: string[]) {
//   const matches = Object.keys(ScanDevices).map(str => (/([A-z]+)([0-9]+)/.exec(str))).filter(m => m !== null) as RegExpExecArray[]
//   const sorted = matches.map((m) => m).sort((a, b) => a[1].localeCompare(b[1]) ?? Number(a[2]) - (Number(b[2])))
//   return sorted.map(m => m[0])
// }
export function DeviceMultiOptions() {
    const ScanAutoArr = ScanDeviceArray.filter(d => d.deviceKey.startsWith('ScanAuto'));
    const ScanScooterArr = ScanDeviceArray.filter(d => d.deviceKey.startsWith('ScanScooter'));
    const PdaArr = ScanDeviceArray.filter(d => d.deviceKey.startsWith('Pda'));
    return {
        Key: 'ScanDevices',
        KeyShort: 'ScanDevices',
        FirstIndex: 0,
        LastIndex: 1000000,
        Options: {
            'scanauto': {
                Key: 'ScanAuto',
                KeyShort: 'ScanAuto',
                FirstIndex: 0,
                LastIndex: ScanAutoArr.length,
                Options: ScanAutoArr.map((d, i) => {
                    return {
                        Key: d.deviceId,
                        KeyShort: d.deviceName,
                        FirstIndex: d.deviceId,
                        LastIndex: d.deviceId,
                    };
                })
            },
            'scanscooter': {
                Key: 'ScanScooter',
                KeyShort: 'ScanScooter',
                FirstIndex: ScanAutoArr.length,
                LastIndex: ScanAutoArr.length + ScanScooterArr.length,
                Options: ScanScooterArr.map((d, i) => {
                    return {
                        Key: d.deviceId,
                        KeyShort: d.deviceName,
                        FirstIndex: d.deviceId,
                        LastIndex: d.deviceId,
                    };
                })
            },
            'pda': {
                Key: 'Pda',
                KeyShort: 'Pda',
                FirstIndex: ScanAutoArr.length + ScanScooterArr.length,
                LastIndex: ScanAutoArr.length + ScanScooterArr.length + PdaArr.length,
                Options: PdaArr.map((d, i) => {
                    return {
                        Key: d.deviceId,
                        KeyShort: d.deviceName,
                        FirstIndex: d.deviceId,
                        LastIndex: d.deviceId,
                    };
                })
            },
        }
    };
}
export function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}
export function compareObjects(prev, current, opt) {
    const rightKeys = Object.keys(current);
    var deepDiffMapper = function () {
        return {
            VALUE_CREATED: 'created',
            VALUE_UPDATED: 'updated',
            VALUE_DELETED: 'deleted',
            VALUE_UNCHANGED: 'unchanged',
            map: function (obj1, obj2) {
                if (this.isFunction(obj1) || this.isFunction(obj2)) {
                    throw 'Invalid argument. Function given, object expected.';
                }
                if (this.isValue(obj1) || this.isValue(obj2)) {
                    const comparison = this.compareValues(obj1, obj2);
                    if (comparison === this.VALUE_UNCHANGED)
                        return comparison;
                    // return {
                    //   type: comparison,
                    //   data: obj1 === undefined ? obj2 : obj1
                    // };
                    return obj1 === undefined ? obj2 : obj1;
                }
                var diff = {};
                const skipped = {};
                for (var key in obj1) {
                    if (this.isFunction(obj1[key])) {
                        continue;
                    }
                    var value2 = undefined;
                    if (obj2[key] !== undefined) {
                        value2 = obj2[key];
                    }
                    const newval = this.map(obj1[key], value2);
                    if (newval === this.VALUE_UNCHANGED) {
                        skipped[key] = true;
                        continue;
                    }
                    // Not sure if this fixes the problem of arrays not being compared correctly
                    if (Object.keys(newval).length === 0) {
                        skipped[key] = true;
                        continue;
                    }
                    diff[key] = newval;
                }
                for (var key in obj2) {
                    if (skipped[key] === true) {
                        continue;
                    }
                    if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
                        continue;
                    }
                    const newval2 = this.map(undefined, obj2[key]);
                    if (newval2 === this.VALUE_UNCHANGED) {
                        continue;
                    }
                    diff[key] = newval2;
                }
                return diff;
            },
            compareValues: function (value1, value2) {
                if (value1 === value2) {
                    return this.VALUE_UNCHANGED;
                }
                if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
                    return this.VALUE_UNCHANGED;
                }
                if (value1 === undefined) {
                    return this.VALUE_CREATED;
                }
                if (value2 === undefined) {
                    return this.VALUE_DELETED;
                }
                return this.VALUE_UPDATED;
            },
            isFunction: function (x) {
                return Object.prototype.toString.call(x) === '[object Function]';
            },
            isArray: function (x) {
                return Object.prototype.toString.call(x) === '[object Array]';
            },
            isDate: function (x) {
                return Object.prototype.toString.call(x) === '[object Date]';
            },
            isObject: function (x) {
                return Object.prototype.toString.call(x) === '[object Object]';
            },
            isValue: function (x) {
                return !this.isObject(x) && !this.isArray(x);
            }
        };
    }();
    // const a = {
    //   a: 'beep boop',
    //   b: 1,
    //   g: new Date('2017.11.25')
    // }
    // const b = {
    //   a: 'hello world!',
    //   b: 'oh word!',
    //   g: new Date('2017.11.25')
    // }
    var result = deepDiffMapper.map(current, prev);
    if (opt?.ignore && opt.ignore.length > 0) {
        Object.keys(result).map(key => {
            if (opt.ignore.includes(key)) {
                delete result[key];
            }
        });
    }
    if (opt?.onlyUseRightKeys === true) {
        // AEngine.log(`info`, { leftKeys: rightKeys, resultKeys: Object.keys(result)})
        Object.keys(result).map(key => {
            if (!rightKeys.includes(key)) {
                delete result[key];
            }
        });
    }
    return result;
}
export function isValidJson(jsonText) {
    try {
        JSON.parse(jsonText);
        return true;
    }
    catch (err) {
        return false;
    }
}
export function debounce(callback, wait) {
    let event = null;
    return function (...args) {
        const later = () => {
            event = null;
            callback(...args);
        };
        clearTimeout(event);
        event = setTimeout(later, wait);
    };
}
export function printImageCss($ele, opt) {
    return printImage(AStripUrl($ele.css('background-image')), opt);
}
export function printImage(base64, opt) {
    return Loading.waitForPromises(Translate.get('Please close the printing window to continue')).then((text) => {
        const $overlay = $(`<div id="printOverlay" class="overlay">${text}</div>`);
        $('body').append($overlay);
        // const ele = _getEle(qs)!
        let popup = window.open('', 'PRINT', 'dialog=1');
        popup.document.write(/*html*/ `
      <style>
        html, body {
          height: auto;
          page-break-before: avoid;
          page-break-after: avoid;
          border: 1px solid white;
          height: 99%;
          text-align: center;
        }
        html {
          -webkit-print-color-adjust: exact;
          margin: 0;
          padding: 0;
        }
        img,.print {
          page-break-before: avoid;
          page-break-after: avoid;
        }
      </style>
    `);
        popup.document.write(`<link rel="preload" as="image" href="${base64}" media="(max-width: ${opt?.maxWidth ?? "2500"}px)">`);
        popup.document.write(`<img src="${base64}" />`);
        // popup.document.write(ele.innerHTML)
        popup.document.close(); // necessary for IE >= 10
        popup.focus(); // necessary for IE >= 10*/
        popup.print();
        popup.close();
        $('#printOverlay').remove();
    }).catch((err) => {
        AError.handleSilent(err);
    });
}
Object.assign(globalThis, { compareObjects, printImage, printImageCss });
