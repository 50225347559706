import { ACrypto } from "../classes/ACrypto.js";
import { AError } from "../classes/AError.js";
import { MYSQL_FALSE, MYSQL_TRUE } from "../utils/query.js";
import { logToDatabase, stringifyChanges } from "./_AOrm.js";
const SELECT_FIELDS = `u.User, DisplayName, GROUP_CONCAT(g.UserGroup) AS UserGroups, \`Function\`, ExternalId, NULL AS Pass, LastAttempt, FirstSeen, LastSeen, FirstName, LastName`;
export class AUserOrm {
    constructor() {
        this.dbName = 'users';
        this.pkString = 'User';
        this.MYSQL_ALLOWED_COLUMNS = [
            "User",
            "Pass",
            "Salt",
            "FirstName",
            "LastName",
            "DisplayName",
            "Function",
            "ExternalId",
            "AuthenticatorKey",
            "AuthenticatorMustInit",
            "AuthenticatorInitCode",
            "AuthenticatorQR",
            'UserGroups'
        ];
        this.COLUMN_MAPPER = {
            'FirstName': async (obj) => {
                return [
                    { key: 'FirstName', value: obj['FirstName'] },
                    { key: 'DisplayName', value: `${obj['FirstName']} ${obj['LastName']}` }
                ];
            },
            'LastName': async (obj) => {
                return [
                    { key: 'LastName', value: obj['LastName'] },
                    { key: 'DisplayName', value: `${obj['FirstName']} ${obj['LastName']}` }
                ];
            },
            'UnsaltedPass': async (obj) => {
                if ((obj.UnsaltedPass ?? "").length === 0) {
                    return [{ key: 'Pass', destroy: true }];
                }
                const { hash, salt } = await this.hashPassword({ User: obj.User, UnsaltedPass: obj.UnsaltedPass });
                return [
                    { key: 'UnsaltedPass', destroy: true },
                    { key: 'Pass', value: hash },
                    { key: 'Salt', value: salt },
                ];
            }
        };
        this.OBFUSCATE_COLUMNS = {
            'UnsaltedPass': true,
            'Pass': true,
            'Salt': true,
        };
    }
    get emptyModel() {
        return {
            User: '',
            DisplayName: '',
            FirstSeen: new Date(),
            LastSeen: new Date(),
            FirstName: '',
            LastName: '',
            Function: '',
            ExternalId: '',
            UserGroups: []
        };
    }
    async transformModel(options, opt) {
        const output = opt?.modifyRef ? options : Object.assign({}, options);
        const keysToDelete = [];
        if (opt?.allowedKeys !== undefined) {
            Object.keys(output).map(k => {
                if (!opt.allowedKeys.includes(k)) {
                    keysToDelete.push(k);
                }
            });
        }
        await Promise.all(Object.keys(output).map(async (k) => {
            if (this.COLUMN_MAPPER.hasOwnProperty(k)) {
                const keyOrKeys = await Promise.resolve().then(() => this.COLUMN_MAPPER[k](output));
                const keyValuePairs = (Array.isArray(keyOrKeys)) ? keyOrKeys : [keyOrKeys];
                for (const pair of keyValuePairs) {
                    if (pair.destroy === undefined) {
                        output[pair.key] = pair.value;
                    }
                    else {
                        keysToDelete.push(pair.key);
                    }
                }
            }
        }));
        const uniqueKeysToDelete = [...new Set(keysToDelete)];
        for (let k of uniqueKeysToDelete) {
            delete output[k];
        }
        return output;
    }
    async hashPassword(opt) {
        let secret = ACrypto.md5String(opt.User + opt.UnsaltedPass);
        if (ACrypto.subtleAvailable()) { // Only over https or localhost!
            return ACrypto.hashSecret(secret);
        }
        return { hash: secret, salt: null };
    }
    async find(opt) {
        return await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT ${SELECT_FIELDS}
        FROM users u
        LEFT JOIN user_usergroups g USING (User)
        LEFT JOIN (SELECT User, MIN(UserSessionStart) as FirstSeen , MAX(UserSessionStart) as LastSeen FROM user_session_start group by user) t using (User)
        WHERE User=:User
        GROUP BY u.User
      `),
            Params: opt
        });
    }
    async fetchAll() {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT ${SELECT_FIELDS}
        FROM users u
        LEFT JOIN user_usergroups g USING (User)
        LEFT JOIN (SELECT User, MIN(UserSessionStart) as FirstSeen , MAX(UserSessionStart) as LastSeen FROM user_session_start group by user) t using (User)
        GROUP BY u.User
        ORDER BY u.User
      `),
            Params: { MYSQL_TRUE, MYSQL_FALSE },
            Translate: ['RegimeTypeTranslated', 'ChannelCodeTranslated'],
            Language: Language
        }, {
            valueMapper: {
                UserGroups: (input) => input?.split(',') ?? []
            }
        });
        return rows;
    }
    async create(options) {
        const found = await this.find(options);
        if (found.Rows.length > 0) {
            AError.handleSilent(`db entry already exists! ${this.dbName}.${this.pkString}=${options[this.pkString]}`, `${this.pkString} Already Exists`);
            return {
                success: false,
                category: 'DUPLICATE_ENTRY',
                msg: [await Translate.get(`${this.pkString} Already Exists:`), `${options[this.pkString]}`],
            };
        }
        const model = await this.transformModel(options);
        logToDatabase({
            category: 'USER',
            action: 'CREATE',
            target: model.User,
            description: `CREATED USER <${model.User}>`
        });
        await requestService.query({
            Query: ( /*SQL*/`
        INSERT INTO users (
          User,
          FirstName,
          LastName,
          DisplayName,
          Function,
          ExternalId,
          Pass,
          Salt,
          ModificationUser
        ) VALUES (
          :User,
          :FirstName,
          :LastName,
          CONCAT(:FirstName, ' ', :LastName),
          :Function,
          :ExternalId,
          :Pass,
          :Salt,
          :ModificationUser
        )
      `),
            Params: {
                ...Object.assign(this.emptyModel, model),
                ModificationUser: _.getUser().User,
                ModificationDevice: _.getSession().DeviceName
            }
        });
        await this.updateRights({ User: model.User }, model);
        return { success: true };
    }
    async update(pk, changes) {
        const res = await this.find(pk);
        if (res.Rows.length === 0) {
            AError.handleSilent(`Couldn't find db entry ${this.dbName}.${this.pkString}=${changes[this.pkString]}`, `${this.pkString} Not Found`);
            return {
                success: false,
                category: 'ENTRY_NOT_FOUND',
                msg: [`${this.pkString} ${changes[this.pkString]} Not Found`],
            };
        }
        const model = await this.transformModel(changes, { allowedKeys: this.MYSQL_ALLOWED_COLUMNS });
        // AEngine.log('model', model)
        logToDatabase({
            category: 'USER',
            action: 'UPDATE',
            target: model.User,
            description: `UPDATED USER <${stringifyChanges(model, this.OBFUSCATE_COLUMNS)}>` // `UserGroups = ${changes.UserGroups ?? 'NULL'}`
        });
        await requestService.query({
            Query: ( /*SQL*/`
        UPDATE users
        SET 
          User=:User,
          FirstName=COALESCE(:FirstName, FirstName),
          LastName=COALESCE(:LastName, LastName),
          DisplayName=CONCAT(COALESCE(NULLIF(:FirstName, ''), FirstName, ''), ' ', COALESCE(NULLIF(:LastName, ''), LastName, '')),
          Pass=COALESCE(:Pass, Pass),
          Salt=COALESCE(:Salt, Salt),
          \`Function\`=COALESCE(:Function, \`Function\`),
          ExternalId=COALESCE(:ExternalId, ExternalId),
          ModificationUser=:ModificationUser,
          ModificationTime=CURRENT_TIMESTAMP
        WHERE User=:User
      `),
            Params: Object.assign({
                ...Object.assign(this.emptyModel, model),
                ModificationUser: _.getUser().User,
                ModificationDevice: _.getSession().DeviceName,
            }, { User: pk.User })
        });
        if (model.UserGroups !== undefined) {
            await this.updateRights(pk, model);
        }
        return { success: true };
    }
    async delete(options) {
        logToDatabase({
            category: 'USER',
            action: 'DELETE',
            target: options.User,
            description: `DELETED USER <${options.User}>`
        });
        await requestService.query({
            Query: (`DELETE FROM users WHERE User=:User`),
            Params: options
        });
    }
    async updateRights(pk, options) {
        logToDatabase({
            category: 'USER',
            action: 'UPDATE_USERGROUPS',
            target: pk.User,
            description: `SET USERGROUPS <${options.UserGroups}>`
        });
        requestService.query({
            Query: `DELETE FROM user_usergroups WHERE User=:User`,
            Params: { User: pk.User }
        });
        await Promise.all([
            ...(options.UserGroups || []).map((group) => {
                return requestService.query({
                    Query: `INSERT INTO user_usergroups (User, UserGroup) VALUES (:User, :UserGroup)`,
                    Params: { User: pk.User, UserGroup: group }
                });
            })
        ]);
    }
}
