import { COLUMN_BOOLEAN, COLUMN_DATETIME, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AConvertToGridColumns, AConvertToGridData, AGetGridRecord, AShowTable, AUrlEncodedImageFromBase64 } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.load();
        this.identifiers = [];
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    async init() {
        this.translations = await Translate.get([
            'true',
            'false',
            'print',
            'no image',
            'Please close the printing window to continue'
        ]);
    }
    appendToTable($tbody, html) {
        const $row = $(html);
        $tbody.append($row);
        return $row;
    }
    fetchDetailedFine(primaryKeys) {
        let conditions = Object.keys(primaryKeys).map((key) => {
            return `${key}=:${key}`;
        }).join(' AND ');
        return Loading.waitForPromises(requestService.query({
            Query: `SELECT FineImage FROM verifications WHERE ${conditions} AND FineImage IS NOT NULL`,
            Params: primaryKeys
        }));
    }
    async print(primaryKeys, data) {
        const fineData = await this.fetchDetailedFine(primaryKeys);
        if (!fineData.Rows.length)
            return Alerts.noResults();
        const base64 = AUrlEncodedImageFromBase64(fineData.Rows[0][0]);
        if (base64.length === 0) {
            return Alerts.noResults();
        }
        const $overlay = $(`
      <div id="printOverlay" class="overlay">
        ${this.translations['Please close the printing window to continue']}
      </div>
    `);
        $('body').append($overlay);
        let popup = window.open('', 'PRINT', 'dialog=1');
        this.data = { primaryKeys, data };
        popup.document.write(`<link rel="preload" as="image" href="${base64}" media="(max-width: 600px)">`);
        popup.document.write(`<img src="${base64}" />`);
        popup.document.close(); // necessary for IE >= 10
        popup.focus(); // necessary for IE >= 10*/
        popup.print();
        popup.close();
        this.popup = popup;
        $('#printOverlay').remove();
    }
    refresh() {
        const filters = FilterManager.save();
        FilterManager.setActive(false);
        const { LicensePlate, FineNumber, FromDate, ToDate, Limit } = filters;
        return Loading.waitForPromises(requestService.query({
            Query: (`
          SELECT
            VerificationEndTime,
            LicensePlate,
            FineNumber,
            FineImage IS NOT NULL as HasImage,
            FineImage IS NOT NULL as Action,

            DetectionId,
            DetectionDeviceId,
            DetectionVersion,
            ParkingRightVersion,
            VerificationVersion,
            VerificationResult
          FROM
            verifications
          WHERE
            ${LicensePlate != '' ? 'LicensePlate LIKE :LicensePlate AND' : ''}
            ${FineNumber != '' ? 'FineNumber LIKE :FineNumber AND' : ''}
            ${ /*FineNumber IS NOT NULL AND */''}
            ${ /*FineData IS NOT NULL AND */''}
            IF (POSITION('FINE' IN VerificationResult) > 0, true, false) AND
            (VerificationEndTime BETWEEN :FromDate AND :ToDate)
          ORDER BY  
            VerificationEndTime   
          LIMIT :Limit
        `),
            Params: {
                LicensePlate,
                FineNumber,
                FromDate,
                ToDate,
                Limit
            },
            Language
        }).then((response) => {
            if (!response.Rows.length) {
                FilterManager.setActive(true);
                return Alerts.noResults();
            }
            const columns = AConvertToGridColumns(response, {
                'DetectionId': { hidden: true },
                'DetectionDeviceId': { hidden: true },
                'DetectionVersion': { hidden: true },
                'ParkingRightVersion': { hidden: true },
                'VerificationVersion': { hidden: true },
                'VerificationResult': { hidden: true },
                'HasImage': COLUMN_BOOLEAN,
                'VerificationEndTime': COLUMN_DATETIME,
                'Action': {
                    type: 'widget',
                    field: 'Action',
                    widgets: [{
                            type: 'button',
                            cls: 'b-blue b-raised',
                            onAction: ({ source }) => {
                                console.log('source', source);
                                const { DetectionId, DetectionDeviceId, DetectionVersion, ParkingRightVersion, VerificationVersion, HasImage } = AGetGridRecord(source.id);
                                this.print({
                                    DetectionId,
                                    DetectionDeviceId,
                                    DetectionVersion,
                                    ParkingRightVersion,
                                    VerificationVersion
                                });
                            }
                        }]
                }
            });
            const data = AConvertToGridData(response, {
                'VerificationEndTime': DATA_DATETIME,
                // 'HasImage': DATA_COOL,
                'Action': (val) => {
                    return this.translations[val == '1' ? 'print' : 'no image'];
                }
            });
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns,
                data
            });
            FilterManager.setActive(true);
        }));
    }
}
export function css() {
    return ( /*html*/`
    <style>
    .overlay {
      color: white;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      text-align: center;
      padding-top: 200px;
    }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="LicensePlate">License Plate</label>
          <input class="form-input" type="text" id="LicensePlate" maxlength="32" LicensePlate />
        </div>

        <div class="form-group">
          <label class="form-label" for="FineNumber">Fine Number</label>
          <input class="form-input" id="FineNumber" type="number" value="" maxlength="47" />
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Fines</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
