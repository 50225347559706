import { AError } from "../../classes/AError.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { ARouteMapHelperService } from "../../services/ARouteMapHelperService.js";
import { createMap } from "../../utils/maps.js";
import { AInputCleanDateTime, createSelectScanDeviceListHtmlAll, debounce, estimateRouteDurationFromDistance, initAccordions, metersToKilometerText, secondsToDurationTextHHMM } from "../../utils/tools.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AColorHSV } from "../../classes/AColorHSV.js";
import { ADragService } from "../../services/ADragService.js";
import { ALoopTimerAsync } from "../../classes/ALoopTimerAsync.js";
export class APage {
    constructor() {
        this.routes = [];
        this.requests = [];
        this.route_requests = [];
        this.routeNames = [];
        this.requestNames = [];
        this.pageNum = 1;
        this.waySegmentColors = {
            green: new AColorHSV(120, 100, 100),
            blue: new AColorHSV(192, 44, 87),
            red: new AColorHSV(0, 99, 99),
            lime: new AColorHSV(63, 100, 100),
            orange: new AColorHSV(32, 94, 100)
        };
        this.$map = $('#route_map'); // Create JQuery collection with id='map'
        this.map = createMap('route_map', {
            zoom: 24
        });
        this.waySegments = [];
        this.notWaySegments = [];
        this.routeNames = [];
        this.requestNames = [];
        this.requests = [];
        // Move legend to map
        this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push($("#route_map_legend").get(0));
        this.routeMapHelperService = AEngine.get(ARouteMapHelperService);
        this.routeMapHelperService.Clear();
    }
    async init() {
        this.translations = await Loading.waitForPromises(Translate.get([
            'Multiple',
            'Planned',
            'Advanced Settings',
            'All Streets',
            'Area(s)',
            'Back',
            'Bad Quality (Fast)',
            'Balanced Quality',
            'Calculating',
            'Cancel',
            'Car is currenly not connected, route will be send when connected',
            'Cars',
            'Compliancy',
            'CompliancyVisitors',
            'Create New Route',
            'Create route for date',
            'Create',
            'Delete route from database',
            'Delete route',
            'Delete',
            'DEPRECATED',
            'EnforcementIntensity',
            'Error',
            'Estimate duration',
            'Estimate length',
            'Fast',
            'Good Quality (Slow)',
            'Include streets without statistics',
            'MarkAllStreetsAsParking',
            'New request accepted',
            'New request rejected',
            'New Route',
            'Next',
            'Normal',
            'Not connected',
            'Number Of Cars',
            'Split Over Routes',
            'Occupancy',
            'Optional',
            'In Queue',
            'Planning',
            'Recalculate route',
            'Received',
            'Request deleted',
            'Request Rejected',
            'Route Deleted',
            'Route is received',
            'Route name contains invalid characters',
            'Route name too long',
            'Route name',
            'Route Quality',
            'Route will be deleted from',
            'RouteArea(s)',
            'Send Route To Car',
            'Send To Device',
            'Send To Devices',
            'Send To',
            'Send',
            'Service not running',
            'Settings',
            'Slider Settings',
            'Streets With Parking',
            'Thorough',
            'VisitorRate',
            'Warning',
            'Zone(s)',
            'Date (optional)',
            'MaxValue',
            'MaxIteration',
            'InitialCoolingRate',
        ]));
        AEngine.get(ADragService).createDraggableGeneric();
        $('#route_list').on('click', 'tr', (e) => {
            const $t = $(e.target);
            const $tr = $t.is('tr') ? $t : $t.closest('tr');
            $('#route_list tr.selected').removeClass('selected');
            $tr.addClass('selected');
            const route_request = this.route_requests.find(rr => rr.IndexId === parseInt($tr.attr('id') ?? ""));
            if (!route_request)
                return;
            this.selectedRouteIndexId = route_request.IndexId;
            AEngine.log('onRouteRowClicked', this.geoMap != undefined && this.geoMap.WaySegments != undefined);
            if (this.geoMap != undefined && this.geoMap.WaySegments != undefined) {
                this.onRouteRowClicked(route_request);
            }
        });
        $('#create-request').on('click', () => this.displayCreateRouteForm().catch(AError.handle));
        $('#routes_sorting').on("change", () => this.redrawTable());
        this.disableCreateNewButton();
        // Loading.waitForPromises([
        //   this.getStatisticsAndMapToWaySegmentsAllDays(7)        
        // ])
        this.subscribeToRouteServiceMsgs();
        this.refreshLoop();
        this.map.fit();
    }
    async refreshLoop() {
        new ALoopTimerAsync(async () => {
            //AEngine.log('selectedRouteId=', this.selectedRouteName)
            await sleep(500);
        }, { loopLifeCycle: 'PAGE' }).start();
        return new ALoopTimerAsync(() => Promise.all([this.refresh(), sleep(3000)]).catch(AError.handle), { loopLifeCycle: 'PAGE' }).start();
    }
    async refresh() {
        if (!this.routePlannerEnabled) {
            this.routePlannerEnabled = await routePlannerService.pollRouteService();
            if (this.routePlannerEnabled) {
                this.routePlannerEnabled = true;
                this.geoMap = await Loading.waitForPromises(routePlannerService.fetchGeoMap());
                //this.geoMap = await Loading.waitForPromises(routePlannerService.getGeoMap())
                Loading.waitForPromises([
                    this.requests = await routePlannerService.fetchRequests(),
                    this.routes = await routePlannerService.fetchRoutes()
                ]);
                this.redrawTable();
                this.enableCreateNewButton();
            }
        }
        else {
            if (this.selectedRouteIndexId === undefined) {
                $('#info_screen_splash').show();
                $('#info_screen_route').hide();
                $('#info_screen_request').hide();
            }
            this.routes = await routePlannerService.fetchRoutes();
            this.requests = await routePlannerService.fetchRequests();
            this.redrawTable();
        }
    }
    subscribeToRouteServiceMsgs() {
        Events.on(`PlanRoute_DeleteRoute_Response`, response => {
            if (response.state == 'ok') {
                Alerts.show({
                    title: ALERT_TITLES.Success,
                    type: ALERTS.Form,
                    content: this.translations['Route Deleted']
                });
                this.selectedRouteIndexId = undefined;
                this.routeMapHelperService.removeWaySegmentsThick(this.map),
                    this.routeMapHelperService.removeWaySegments(this.map),
                    this.routeMapHelperService.removePolygons(this.map),
                    this.map.fit();
            }
            else if (response.state == 'error') {
                Alerts.show({
                    title: ALERT_TITLES.Warning,
                    content: this.translations['Request Rejected'],
                    type: ALERTS.Form,
                });
            }
        });
        Events.on(`PlanRoute_SendRouteToCar_Response`, response => {
            if (response.State == "NotOnline") {
                Alerts.show({
                    type: ALERTS.Form,
                    translatedTitle: this.translations['Not connected'],
                    content: this.translations['Car is currenly not connected, route will be send when connected']
                });
            }
            else if (response.State == "Ok") {
                Alerts.show({
                    type: ALERTS.Form,
                    translatedTitle: this.translations['Received'],
                    content: this.translations['Route is received']
                });
            }
            else {
                Alerts.show({
                    title: ALERT_TITLES.Error,
                    content: response.StateText,
                    type: ALERTS.Form,
                });
            }
        });
        Events.on(`PlanRoute_CreateRoute_Response`, async (res) => {
            this.enableCreateNewButton();
            if (res.message == 'ok') {
                this.requests = await routePlannerService.fetchRequests();
            }
            else if (res.message == 'error') {
                Alerts.show({
                    translatedTitle: await Translate.get('New Request Rejected'),
                    content: (`<div class="form-group">${res.errors}</div>`).replace(/\s\s+/g, ' '),
                    type: ALERTS.Form,
                    buttons: ALERT_BUTTONS.ok
                });
            }
        });
        // Events.on(`PlanRouteRequestRunning`, response => {
        //   this.enableCreateNewButton();
        //   // if (response.message == 'state changed') {
        //   //   this.getRequests()
        //   // }
        // })
    }
    enableSendButton() {
        $('#ShareButton').addClass('btn-primary');
        $('#ShareButton').prop('disabled', false);
    }
    disableSendButton() {
        $('#ShareButton').removeClass('btn-primary');
        $('#ShareButton').prop('disabled', true);
    }
    enableCreateNewButton() {
        $('#create-request').addClass('btn-primary');
        $('#create-request').prop('disabled', false);
        $('#create-request').text(this.translations['Create New Route']);
    }
    disableCreateNewButton() {
        $('#create-request').removeClass('btn-primary');
        $('#create-request').prop('disabled', true);
        $('#create-request').text(this.translations['Service not running']);
    }
    redrawTable() {
        const sortingOrder = $('#routes_sorting').val();
        // console.log('filterList2', $("#route_list > tr.selected"), $("#route_list > tr.selected").length, $("#route_list > tr").length)
        this.route_requests = [];
        let indexId = 0;
        for (let request of this.requests) {
            if (request.Status == "PlanRoutePending" || request.Status == "PlanRouteRunning") {
                this.route_requests.push({
                    IndexId: indexId++,
                    Request: request,
                    Route: undefined
                });
            }
        }
        for (let route of this.routes) {
            const request = this.requests.find(req => req.RouteId === route.RouteId);
            if (request) { // if route exist -> request should exist
                if (this.route_requests.find(req => req.Request.RouteId === request.RouteId) === undefined) {
                    this.route_requests.push({
                        IndexId: indexId++,
                        Request: request,
                        Route: route
                    });
                }
            }
        }
        this.route_requests.sort((a, b) => {
            switch (sortingOrder) {
                case "co": return (a.Request.Created.getTime() - b.Request.Created.getTime());
                case "cn": return -(a.Request.Created.getTime() - b.Request.Created.getTime());
                case "az": return (a.Request.RouteName.localeCompare(b.Request.RouteName));
                case "za": return -(a.Request.RouteName.localeCompare(b.Request.RouteName));
                default: throw new Error(`Sorting Order not expected: ${sortingOrder}`);
            }
        });
        // $('#route_list').children().remove()
        const tableRowArr = this.route_requests.map((route_request) => {
            const isSelected = (route_request.IndexId === this.selectedRouteIndexId);
            if (route_request.Route === undefined) { // Only Request
                const routeName = route_request.Request.RouteName;
                const statusText = (route_request.Request.Status === "PlanRoutePending") ? this.translations['In Queue'] : ((route_request.Request.Status === "PlanRouteFinished") ? "" : route_request.Request.StatusText);
                return ( /*html*/`
          <tr id="${route_request.IndexId}" class='tableRow ${isSelected ? 'selected' : ''}'>
            <td class='columns' style="margin-left: 1px; margin-right: 1px">
              <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" class="col-9">${routeName}</div>
              <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; text-align: right"class="col-3">${statusText}</div>
            </td>
          </tr>
        `);
            }
            else { // Route
                const routeName = route_request.Route.RouteName;
                let cls = route_request.Route.Active ? 'tableRow' : 'tableRowInActive';
                let prefix = route_request.Route.Active ? '' : this.translations['DEPRECATED'] + " - ";
                return ( /*html*/`
          <tr id="${route_request.IndexId}" class='${cls} ${isSelected ? 'selected' : ''}'>
            <td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${prefix}${routeName}</td>
          </tr>
        `);
            }
        });
        const html = tableRowArr.join('');
        $('#route_list').html(html);
    }
    async onRouteRowClicked(route_request) {
        this.routeMapHelperService.removeWaySegmentsThick(this.map);
        this.routeMapHelperService.removeWaySegments(this.map);
        this.routeMapHelperService.removePolygons(this.map);
        let ps = this;
        if (!route_request.Request) {
            AEngine.warn(`No request: ${route_request}`);
            return;
        }
        if (route_request.Route) { // Route
            $('#info_screen_splash').hide();
            $('#info_screen_route').show();
            $('#info_screen_request').hide();
            let $btn_info = $('#info_route_info');
            let $btn_send = $('#info_route_send');
            let $btn_recalc = $('#info_route_recalc');
            let $btn_delete = $('#info_route_delete');
            $btn_info.off();
            $btn_send.off();
            $btn_recalc.off();
            $btn_delete.off();
            $('#info_route_created').text(AInputCleanDateTime(route_request.Route.Requestcreated_datetime));
            $btn_delete.on("click", () => this.alert_deleteRoute(route_request));
            const verifySettings = (req) => {
                if (!req || !req.Settings)
                    return false;
                for (const i in req.Settings.Areas) {
                    if (!ps.geoMap.Areas[req.Settings.Areas[i]])
                        return false;
                }
                for (const i in req.Settings.Zones) {
                    if (!ps.geoMap.Zones[req.Settings.Zones[i]])
                        return false;
                }
                for (const i in req.Settings.RouteAreas) {
                    if (!ps.geoMap.RouteAreas[req.Settings.RouteAreas[i]])
                        return false;
                }
                return true;
            };
            const hasSettings = verifySettings(route_request.Request);
            $btn_recalc.prop('disabled', true);
            $btn_info.prop('disabled', true);
            if (hasSettings) {
                $btn_recalc.prop('disabled', false);
                $btn_recalc.on("click", () => this.alert_recalcRoute(route_request.Route));
                $btn_info.prop('disabled', false);
                $btn_info.on("click", () => this.alert_showRouteInfo(route_request.Route));
            }
            let $slider = $('#info_route_slider');
            const isDeprecated = (route_request.Route.Active === 0);
            if (isDeprecated) {
                $slider.prop('disabled', true);
                $btn_send.prop('disabled', true);
                $('#info_route_name').text("-");
                $('#info_route_distance').text("-");
                $('#info_route_duration').text("-");
            }
            else {
                $btn_send.prop('disabled', false);
                $('#info_route_name').text(route_request.Route.RouteName);
                $('#info_route_distance').text(metersToKilometerText(route_request.Route.Length));
                $('#info_route_duration').text(secondsToDurationTextHHMM(estimateRouteDurationFromDistance(route_request.Route.Length)));
                $btn_send.on("click", () => this.alert_sendForm(route_request.Route));
                let WaysegmentsParking = [];
                let WaysegmentsOther = [];
                $slider.prop('disabled', false);
                const maxValue = route_request.Route.RouteSegments.length - 1;
                $slider.prop("max", maxValue);
                let $slider_val = -1;
                $slider.off();
                $slider.on("change", async (e) => {
                    if ($slider_val != $slider.prop('value1')) {
                        $slider_val = $slider.prop('value1');
                        if (route_request.Route && route_request.Route.RouteSegments[$slider_val]) {
                            const $s = route_request.Route.RouteSegments[$slider_val];
                            let WaySegmentId = Math.abs($s?.WaySegmentId);
                            if (!ps.geoMap.WaySegments[WaySegmentId])
                                return;
                            await ps.routeMapHelperService.removeWaySegmentsThick(this.map);
                            if ($s.ScanSide === "ScanLeft" || $s.ScanSide === "ScanRight" || $s.ScanSide === "ScanLeftAndRight" || $s.ScanSide === "ScanAny") {
                                ps.routeMapHelperService.drawWaySegmentThick(ps.map, ps.geoMap.WaySegments[WaySegmentId], ps.waySegmentColors.red);
                            }
                            else {
                                ps.routeMapHelperService.drawWaySegmentThick(ps.map, ps.geoMap.WaySegments[WaySegmentId], ps.waySegmentColors.orange);
                            }
                        }
                    }
                });
                $slider.prop('value1', 0);
                if (this.geoMap != undefined && this.geoMap.WaySegments != undefined && route_request.Route.RouteSegments != undefined && route_request.Route.RouteSegments.length) {
                    for (const i in route_request.Route.RouteSegments) {
                        const $s = route_request.Route.RouteSegments[i];
                        let WaySegmentId = Math.abs($s.WaySegmentId);
                        if (WaysegmentsParking.indexOf(WaySegmentId) != -1 || !this.geoMap.WaySegments[WaySegmentId])
                            continue;
                        if ($s.ScanSide === "ScanLeft" || $s.ScanSide === "ScanRight" || $s.ScanSide === "ScanLeftAndRight" || $s.ScanSide === "ScanAny") {
                            if (WaysegmentsParking.indexOf(WaySegmentId) === -1) {
                                const indexOther = WaysegmentsOther.indexOf(WaySegmentId);
                                if (indexOther != -1) {
                                    WaysegmentsOther.splice(indexOther, 1);
                                }
                                WaysegmentsParking.push(WaySegmentId);
                                this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.red);
                            }
                        }
                        else {
                            if (WaysegmentsOther.indexOf(WaySegmentId) === -1) {
                                WaysegmentsOther.push(WaySegmentId);
                                this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.orange);
                            }
                        }
                    }
                    this.routeMapHelperService.fitBoundsWaySegments(this.map);
                }
            }
        }
        else { // thus only request -> request
            $('#info_screen_splash').hide();
            $('#info_screen_route').hide();
            $('#info_screen_request').show();
            $('#info_request_name').text(route_request.Request.RouteName);
            $('#info_request_created').text(AInputCleanDateTime(new Date(route_request.Request.Created)));
            let $btn_info = $('#info_request_info');
            let $btn_delete = $('#info_request_delete');
            $btn_delete.off();
            $btn_delete.on("click", () => this.alert_deleteRoute(route_request));
            $btn_info.off();
            $btn_info.on("click", () => this.alert_showRequestInfo(route_request.Request));
            let WaysegmentsParking = [];
            if (route_request.Request.ParkingStreetIds != undefined && route_request.Request.ParkingStreetIds.length) {
                for (const WaySegmentId of route_request.Request.ParkingStreetIds) {
                    if (WaysegmentsParking.indexOf(WaySegmentId) === -1) {
                        WaysegmentsParking.push(WaySegmentId);
                        this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.red);
                    }
                }
                this.routeMapHelperService.fitBoundsWaySegments(this.map);
            }
        }
    }
    /**
     * Create New Route
     */
    async displayCreateRouteForm(ids = 'new-request') {
        const html = /*html*/ `
    <div id="${ids}" style="margin: 10px;">
      <div class="columns">
        <h5 id="alert_number" class="col-1"></h5>
        <div class="col-11">
          <span id="alert_estimates_distance"></span>
          <span id="alert_estimates_duration"></span>
        </div>
      </div>

      <div id="form_1_2" class="columns" style="min-height: 500px; overflow-y: auto;">
        <div id="form_1" class="column col-6 col-md-12">
          <div class="form-group col-12">
            <label class="form-switch">
              <input id="route_dateswitch" type="checkbox">
              <i class="form-icon"></i>
              <div id="route_dateswitch">${this.translations['Create route for date']}</div>
            </label>
            <label class="form-switch">
              <input id="route_regimeswitch" disabled type="checkbox">
              <i class="form-icon"></i>
              <div id="route_regimeswitch">Filter Regimes</div>
            </label>
          </div>
          <div class="columns col col-12">
            <div class="column col-4">
              <input class="form-input" type="date" disabled id="route_date">
            </div>
            <div class="column col-4">
              <input class="form-input" type="time" disabled id="route_fromtime">
            </div>
            <div class="column col-4">
              <input class="form-input" type="time" disabled id="route_totime">
            </div>
          </div>

          <div style="height: 80%; margin-top: 20px">
            <div class="fh accordion-group" style="position: relative; overflow-y: auto">

              <div class="accordion-wrapper mb-2">
                <a href="#" id="areasAccordionButton" class="d-block h6" style="background-color: #e6e6e6;">
                  <i class="icon icon-arrow-right mr-1"></i>
                  <span class="Grey">${this.translations['Area(s)']}</span>
                </a>
                <div class="tableWrapper hidable" style="display: none">
                  <table id="areasAccordion" class="styled-table grid-like fw">
                    <tbody>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="accordion-wrapper mb-2">
                <a href="#" id="zonesAccordionButton" class="d-block h6" style="background-color: #e6e6e6;">
                  <i class="icon icon-arrow-right mr-1"></i>
                  <span class="Grey">${this.translations['Zone(s)']}</span>
                </a>
                <div class="tableWrapper hidable " style="display: none">
                  <table id="zonesAccordion" class="styled-table grid-like fw">
                    <tbody>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="accordion-wrapper mb-2">
                <a href="#" id="routeAreasAccordionButton" class="d-block h6" style="background-color: #e6e6e6;">
                  <i class="icon icon-arrow-right mr-1"></i>
                  <span class="Grey">${this.translations['RouteArea(s)']}</span>
                </a>
                <div class="tableWrapper hidable " style="display: none">
                  <table id="routeareasAccordion" class="styled-table grid-like fw">
                    <tbody>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="form_2" class="column col-6" style="display: none;">
          <div class="columns">
            <div class="column col-6" style="text-align: center;">
              <button id="btn-parkstraten" class="btn fw" style="min-height: 50px;">${this.translations['Streets With Parking']}</button>
            </div>
            <div class="column col-6" style="text-align: center;">
              <button id="btn-allestraten" class="btn fw" style="min-height: 50px;">${this.translations['All Streets']}</button>
            </div>

            <div class="form-group col-12" style="margin-left: 30px; height: 50px;">
              <label class="form-switch">
                <input id="statistics_switch" checked type="checkbox">
                <i class="form-icon"></i>
                <div id="statistics_label">${this.translations['Include streets without statistics']}</div>
              </label>
            </div>

            <div class="columns col col-12" style="height: 350px; margin-left: 50px; margin-right: 50px;">
              <div class="column col-12 mb-2">
                <label class="form-label" id="label_slider_1"
                  style="text-align: center">${this.translations['Occupancy']}</label>
                  ${this.createSlider("rangeslider_1")}
              </div>
              <div class="column col-12 mb-2">
                <label class="form-label" id="label_slider_2"
                  style="text-align: center">${this.translations['VisitorRate']}</label>
                  ${this.createSlider("rangeslider_2")}
              </div>
              <div class="column col-12 mb-2">
                <label class="form-label" id="label_slider_3"
                  style="text-align: center">${this.translations['Compliancy']}</label>
                ${this.createSlider("rangeslider_3")}
              </div>
              <div class="column col-12 mb-2">
                <label class="form-label" id="label_slider_4"
                  style="text-align: center">${this.translations['CompliancyVisitors']}</label>
                ${this.createSlider("rangeslider_4")}
              </div>
              <div class="column col-12 mb-2">
                <label class="form-label" id="label_slider_5"
                  style="text-align: center">${this.translations['EnforcementIntensity']}</label>
                  ${this.createSlider("rangeslider_5")}
              </div>
            </div>
          </div>
        </div>

        <div class="column col-6 col-md-12">
          <div style="height: 5%; margin-bottom: 10px">
            <button id="btn-showhide-streets" class="btn">Show Streets</button> 
          </div>
          <div id="create_map" class="aci-map" style="height: 93%; width: 100%"></div>
          <div class="legend legend-opaque" id="create_map_legend">
            <div id="create_map_legend_title" style="white-space: pre-line;" class="legend-label label-height-lg"></div>
          </div>
        </div>
      </div>

      <div id="form_3" class="columns" style="width: 100%; height: 100%; display: none;">
        <div class="column col-9 col-mx-auto">
          <div class="columns">
            <div class="column col-12">
              <div class="form-group">
                <div class="col-3">
                  <h6 class="column col-12">${this.translations['Route name']}</h6>  
                  <!-- <label class="form-label" for="routename">${this.translations['Route name']}</label> -->
                </div>
                <div class="col">
                  <input class="form-input" autocomplete="off" type="text" id="routename">
                </div>
              </div>
            </div>
            <div class="column col-12 mb-2" style="margin-top: 20px;">
              <div class="form-group">
                <div class="col-3">
                  <h6 class="column col-12">${this.translations['Split Over Routes']}</h6>
                  <!--<label class="form-label" for="numcars">${this.translations['Number Of Cars']}</label>-->
                </div>
                <div class="col">
                  <input class="form-input" type="number" id="numcars" min=1 max=5 value=1 style="width: 100%">
                </div>
              </div>
            </div>
            <div class="column col-12 mb-2">
              <div class="form-group">

                <label class="form-switch col-3">
                  <input id="send_to_devices_checkbox" type="checkbox">
                  <i class="form-icon"></i> <h6>${this.translations['Send To Devices']}</h6>
                </label>

                <div class="col columns" id="devices_dropdown_extra_1">
                  <div class="col-1" style="margin: auto; text-align: center;">
                    <div>Route 1</div>
                  </div>
                  <div class="col-11">
                    ${createSelectScanDeviceListHtmlAll("devices_dropdown_1")}
                  </div> 
                </div>
                
                <div class="col columns" id="devices_dropdown_extra_2">
                  <div class="col-1" style="margin: auto; text-align: center;">
                    <div>Route 2</div>
                  </div>
                  <div class="col-11">
                    ${createSelectScanDeviceListHtmlAll("devices_dropdown_2")}
                  </div>
                </div>

                <div class="col columns" id="devices_dropdown_extra_3">
                  <div class="col-1" style="margin: auto; text-align: center;">
                    <div>Route 3</div>
                  </div>
                  <div class="col-11">
                    ${createSelectScanDeviceListHtmlAll("devices_dropdown_3")}
                  </div>
                </div>                

                <div class="col columns" id="devices_dropdown_extra_4">
                  <div class="col-1" style="margin: auto; text-align: center;">
                    <div>Route 4</div>
                  </div>
                  <div class="col-11">
                    ${createSelectScanDeviceListHtmlAll("devices_dropdown_4")}
                  </div>
                </div>
                
                <div class="col columns" id="devices_dropdown_extra_5">
                  <div class="col-1" style="margin: auto; text-align: center;">
                    <div>Route 5</div>
                  </div>
                  <div class="col-11">
                    ${createSelectScanDeviceListHtmlAll("devices_dropdown_5")}
                  </div>
                </div>

              </div>          
            </div>            

            <div class="column columns col-12" style="margin-top: 20px;">
              <h6 class="column col-12" style="margin-left: 10px;">${this.translations['Route Quality']}</h6>
              <label style="text-align: left" class="form-label column col" id="sliderlabel1" for="quality_slider">${this.translations['Bad Quality (Fast)']}</label>
              <label style="text-align: center" class="form-label column col-auto" id="sliderlabel2" for="quality_slider">${this.translations['Balanced Quality']}</label>
              <label style="text-align: right" class="form-label column col" id="sliderlabel3" for="quality_slider">${this.translations['Good Quality (Slow)']}</label>
              <input style="height: 15px; margin: 10px;" type="range" min="1" max="5" value="3" class="slider col-12" id="quality_slider">
            </div>
          </div>
        </div>
      </div>

      <div style="text-align: center; margin-top: 50px;">
        <button id="btn-back" class="btn" style="width: 100px;">${this.translations['Back']}</button>
        <button id="btn-next" class="btn" style="width: 100px;">${this.translations['Next']}</button>
      </div>
    </div>
    `.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['New Route'],
            content: html,
            type: ALERTS.Mega,
            buttons: ALERT_BUTTONS.createCancel
        });
        events.on(ALERT_STATUS.ON_MODAL_CLOSED, _ => {
            this.routeMapHelperService.removeWaySegments(this.mapModal);
        });
        initAccordions(events.$ele);
        this.pageNum = 1;
        this.switchForm(this.pageNum);
        $('#btn-next').on("click", (e) => {
            if (this.pageNum == 3) {
                this.createRequest(ids);
                Alerts.closeAllActiveModals();
            }
            else {
                this.switchForm(++this.pageNum);
            }
        });
        $('#btn-back').on("click", () => this.switchForm(--this.pageNum));
        this.initFirstForm();
        this.initThirdForm();
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    createSlider(id) {
        var $sliderhtml = ( /*html*/`
      <tc-range-slider
        id="${id}"
        value1="0"
        value2="100"
        round="0"
        moving-tooltip="true"
        moving-tooltip-distance-to-pointer="30"
        moving-tooltip-width="40"
        moving-tooltip-height="30"
        moving-tooltip-bg="#7DCCDF"
        moving-tooltip-text-color="#efefef"
        moving-tooltip-units="%"
        slider-bg="#CBD5E1"
        slider-bg-hover="#94A3B8"
        slider-bg-fill="#009EC5" 
        slider-width="100%"
        style="--opacity: 0.2"
        ></tc-range-slider>
    `);
        return $sliderhtml;
    }
    createCollapsible() {
        var $collapsiblehtml = /*html*/ `<button type="button" class="collapsible" id="advanced_button">${this.translations['Advanced Settings']}</button>`;
        $collapsiblehtml += ( /*html*/`
      <div class="contentcollapsible">
        <div class="column col-12">
          <div class="columns">
            <div class="column col-12">
              <label class="form-label" for="">${this.translations['Route Quality']}</label>
            </div>
            <!-- <h6 style="text-align: center; margin-top: 30px;" class="column col-12">${this.translations['Route Quality']}</h6> -->
            
            <label style="text-align: left" class="form-label column col" id="sliderlabel1" for="slider">${this.translations['Bad Quality (Fast)']}</label>
            <label style="text-align: center" class="form-label column col-auto" id="sliderlabel2" for="slider">${this.translations['Balanced Quality']}</label>
            <label style="text-align: right" class="form-label column col" id="sliderlabel3" for="slider">${this.translations['Good Quality (Slow)']}</label>
            <input style="height: 15px; margin: 10px;" type="range" min="1" max="3" value="2" class="slider col-12" id="slider">
          </div>
        </div>
        <label class="form-switch">
          <input id="preferright" checked type="checkbox">
          <i class="form-icon"></i> PreferRight
        </label>
        <label class="form-label" for="maxvalue">${this.translations['MaxValue']}</label>
        <input class="form-input" autocomplete="off" type="number" id="maxvalue">
        <label class="form-label" for="maxiteration">${this.translations['MaxIteration']}</label>
        <input class="form-input" autocomplete="off" type="number" id="maxiteration">
        <label class="form-label" for="initialcoolingrate">${this.translations['InitialCoolingRate']}</label>
        <input class="form-input" autocomplete="off" type="number" id="initialcoolingrate">
      </div>
    `);
        return $collapsiblehtml;
    }
    createRouteName() {
        let name = "";
        var areas = $("#areasAccordion > tbody > tr.selected").toArray().map(e => $(e).attr('id'));
        var zones = $("#zonesAccordion > tbody > tr.selected").toArray().map(e => $(e).attr('id'));
        var routeareas = $("#routeareasAccordion > tbody > tr.selected").toArray().map(e => $(e).attr('id'));
        if (areas.length != 0) {
            if (areas.length == 1)
                name = this.geoMap.Areas[areas[0]].Name;
            else
                name = this.translations["Multiple"] + "_Areas";
        }
        else if (zones.length != 0) {
            if (zones.length == 1)
                name = this.geoMap.Zones[zones[0]].Name;
            else
                name = this.translations["Multiple"] + "_Zones";
        }
        else if (routeareas.length != 0) {
            if (routeareas.length == 1)
                name = this.geoMap.RouteAreas[routeareas[0]].Name;
            else
                name = this.translations["Multiple"] + "_RouteAreas";
        }
        if ($('#route_dateswitch').prop("checked") && $("#route_date").val()) {
            name += " - " + new Date($("#route_date").val() + "").toDateString() + " " + $("#route_fromtime").val();
        }
        let loop_count = 0;
        name = name.replace(/ /g, "_");
        while (!this.verifyRouteName(name) && loop_count < 10) {
            loop_count++;
            name = this.incrementRouteNumber(name);
        }
        if (loop_count == 10)
            name = "";
        return name;
    }
    incrementRouteNumber(name) {
        let result = name;
        var rgx = /_\d*$/;
        if (rgx.test(name)) {
            let lastIndex = result.lastIndexOf("_");
            let left = result.substring(0, lastIndex);
            let right = parseInt(result.substring(lastIndex + 1));
            result = left + '_' + ++right;
        }
        else {
            result += "_1";
        }
        return result;
    }
    verifyRouteName(name) {
        if (this._verifyRouteName(name)) {
            return true;
        }
        AEngine.warn(`Couldn't verify name=${name}`);
        return false;
    }
    _verifyRouteName(name) {
        var rg1 = /^[^\\\*\?"<>\s]+$/;
        if (name.length == 0)
            return false;
        else if (!rg1.test(name))
            return false;
        else if (name.length > 75)
            return false;
        if (this.routeNames.indexOf(name) >= 0)
            return false;
        else if (this.requestNames.indexOf(name) >= 0)
            return false;
        return true;
    }
    async switchForm(number) {
        let $form_1 = $('#form_1');
        let $form_2 = $('#form_2');
        let $form_1_2 = $('#form_1_2');
        let $form_3 = $('#form_3');
        let $nextbtn = $('#btn-next');
        let $backbtn = $('#btn-back');
        let $alertNumber = $('#alert_number');
        $alertNumber.text(number + "/3");
        $nextbtn.prop('disabled', false);
        $backbtn.prop('disabled', false);
        if (number != 3) {
            $nextbtn.text(this.translations['Next']);
            $nextbtn.removeClass('btn-primary');
        }
        if (number == 3) {
            await Loading.waitForPromises([
                this.fetchAndCacheRequestNames(),
                this.fetchRouteNames()
            ]);
            const routeName = this.createRouteName();
            const $name_ok = routeName.length;
            if ($name_ok) {
                $('#routename').val(routeName);
                $('#routename').removeClass('is-error');
            }
            else {
                $nextbtn.prop('disabled', true);
                $('#routename').addClass('is-error');
            }
            const $date_checked = $('#route_dateswitch').prop("checked");
            $('#devices_dropdown_extra_1').addClass('hidden');
            $('#devices_dropdown_extra_2').addClass('hidden');
            $('#devices_dropdown_extra_3').addClass('hidden');
            $('#devices_dropdown_extra_4').addClass('hidden');
            $('#devices_dropdown_extra_5').addClass('hidden');
            if ($date_checked) {
                $('#devices_dropdown_extra_1').removeClass('hidden');
                $('#devices_dropdown_1').val("");
                $('#devices_dropdown_1').addClass('is-error');
                $('#send_to_devices_checkbox').prop("checked", true);
                $('#send_to_devices_checkbox').prop('disabled', true);
                $('#numcars').val(1);
                $('#numcars').prop('disabled', true);
                $('#devices_dropdown_label').text(this.translations['Send To Device']);
            }
            else {
                $('#devices_dropdown_label').text(this.translations['Send To Device'] + '  (' + this.translations['Optional'] + ')');
                $('#devices_dropdown').removeClass('is-error');
                $('#send_to_devices_checkbox').prop("checked", false);
                $('#send_to_devices_checkbox').prop('disabled', false);
                $('#numcars').prop('disabled', false);
            }
            if ($date_checked || !$name_ok) {
                $nextbtn.prop('disabled', true);
            }
            else {
                $nextbtn.prop('disabled', false);
            }
        }
        $form_1.hide();
        $form_2.hide();
        $form_1_2.hide();
        $form_3.hide();
        switch (number) {
            case 1:
                $form_1_2.show();
                $form_1.show();
                if ($('#btn-showhide-streets').hasClass('streetsVisible')) {
                    this.routeMapHelperService.setVisiblityWaySegments(this.mapModal, true);
                }
                else {
                    this.routeMapHelperService.setVisiblityWaySegments(this.mapModal, false);
                }
                $('#btn-showhide-streets').prop('disabled', false);
                //this.routeMapHelperService.removeWaySegments(this.mapModal)
                this.routeMapHelperService.setVisiblityPolygons(this.mapModal, true);
                this.routeMapHelperService.setVisiblityPolygonsLowerLayer(this.mapModal, true);
                this.routeMapHelperService.fitBoundsPolygonsLowerLayer(this.mapModal);
                $backbtn.prop('disabled', true);
                $('#alert_estimates_distance').text("");
                $('#alert_estimates_duration').text("");
                break;
            case 2:
                $form_1_2.show();
                $form_2.show();
                this.initSecondForm();
                break;
            case 3:
                $form_3.show();
                $nextbtn.text(this.translations['Create']);
                $nextbtn.addClass('btn-primary');
                break;
        }
    }
    validateRegimeTypeId(type, id) {
        if (!this.regimes)
            return false;
        if (this.regimes.hasOwnProperty(type) && this.regimes[type].hasOwnProperty(id) && this.regimes[type][id]) {
            if (type == "Areas") {
                if (this.geoMap.WaySegments && this.geoMap.Areas) {
                    for (const $waySegmentId of this.geoMap.Areas[id].WaySegments) {
                        if (this.validateRegimeTypeId("WaySegments", $waySegmentId))
                            return true;
                    }
                }
            }
            else if (type == "Zones") {
                if (this.geoMap.WaySegments && this.geoMap.Zones) {
                    for (const $waySegmentId of this.geoMap.Zones[id].WaySegments) {
                        if (this.validateRegimeTypeId("WaySegments", $waySegmentId))
                            return true;
                    }
                }
            }
            else if (type == "RouteAreas") {
                if (this.geoMap.WaySegments && this.geoMap.RouteAreas) {
                    for (const $waySegmentId of this.geoMap.RouteAreas[id].WaySegments) {
                        if (this.validateRegimeTypeId("WaySegments", $waySegmentId))
                            return true;
                    }
                }
            }
            else {
                return true;
            }
        }
        return false;
    }
    async initFirstForm() {
        let $page = this;
        const { routeMapHelperService } = this;
        let $date_switch = $('#route_dateswitch');
        let $regime_switch = $('#route_regimeswitch');
        let $date = $("#route_date");
        let $from = $("#route_fromtime");
        let $to = $("#route_totime");
        const filterAreaZoneRouteAreas = () => {
            routeMapHelperService.removePolygons(this.mapModal);
            function compare(a, b) {
                if (a.Name < b.Name) {
                    return -1;
                }
                if (a.Name > b.Name) {
                    return 1;
                }
                return 0;
            }
            let useRegime = $regime_switch.prop("checked");
            const $tbodyAreas = $('#areasAccordion tbody');
            $tbodyAreas.children().remove();
            for (const areaId of Object.keys(this.geoMap.Areas)) {
                if (!useRegime || $page.validateRegimeTypeId("Areas", parseInt(areaId))) {
                    //if($regimes["Areas"][areaId]){
                    var Name = this.geoMap.Areas[areaId].Name;
                    if (!Name.length)
                        Name = areaId;
                    var $row = $(/*html*/ `
            <tr id="${areaId}" class='tableRow'>
            <td>${Name}</td>
            </tr>
            `.replace(/\s\s+/g, ' '));
                    $tbodyAreas.append($row);
                }
            }
            const $tbodyZones = $('#zonesAccordion tbody');
            $tbodyZones.children().remove();
            for (const zoneId of Object.keys(this.geoMap.Zones)) {
                if (!useRegime || $page.validateRegimeTypeId("Zones", parseInt(zoneId))) {
                    var Name = this.geoMap.Zones[zoneId].Name;
                    if (!Name.length)
                        Name = zoneId;
                    var $row = $(/*html*/ `
            <tr id="${zoneId}" class='tableRow'>
            <td>${Name}</td>
            </tr>
            `.replace(/\s\s+/g, ' '));
                    $tbodyZones.append($row);
                }
            }
            const $tbodyRouteAreas = $('#routeareasAccordion tbody');
            $tbodyRouteAreas.children().remove();
            for (const routeareaId of Object.keys(this.geoMap.RouteAreas)) {
                if (!useRegime || $page.validateRegimeTypeId("RouteAreas", parseInt(routeareaId))) {
                    var Name = this.geoMap.RouteAreas[routeareaId].Name;
                    $tbodyRouteAreas.append(/*html*/ `<tr id="${routeareaId}" class="tableRow"><td>${Name || routeareaId}</td></tr>`);
                }
            }
        };
        const drawLowerLayer = () => {
            routeMapHelperService.removePolygonsLowerlayer(this.mapModal);
            routeMapHelperService.removeWaySegments(this.mapModal);
            if ($('#areasAccordionButton').hasClass('selected'))
                drawAllAreas();
            if ($('#zonesAccordionButton').hasClass('selected'))
                drawAllZones();
            if ($('#routeAreasAccordionButton').hasClass('selected'))
                drawAllRouteAreas();
            routeMapHelperService.fitBoundsPolygonsLowerLayer(this.mapModal);
        };
        const drawAllAreas = () => {
            let useRegime = $regime_switch.prop("checked");
            for (const areaId of Object.keys(this.geoMap.Areas)) {
                if (!useRegime || $page.validateRegimeTypeId("Areas", parseInt(areaId))) {
                    routeMapHelperService.drawPolygonOnMap(this.mapModal, this.geoMap.Areas[areaId], "area", areaId, new AColorHSV(60, 33, 100), new AColorHSV(0, 0, 35), 0.0, onLowerLayerClicked, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    for (const $waySegmentId of this.geoMap.Areas[areaId].WaySegments) {
                        const $waySegment = this.geoMap.WaySegments[$waySegmentId];
                        if ($waySegment.ParkingCountLeft || $waySegment.ParkingCountRight) {
                            $page.routeMapHelperService.drawWaySegment(this.mapModal, $waySegment, this.waySegmentColors.red, 3);
                        }
                        else {
                            $page.routeMapHelperService.drawWaySegment(this.mapModal, $waySegment, this.waySegmentColors.orange, 1.5);
                        }
                    }
                    $page.routeMapHelperService.setVisiblityWaySegments($page.mapModal, false);
                }
            }
        };
        const drawAllZones = () => {
            let useRegime = $regime_switch.prop("checked");
            for (const zoneId of Object.keys(this.geoMap.Zones)) {
                if (!useRegime || $page.validateRegimeTypeId("Zones", parseInt(zoneId))) {
                    routeMapHelperService.drawPolygonOnMap(this.mapModal, this.geoMap.Zones[zoneId], "zone", zoneId, new AColorHSV(60, 33, 100), new AColorHSV(0, 0, 35), 0.0, onLowerLayerClicked, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    for (const $waySegmentId of this.geoMap.Zones[zoneId].WaySegments) {
                        const $waySegment = this.geoMap.WaySegments[$waySegmentId];
                        if ($waySegment.ParkingCountLeft || $waySegment.ParkingCountRight) {
                            $page.routeMapHelperService.drawWaySegment(this.mapModal, $waySegment, this.waySegmentColors.red, 3);
                        }
                        else {
                            $page.routeMapHelperService.drawWaySegment(this.mapModal, $waySegment, this.waySegmentColors.orange, 1.5);
                        }
                    }
                    $page.routeMapHelperService.setVisiblityWaySegments($page.mapModal, false);
                }
            }
        };
        const drawAllRouteAreas = () => {
            let useRegime = $regime_switch.prop("checked");
            for (const routeareaId of Object.keys(this.geoMap.RouteAreas)) {
                if (!useRegime || $page.validateRegimeTypeId("RouteAreas", parseInt(routeareaId))) {
                    routeMapHelperService.drawPolygonOnMap(this.mapModal, this.geoMap.RouteAreas[routeareaId], "routearea", routeareaId, new AColorHSV(60, 33, 100), new AColorHSV(0, 0, 35), 0.0, onLowerLayerClicked, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    for (const $waySegmentId of this.geoMap.RouteAreas[routeareaId].WaySegments) {
                        if (!useRegime || $page.validateRegimeTypeId("WaySegments", $waySegmentId)) {
                            const $waySegment = this.geoMap.WaySegments[$waySegmentId];
                            if ($waySegment.ParkingCountLeft || $waySegment.ParkingCountRight) {
                                $page.routeMapHelperService.drawWaySegment(this.mapModal, $waySegment, this.waySegmentColors.red, 3);
                            }
                            else {
                                $page.routeMapHelperService.drawWaySegment(this.mapModal, $waySegment, this.waySegmentColors.orange, 1.5);
                            }
                        }
                    }
                    $page.routeMapHelperService.setVisiblityWaySegments($page.mapModal, false);
                }
            }
        };
        const onLowerLayerClicked = (type, id) => {
            if (this.pageNum != 1)
                return;
            if (type == "area") {
                $(`#areasAccordion > tbody > tr[id="${id}"]`).trigger('click');
                // $("#areasAccordion > tbody > tr").toArray().map(e => $(e)).map(($tr) => {
                //   if ($tr.attr('id')! === id) $tr.trigger('click')
                // })
            }
            else if (type == "zone") {
                $(`#zonesAccordion > tbody > tr[id="${id}"]`).trigger('click');
                // $("#zonesAccordion > tbody > tr").toArray().map(e => $(e)).map(($tr) => {
                //   if ($tr.attr('id')! === id) $tr.trigger('click')
                // })
            }
            else if (type == "routearea") {
                $(`#routeareasAccordion > tbody > tr[id="${id}"]`).trigger('click');
                // $("#routeareasAccordion > tbody > tr").toArray().map(e => $(e)).map(($tr) => {
                //   if ($tr.attr('id')! === id) $tr.trigger('click')
                // })
            }
        };
        const onLowerLayerMouseOver = (type, id) => {
            let str = "";
            const showRegime = $("#route_regimeswitch").prop('checked');
            if (type == "area" && this.geoMap.Areas[id]) {
                str = this.geoMap.Areas[id].Name;
                if (showRegime) {
                    let regime = "-";
                    if ($page.regimes["Regimes"] &&
                        $page.regimes["Areas"] &&
                        $page.regimes["Areas"][id] &&
                        $page.regimes["Regimes"][$page.regimes["Areas"][id]]) {
                        regime = $page.regimes["Regimes"][$page.regimes["Areas"][id]];
                    }
                    str += "\n\r" + regime;
                }
            }
            else if (type == "zone" && this.geoMap.Zones[id]) {
                str = this.geoMap.Zones[id].Name;
                if (showRegime) {
                    let regime = "-";
                    if ($page.regimes["Regimes"] &&
                        $page.regimes["Zones"] &&
                        $page.regimes["Zones"][id] &&
                        $page.regimes["Regimes"][$page.regimes["Zones"][id]]) {
                        regime = $page.regimes["Regimes"][$page.regimes["Zones"][id]];
                    }
                    str += "\n\r" + regime;
                }
            }
            else if (type == "routearea" && this.geoMap.RouteAreas[id]) {
                str = this.geoMap.RouteAreas[id].Name;
                if (showRegime) {
                    let regime = "-";
                    if ($page.regimes["Regimes"] &&
                        $page.regimes["RouteAreas"] &&
                        $page.regimes["RouteAreas"][id] &&
                        $page.regimes["Regimes"][$page.regimes["RouteAreas"][id]]) {
                        regime = $page.regimes["Regimes"][$page.regimes["RouteAreas"][id]];
                    }
                    str += "\n\r" + regime;
                }
            }
            $('#create_map_legend_title').text(str);
            legend.style.display = "";
        };
        const onLowerLayerMouseOut = () => {
            legend.style.display = "none";
        };
        const verifyInput = () => {
            let $nextbtn = $('#btn-next');
            $nextbtn.prop('disabled', true);
            let dateValid = true;
            let areazoneValid = false;
            $date.removeClass('is-error');
            $from.removeClass('is-error');
            $to.removeClass('is-error');
            if ($date_switch.prop("checked")) {
                if (!$date.val() || ($date.val() && new Date($date.val() + " " + $from.val()) < new Date()) || $from.val() > $to.val()) {
                    dateValid = false;
                    $date.addClass('is-error');
                    $from.addClass('is-error');
                    $to.addClass('is-error');
                }
            }
            if (($("#areasAccordion > tbody > tr.selected").length) ||
                ($("#zonesAccordion > tbody > tr.selected").length) ||
                ($("#routeareasAccordion > tbody > tr.selected").length)) {
                areazoneValid = true;
            }
            if (dateValid && areazoneValid) {
                $nextbtn.prop('disabled', false);
            }
            else {
                $nextbtn.prop('disabled', true);
            }
        };
        const setOnClickAccordions = () => {
            let $btn_areas = $('#areasAccordionButton');
            $btn_areas.on('click', (e) => {
                // const $t = $(e.target)
                // const $acc = $t.is('.accordion_initialized') ? $t : $t.closest('.accordion_initialized')
                // if ($btn_areas.hasClass('selected')) $btn_areas.removeClass('selected')
                // else $btn_areas.addClass('selected')
                $btn_areas.toggleClass('selected');
                $btn_zones.removeClass('selected');
                $btn_routeareas.removeClass('selected');
                $("#zonesAccordion > tbody > tr.selected").each(function () { $(this).removeClass('selected'); });
                $("#routeareasAccordion > tbody > tr.selected").each(function () { $(this).removeClass('selected'); });
                routeMapHelperService.removePolygons(this.mapModal);
                if ($btn_areas.hasClass('selected')) {
                    $btn_areas.find("tr.selected").toArray().map(e => $(e)).map(($tr) => {
                        if ($tr.hasClass('selected')) {
                            const geoId = $tr.attr('id');
                            const color = new AColorHSV(216, 100, 100);
                            routeMapHelperService.drawPolygonOnMapBorderOnly(this.mapModal, this.geoMap.Areas[geoId], "area", geoId, color, 1.0, () => { $tr.trigger('click'); }, onLowerLayerMouseOver, onLowerLayerMouseOut);
                        }
                    });
                }
                else {
                    $("#areasAccordion > tbody > tr.selected").each(function () { $(this).removeClass('selected'); });
                }
                if ($btn_areas.hasClass('selected') || $btn_zones.hasClass('selected') || $btn_routeareas.hasClass('selected')) {
                    $showhidestreetsbtn.prop('disabled', false);
                    $showhidestreetsbtn.removeClass("streetsVisible");
                }
                else {
                    $showhidestreetsbtn.prop('disabled', true);
                    $showhidestreetsbtn.text("Show Streets");
                }
                verifyInput();
                drawLowerLayer();
            });
            let $btn_zones = $('#zonesAccordionButton');
            $btn_zones.on('click', () => {
                // if ($btn_zones.hasClass('selected')) $btn_zones.removeClass('selected')
                // else $btn_zones.addClass('selected')
                $btn_zones.toggleClass('selected');
                $btn_areas.removeClass('selected');
                $btn_routeareas.removeClass('selected');
                $("#areasAccordion > tbody > tr.selected").each(function () { $(this).removeClass('selected'); });
                $("#routeareasAccordion > tbody > tr.selected").each(function () { $(this).removeClass('selected'); });
                routeMapHelperService.removePolygons(this.mapModal);
                if ($btn_zones.hasClass('selected')) {
                    $btn_routeareas.find("tr.selected").toArray().map(e => $(e)).map(($tr) => {
                        if ($tr.hasClass('selected')) {
                            const geoId = $tr.attr('id');
                            const color = new AColorHSV(216, 100, 100);
                            routeMapHelperService.drawPolygonOnMapBorderOnly(this.mapModal, this.geoMap.Zones[geoId], "zone", geoId, color, 1.0, () => { $tr.trigger('click'); }, onLowerLayerMouseOver, onLowerLayerMouseOut);
                        }
                    });
                }
                else {
                    $("#zonesAccordion > tbody > tr.selected").each(function () { $(this).removeClass('selected'); });
                }
                if ($btn_areas.hasClass('selected') || $btn_zones.hasClass('selected') || $btn_routeareas.hasClass('selected')) {
                    $showhidestreetsbtn.prop('disabled', false);
                    $showhidestreetsbtn.removeClass("streetsVisible");
                }
                else {
                    $showhidestreetsbtn.prop('disabled', true);
                    $showhidestreetsbtn.text("Show Streets");
                }
                verifyInput();
                drawLowerLayer();
            });
            let $btn_routeareas = $('#routeAreasAccordionButton');
            $btn_routeareas.on('click', (e) => {
                // if ($btn_routeareas.hasClass('selected')) $btn_routeareas.removeClass('selected')
                // else $btn_routeareas.addClass('selected')
                $btn_routeareas.toggleClass('selected');
                $btn_areas.removeClass('selected');
                $btn_zones.removeClass('selected');
                $("#areasAccordion > tbody > tr.selected").each(function () { $(this).removeClass('selected'); });
                $("#zonesAccordion > tbody > tr.selected").each(function () { $(this).removeClass('selected'); });
                routeMapHelperService.removePolygons(this.mapModal);
                if ($btn_routeareas.hasClass('selected')) {
                    $btn_routeareas.find("tr").toArray().map(e => $(e)).map(($tr) => {
                        if ($tr.hasClass('selected')) {
                            const geoId = $tr.attr('id');
                            const color = new AColorHSV(216, 100, 100);
                            routeMapHelperService.drawPolygonOnMapBorderOnly(this.mapModal, this.geoMap.RouteAreas[geoId], "routearea", geoId, color, 1.0, () => { $tr.trigger('click'); }, onLowerLayerMouseOver, onLowerLayerMouseOut);
                        }
                    });
                }
                else {
                    $("#routeareasAccordion > tbody > tr.selected").each(function () { $(this).removeClass('selected'); });
                }
                if ($btn_areas.hasClass('selected') || $btn_zones.hasClass('selected') || $btn_routeareas.hasClass('selected')) {
                    $showhidestreetsbtn.prop('disabled', false);
                    $showhidestreetsbtn.removeClass("streetsVisible");
                }
                else {
                    $showhidestreetsbtn.prop('disabled', true);
                    $showhidestreetsbtn.text("Show Streets");
                }
                verifyInput();
                drawLowerLayer();
            });
            const $tbodyAreas = $('#areasAccordion tbody');
            $tbodyAreas.on('click', 'tr', (e) => {
                const $t = $(e.target);
                const $opt = $t.is('tr') ? $t : $t.closest('tr');
                // this.selectedRouteName = $opt.attr('id') // TODO
                $opt.toggleClass('selected'); // TODO
                routeMapHelperService.removePolygons(this.mapModal);
                $tbodyAreas.find("tr.selected").toArray().map(e => $(e)).map(($tr) => {
                    if ($tr.hasClass('selected')) {
                        const geoId = $tr.attr('id');
                        const color = new AColorHSV(216, 100, 100);
                        routeMapHelperService.drawPolygonOnMapBorderOnly(this.mapModal, this.geoMap.Areas[geoId], "area", geoId, color, 1.0, () => { $tr.trigger('click'); }, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    }
                });
                verifyInput();
            });
            const $tbodyZones = $('#zonesAccordion tbody');
            $tbodyZones.on('click', 'tr', (e) => {
                const $t = $(e.target);
                const $opt = $t.is('tr') ? $t : $t.closest('tr');
                $opt.toggleClass('selected');
                routeMapHelperService.removePolygons(this.mapModal);
                $tbodyZones.find("tr.selected").toArray().map(e => $(e)).map(($tr) => {
                    if ($tr.hasClass('selected')) {
                        const geoId = $tr.attr('id');
                        const color = new AColorHSV(216, 100, 100);
                        routeMapHelperService.drawPolygonOnMapBorderOnly(this.mapModal, this.geoMap.Zones[geoId], "zone", geoId, color, 1.0, () => { $tr.trigger('click'); }, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    }
                });
                verifyInput();
            });
            const $tbodyRouteAreas = $('#routeareasAccordion tbody');
            $tbodyRouteAreas.on('click', 'tr', (e) => {
                const $t = $(e.target);
                const $opt = $t.is('tr') ? $t : $t.closest('tr');
                // this.selectedRouteName = $opt.attr('id')
                $opt.toggleClass('selected');
                routeMapHelperService.removePolygons(this.mapModal);
                $tbodyRouteAreas.find("tr.selected").toArray().map(e => $(e)).map(($tr) => {
                    if ($tr.hasClass('selected')) {
                        const geoId = $tr.attr('id');
                        const color = new AColorHSV(216, 100, 100);
                        routeMapHelperService.drawPolygonOnMapBorderOnly(this.mapModal, this.geoMap.RouteAreas[geoId], "routearea", geoId, color, 1.0, () => { $tr.trigger('click'); }, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    }
                });
                verifyInput();
            });
        };
        $date_switch.on('change', () => {
            if ($date_switch.prop("checked")) {
                $regime_switch.prop('disabled', false);
                $regime_switch.prop('checked', true);
                $date.prop('disabled', false);
                $date.trigger('change');
                verifyInput();
            }
            else {
                $regime_switch.prop('disabled', true);
                $regime_switch.prop('checked', false);
                $date.prop('disabled', true);
                $from.prop('disabled', true);
                $to.prop('disabled', true);
                filterAreaZoneRouteAreas();
                drawLowerLayer();
            }
        });
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $date.prop('min', minDate);
        $from.val("06:00");
        $to.val("23:00");
        $date.on("change", async (e) => {
            if (!$date.val())
                return;
            $from.prop('disabled', false);
            $to.prop('disabled', false);
            if ($regime_switch.prop('checked')) {
                var $fromdate = new Date($date.val() + " " + $from.val());
                var $todate = new Date($date.val() + " " + $to.val());
                this.regimes = await Loading.waitForPromises(routePlannerService.fetchAndCacheRegime($fromdate, $todate));
            }
            filterAreaZoneRouteAreas();
            drawLowerLayer();
            verifyInput();
        });
        $from.on("change", async (e) => {
            $to.prop('min', $from.val());
            if ($regime_switch.prop('checked')) {
                var $fromdate = new Date($date.val() + " " + $from.val());
                var $todate = new Date($date.val() + " " + $to.val());
                this.regimes = await Loading.waitForPromises(routePlannerService.fetchAndCacheRegime($fromdate, $todate));
            }
            filterAreaZoneRouteAreas();
            drawLowerLayer();
            verifyInput();
        });
        $to.on("change", async (e) => {
            if ($regime_switch.prop('checked')) {
                var $fromdate = new Date($date.val() + " " + $from.val());
                var $todate = new Date($date.val() + " " + $to.val());
                this.regimes = await Loading.waitForPromises(routePlannerService.fetchAndCacheRegime($fromdate, $todate));
            }
            filterAreaZoneRouteAreas();
            drawLowerLayer();
            verifyInput();
        });
        $regime_switch.on("change", async (e) => {
            if ($regime_switch.prop('checked')) {
                var $fromdate = new Date($date.val() + " " + $from.val());
                var $todate = new Date($date.val() + " " + $to.val());
                this.regimes = await Loading.waitForPromises(routePlannerService.fetchAndCacheRegime($fromdate, $todate));
            }
            filterAreaZoneRouteAreas();
            drawLowerLayer();
            verifyInput();
        });
        filterAreaZoneRouteAreas();
        setOnClickAccordions();
        verifyInput();
        this.mapModal = createMap('create_map', { zoom: 100 });
        this.mapModal.fit();
        this.$mapModal = $(this.mapModal.getDiv());
        let $showhidestreetsbtn = $('#btn-showhide-streets');
        $showhidestreetsbtn.prop('disabled', true);
        $showhidestreetsbtn.on("click", async function () {
            if ($showhidestreetsbtn.hasClass("streetsVisible")) {
                $showhidestreetsbtn.text("Show Streets");
                $page.routeMapHelperService.setFillOpacityPolygonsLowerLayer($page.mapModal, 0.5);
                $page.routeMapHelperService.setVisiblityWaySegments($page.mapModal, false);
                $showhidestreetsbtn.removeClass("streetsVisible");
            }
            else {
                $showhidestreetsbtn.text("Hide Streets");
                $page.routeMapHelperService.setFillOpacityPolygonsLowerLayer($page.mapModal, 0.2);
                $page.routeMapHelperService.setVisiblityWaySegments($page.mapModal, true);
                $showhidestreetsbtn.addClass("streetsVisible");
            }
        });
        const legend = document.getElementById("create_map_legend");
        this.mapModal.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
        legend.style.display = "none";
    }
    async initSecondForm() {
        const { routeMapHelperService } = this;
        let $btnallestaten = $('#btn-allestraten');
        let $btnparkstaten = $('#btn-parkstraten');
        let $statistics_switch = $('#statistics_switch');
        let $statistics_label = $('#statistics_label');
        let page = this;
        let $rangeslider1, $rangeslider2, $rangeslider3, $rangeslider4, $rangeslider5;
        let $label_slider1 = $('#label_slider_1');
        let $label_slider2 = $('#label_slider_2');
        let $label_slider3 = $('#label_slider_3');
        let $label_slider4 = $('#label_slider_4');
        let $label_slider5 = $('#label_slider_5');
        //let minOccupancy  = 0, maxOccupancy = 0
        //let minCompliancy = 0, maxCompliancy = 0
        let allWaySegments;
        let geoMap = this.geoMap;
        let $create_map = this.mapModal;
        let allstreets, without_statistics;
        function initSliders() {
            $rangeslider1 = $('#rangeslider_1');
            $rangeslider2 = $('#rangeslider_2');
            $rangeslider3 = $('#rangeslider_3');
            $rangeslider4 = $('#rangeslider_4');
            $rangeslider5 = $('#rangeslider_5');
            // Prevent spamming of change event
            const triggerFilterWaysegments = debounce(() => {
                // Change Event Called
                filterWaySegments();
            }, 300);
            $rangeslider1.on('change', (e) => triggerFilterWaysegments());
            $rangeslider2.on('change', (e) => triggerFilterWaysegments());
            $rangeslider3.on('change', (e) => triggerFilterWaysegments());
            $rangeslider4.on('change', (e) => triggerFilterWaysegments());
            $rangeslider5.on('change', (e) => triggerFilterWaysegments());
        }
        function disableEnableSliders() {
            let txt_color_enabled = "black";
            let txt_color_disabled = "lightgray";
            if (allstreets) {
                $btnallestaten.css("background-color", "lightblue");
                $btnparkstaten.css("background-color", "");
            }
            else {
                $btnallestaten.css("background-color", "");
                $btnparkstaten.css("background-color", "lightblue");
            }
            $statistics_switch.prop('disabled', true);
            $statistics_label.css('color', txt_color_disabled);
            $rangeslider1.prop('disabled', true);
            $label_slider1.css('color', txt_color_disabled);
            $rangeslider2.prop('disabled', true);
            $label_slider2.css('color', txt_color_disabled);
            $rangeslider3.prop('disabled', true);
            $label_slider3.css('color', txt_color_disabled);
            $rangeslider4.prop('disabled', true);
            $label_slider4.css('color', txt_color_disabled);
            $rangeslider5.prop('disabled', true);
            $label_slider5.css('color', txt_color_disabled);
            if (!allstreets) {
                $statistics_switch.prop('disabled', false);
                $statistics_label.css('color', txt_color_enabled);
                for (const id of allWaySegments) {
                    const waySegment = geoMap.WaySegments[id];
                    if (waySegment.Occupancy) {
                        $rangeslider1.prop('disabled', false);
                        $label_slider1.css('color', txt_color_enabled);
                        break;
                    }
                }
                for (const id of allWaySegments) {
                    const waySegment = geoMap.WaySegments[id];
                    if (waySegment.VisitorRate) {
                        $rangeslider2.prop('disabled', false);
                        $label_slider2.css('color', txt_color_enabled);
                        break;
                    }
                }
                for (const id of allWaySegments) {
                    const waySegment = geoMap.WaySegments[id];
                    if (waySegment.Compliancy) {
                        $rangeslider3.prop('disabled', false);
                        $label_slider3.css('color', txt_color_enabled);
                        break;
                    }
                }
                for (const id of allWaySegments) {
                    const waySegment = geoMap.WaySegments[id];
                    if (waySegment.CompliancyVisitors) {
                        $rangeslider4.prop('disabled', false);
                        $label_slider4.css('color', txt_color_enabled);
                        break;
                    }
                }
                for (const id of allWaySegments) {
                    const waySegment = geoMap.WaySegments[id];
                    if (waySegment.EnforcementIntensity) {
                        $rangeslider5.prop('disabled', false);
                        $label_slider5.css('color', txt_color_enabled);
                        break;
                    }
                }
                //$rangeslider5.prop('disabled', false)
                //$label_slider5.css('color', txt_color_enabled) 
            }
        }
        function selectAllWaySegmentsAndFilterStats() {
            // let waySegmentsIds: any = []
            // $("#areasAccordion > tbody > tr.selected").each(function () {
            //   waySegmentsIds = waySegmentsIds.concat(geoMap.Areas[this.id].WaySegments)
            // });
            // $("#zonesAccordion > tbody > tr.selected").each(function () {
            //   waySegmentsIds = waySegmentsIds.concat(geoMap.Zones[this.id].WaySegments)
            // });
            // $("#routeareasAccordion > tbody > tr.selected").each(function () {
            //   waySegmentsIds = waySegmentsIds.concat(geoMap.RouteAreas[this.id].WaySegments)
            // });
            // if (waySegmentsIds != null) {
            //   for (let i = 0; i < waySegmentsIds.length; i++) {
            //     if (geoMap.WaySegments[waySegmentsIds[i]]) {
            //       allWaySegments[waySegmentsIds[i]] = geoMap.WaySegments[waySegmentsIds[i]]
            //     }
            //   }
            // }
            allWaySegments = [];
            $("#areasAccordion > tbody > tr.selected").each(function () {
                allWaySegments = allWaySegments.concat(geoMap.Areas[this.id].WaySegments);
            });
            $("#zonesAccordion > tbody > tr.selected").each(function () {
                allWaySegments = allWaySegments.concat(geoMap.Zones[this.id].WaySegments);
            });
            $("#routeareasAccordion > tbody > tr.selected").each(function () {
                allWaySegments = allWaySegments.concat(geoMap.RouteAreas[this.id].WaySegments);
            });
            page.waySegments = allWaySegments;
        }
        function filterWaySegments() {
            page.waySegments = [];
            page.notWaySegments = [];
            if (allWaySegments == null)
                return;
            if (allstreets) {
                page.waySegments = allWaySegments;
            }
            else {
                let minOccupancy_set = $rangeslider1.prop("value1") / 100;
                let maxOccupancy_set = $rangeslider1.prop("value2") / 100;
                let minVisitorRate_set = $rangeslider2.prop("value1") / 100;
                let maxVisitorRate_set = $rangeslider2.prop("value2") / 100;
                let minCompliancy_set = $rangeslider3.prop("value1") / 100;
                let maxCompliancy_set = $rangeslider3.prop("value2") / 100;
                let minCompliancyVisitors_set = $rangeslider4.prop("value1") / 100;
                let maxCompliancyVisitors_set = $rangeslider4.prop("value2") / 100;
                let minEnforcementIntensity_set = $rangeslider5.prop("value1") / 100;
                let maxEnforcementIntensity_set = $rangeslider5.prop("value2") / 100;
                for (const id of allWaySegments) {
                    const waySegment = geoMap.WaySegments[id];
                    if (waySegment.ParkingCountLeft > 0 || waySegment.ParkingCountRight > 0) {
                        if (($useRegime ? page.validateRegimeTypeId("WaySegments", id) : true) &&
                            ((waySegment.Occupancy >= minOccupancy_set && waySegment.Occupancy <= maxOccupancy_set) || (!waySegment.Occupancy && without_statistics)) &&
                            ((waySegment.VisitorRate >= minVisitorRate_set && waySegment.VisitorRate <= maxVisitorRate_set) || (!waySegment.VisitorRate && without_statistics)) &&
                            ((waySegment.Compliancy >= minCompliancy_set && waySegment.Compliancy <= maxCompliancy_set) || (!waySegment.Compliancy && without_statistics)) &&
                            ((waySegment.CompliancyVisitors >= minCompliancyVisitors_set && waySegment.CompliancyVisitors <= maxCompliancyVisitors_set) || (!waySegment.CompliancyVisitors && without_statistics)) &&
                            ((waySegment.EnforcementIntensity >= minEnforcementIntensity_set && waySegment.EnforcementIntensity <= maxEnforcementIntensity_set) || (!waySegment.EnforcementIntensity && without_statistics))) {
                            page.waySegments.push(id);
                        }
                    }
                    else {
                        page.notWaySegments.push(id);
                    }
                }
            }
            drawSelectedWaySegments();
        }
        function drawSelectedWaySegments() {
            routeMapHelperService.setVisiblityWaySegments($create_map, false);
            for (const id of page.waySegments) {
                page.routeMapHelperService.setVisiblityWaySegment($create_map, id, true);
            }
            setRouteStats();
            setNextButton();
        }
        function setNextButton() {
            let $nextbtn = $('#btn-next');
            if (page.waySegments.length <= 2)
                $nextbtn.prop('disabled', true);
            else
                $nextbtn.prop('disabled', false);
        }
        function setRouteStats() {
            let dist_park = 0;
            for (const id of page.waySegments) {
                dist_park += page.geoMap.WaySegments[id].Distance;
            }
            const $routeFrom = new Date($("#route_date").val() + " " + $("#route_fromtime").val());
            const $routeTo = new Date($("#route_date").val() + " " + $("#route_totime").val());
            let dist_route = dist_park * 1.9;
            let dur_route_sec = estimateRouteDurationFromDistance(dist_route);
            let $estimates_distance = $('#alert_estimates_distance');
            let $estimates_duration = $('#alert_estimates_duration');
            $estimates_distance.text(page.translations['Estimate length'] + ": " + metersToKilometerText(dist_route));
            $estimates_duration.text(" " + page.translations['Estimate duration'] + ": " + secondsToDurationTextHHMM(dur_route_sec));
            $estimates_duration.css('color', '');
            if ($('#route_dateswitch').prop('checked')) {
                const secondsPlanned = Math.abs($routeFrom.getTime() - $routeTo.getTime()) / 1000;
                $estimates_duration.text($estimates_duration.text() + " (" + page.translations["Planned"] + " " + secondsToDurationTextHHMM(secondsPlanned) + ")");
                if (secondsPlanned < dur_route_sec)
                    $estimates_duration.css('color', 'red');
            }
        }
        function hasParkingStreets() {
            for (const id of page.waySegments) {
                if (geoMap.WaySegments[id].ParkingCountLeft > 0 || geoMap.WaySegments[id].ParkingCountRight > 0) {
                    return true;
                }
            }
            return false;
        }
        allstreets = true;
        without_statistics = true;
        $('#btn-next').prop('disabled', true);
        $('#btn-back').prop('disabled', true);
        $('#btn-showhide-streets').prop('disabled', true);
        $btnallestaten.css("background-color", "lightblue");
        $btnparkstaten.css("background-color", "");
        selectAllWaySegmentsAndFilterStats();
        drawSelectedWaySegments();
        this.routeMapHelperService.setVisiblityPolygons(this.mapModal, false);
        this.routeMapHelperService.setVisiblityPolygonsLowerLayer(this.mapModal, false);
        this.routeMapHelperService.fitBoundsWaySegments(this.mapModal);
        initSliders();
        const $hasParking = hasParkingStreets();
        const $useRegime = $('#route_regimeswitch').prop("checked");
        !$hasParking ? $btnparkstaten.prop('disabled', true) : $btnparkstaten.prop('disabled', false);
        $useRegime ? $btnallestaten.prop('disabled', true) : $btnallestaten.prop('disabled', false);
        $statistics_switch.off();
        $statistics_switch.on("change", () => {
            without_statistics = $statistics_switch.prop("checked");
            filterWaySegments();
        });
        $btnallestaten.off();
        $btnallestaten.on("click", () => {
            $btnallestaten.addClass('selected');
            allstreets = true;
            disableEnableSliders();
            filterWaySegments();
        });
        $btnparkstaten.off();
        $btnparkstaten.on("click", () => {
            $btnallestaten.removeClass('selected');
            allstreets = false;
            disableEnableSliders();
            filterWaySegments();
        });
        $('#btn-back').prop('disabled', false);
        if ($hasParking)
            $btnparkstaten.trigger('click');
        else
            $btnallestaten.trigger('click');
    }
    initThirdForm() {
        let $quality_slider = $("#quality_slider");
        /*
        function changeSettingValues(level) {
          $("#slider").val(level);
      
          switch (level) {
            case "1":
              $("#sliderlabel1").css('color', 'black');
              $("#sliderlabel2").css('color', 'lightgrey');
              $("#sliderlabel3").css('color', 'lightgrey');
      
              $("#maxvalue").val(1000);
              $("#maxiteration").val(10000);
              $("#initialcoolingrate").val(1000);
              break;
      
            case "2":
              $("#sliderlabel1").css('color', 'lightgrey');
              $("#sliderlabel2").css('color', 'black');
              $("#sliderlabel3").css('color', 'lightgrey');
        
              $("#maxvalue").val(1000);
              $("#maxiteration").val(20000);
              $("#initialcoolingrate").val(1000);
              break;
            case "3":
              $("#sliderlabel1").css('color', 'lightgrey');
              $("#sliderlabel2").css('color', 'lightgrey');
              $("#sliderlabel3").css('color', 'black');
      
              $("#maxvalue").val(500);
              $("#maxiteration").val(100000);
              $("#initialcoolingrate").val(500);
              break;
          }
      
          $("#minimalpark").val(0.5);
          $("#minimalstreetdist").val(5);
        }
        */
        const validateInput = (page) => {
            let nameValid = true;
            let forDevicesValid = true;
            let $routeName = $('#routename');
            let $numcars = $('#numcars');
            let $nextbtn = $('#btn-next');
            if ($routeName == null || !$routeName.val()?.toString().length || !this.verifyRouteName($routeName.val())) {
                $routeName.addClass('is-error');
                nameValid = false;
            }
            else {
                $routeName.removeClass('is-error');
            }
            const $forDate = $('#route_dateswitch').prop('checked');
            const $sendToDevices = $('#send_to_devices_checkbox').prop("checked");
            if ($forDate) {
                // max 1
                let id = '#devices_dropdown_1';
                if (!$(id).val()) {
                    $(id).addClass('is-error');
                    forDevicesValid = false;
                }
                else {
                    $(id).removeClass('is-error');
                }
            }
            else if ($sendToDevices) {
                const num = $numcars.val();
                for (let index = 1; index <= num; index++) {
                    let id = '#devices_dropdown_' + index;
                    if (!$(id).val()) {
                        $(id).addClass('is-error');
                        forDevicesValid = false;
                    }
                    else {
                        $(id).removeClass('is-error');
                    }
                }
            }
            if (!nameValid || !forDevicesValid) {
                $nextbtn.prop('disabled', true);
            }
            else {
                $nextbtn.prop('disabled', false);
            }
        };
        const numCarsChanged = (num) => {
            $('#devices_dropdown_extra_1').addClass('hidden');
            $('#devices_dropdown_extra_2').addClass('hidden');
            $('#devices_dropdown_extra_3').addClass('hidden');
            $('#devices_dropdown_extra_4').addClass('hidden');
            $('#devices_dropdown_extra_5').addClass('hidden');
            for (let index = 0; index <= num; ++index) {
                const id = '#devices_dropdown_extra_' + index;
                $(id).removeClass('hidden');
            }
        };
        var page = this;
        $('#routename').on("input", (e) => {
            validateInput(page);
        });
        var $send_to_devices_checkbox = $('#send_to_devices_checkbox');
        $send_to_devices_checkbox.on("change", (e) => {
            if ($send_to_devices_checkbox.prop("checked")) {
                numCarsChanged($('#numcars').val());
                validateInput(page);
            }
            else {
                numCarsChanged(0);
                validateInput(page);
            }
        });
        $('#numcars').on("input", (e) => {
            if ($send_to_devices_checkbox.prop("checked")) {
                numCarsChanged($('#numcars').val());
                validateInput(page);
            }
        });
        var $devices_dropdown_1 = $('#devices_dropdown_1');
        var $devices_dropdown_2 = $('#devices_dropdown_2');
        var $devices_dropdown_3 = $('#devices_dropdown_3');
        var $devices_dropdown_4 = $('#devices_dropdown_4');
        var $devices_dropdown_5 = $('#devices_dropdown_5');
        $('#devices_dropdown_extra_1').addClass('hidden');
        $('#devices_dropdown_extra_2').addClass('hidden');
        $('#devices_dropdown_extra_3').addClass('hidden');
        $('#devices_dropdown_extra_4').addClass('hidden');
        $('#devices_dropdown_extra_5').addClass('hidden');
        $devices_dropdown_1.on("change", (e) => {
            validateInput(page);
        });
        $devices_dropdown_2.on("change", (e) => {
            validateInput(page);
        });
        $devices_dropdown_3.on("change", (e) => {
            validateInput(page);
        });
        $devices_dropdown_4.on("change", (e) => {
            validateInput(page);
        });
        $devices_dropdown_5.on("change", (e) => {
            validateInput(page);
        });
        //changeSettingValues("2");
        // $slider.on("change", (e) => {
        //   changeSettingValues($slider.val())
        // });
        $("#sliderlabel1").on("click", (e) => {
            $quality_slider.val("1");
            //changeSettingValues("1");
        });
        $("#sliderlabel2").on("click", (e) => {
            $quality_slider.val("3");
            //changeSettingValues("3");
        });
        $("#sliderlabel3").on("click", (e) => {
            $quality_slider.val("5");
            //changeSettingValues("5");
        });
        let $advancedbtn = $('#advanced_button');
        $advancedbtn.on("click", function () {
            $advancedbtn.toggleClass('active');
            const $nextElement = $advancedbtn.next();
            $nextElement.toggle();
        });
    }
    createRequest(ids) {
        let $parkingptreetIds = Object.values(this.waySegments);
        const $form = $(`#${ids}`);
        let $settings = {};
        var areas = [];
        $("#areasAccordion > tbody > tr.selected").each(function () {
            areas.push(this.id);
        });
        var zones = [];
        $("#zonesAccordion > tbody > tr.selected").each(function () {
            zones.push(this.id);
        });
        var routeareas = [];
        $("#routeareasAccordion > tbody > tr.selected").each(function () {
            routeareas.push(this.id);
        });
        const numroutes = $form.find('#numcars').val();
        let fordevice = "";
        if ($('#send_to_devices_checkbox').prop("checked")) {
            fordevice = $('#devices_dropdown_1').val();
            for (let index = 2; index <= numroutes; index++) {
                let id = '#devices_dropdown_' + index;
                fordevice += (',' + $(id).val());
            }
        }
        $settings["Areas"] = areas;
        $settings["Zones"] = zones;
        $settings["RouteAreas"] = routeareas;
        $settings["Occupancy"] = $('#rangeslider_1').prop("value1") / 100 + "/" + $('#rangeslider_1').prop("value2") / 100;
        $settings["VisitorRate"] = $('#rangeslider_2').prop("value1") / 100 + "/" + $('#rangeslider_2').prop("value2") / 100;
        $settings["Compliancy"] = $('#rangeslider_3').prop("value1") / 100 + "/" + $('#rangeslider_3').prop("value2") / 100;
        $settings["CompliancyVisitors"] = $('#rangeslider_4').prop("value1") / 100 + "/" + $('#rangeslider_4').prop("value2") / 100;
        $settings["EnforcementIntensity"] = $('#rangeslider_5').prop("value1") / 100 + "/" + $('#rangeslider_5').prop("value2") / 100;
        $settings["AllStreets"] = $('#btn-allestraten').hasClass("selected");
        $settings["WithoutStatistics"] = $('#statistics_switch').prop("checked");
        const routename = ($form.find('#routename').val());
        const preferright = $form.find('#preferright').prop("checked");
        const qualityLevel = $form.find('#quality_slider').val();
        //const maxvalue = $form.find('#maxvalue').val();
        //const maxiteration = $form.find('#maxiteration').val();
        //const initialcoolingrate = $form.find('#initialcoolingrate').val();
        const routeFromTime = $("#route_date").val() + " " + $("#route_fromtime").val();
        const routeToTime = $("#route_date").val() + " " + $("#route_totime").val();
        const planrequest = {
            "RouteName": routename,
            "ForDevice": fordevice,
            "PreferRight": preferright,
            "ParkingStreetIds": $parkingptreetIds,
            "Settings": $settings,
            "NumRoutes": numroutes,
            "QualityLevel": qualityLevel,
            //"MaxValue": maxvalue,
            //"MaxIteration": maxiteration,
            //"InitialCoolingRate": initialcoolingrate,
            "RouteFromTime": routeFromTime,
            "RouteToTime": routeToTime
        };
        CCCClient.SendMessage("PlanRoute_CreateRoute_Request", 0, planrequest, 0, {
            Type: "ControlCenter",
            IndexNumber: 1,
            CustomerNumber: CCCClient.NodeCustomerNumber,
            ProjectNumber: CCCClient.NodeProjectNumber
        });
    }
    async recalculateRoute(route) {
        const parseSettingsString = (string) => {
            if (string.includes('/')) {
                const array = string.split("/");
                if (array.length === 2) {
                    return array;
                }
            }
            return [];
        };
        const req = this.requests.find(r => r.RouteId === route.RouteId);
        if (!req) { // ERROR
            throw new Error(`Couldn't recalculate route!`);
        }
        let $settings = req.Settings;
        let allWaySegmentsIds = [];
        let allWaySegments = [];
        let waySegmentsIds = [];
        for (const i in $settings.Areas) {
            if (this.geoMap.Areas[$settings.Areas[i]] && this.geoMap.Areas[$settings.Areas[i]].WaySegments) {
                allWaySegmentsIds = allWaySegmentsIds.concat(this.geoMap.Areas[$settings.Areas[i]].WaySegments);
            }
        }
        for (const i in $settings.Zones) {
            // @ts-ignore
            if (this.geoMap.Zones[$settings.Zones[i]] && this.geoMap.Zones[$settings.Zones[i]].WaySegments) {
                // @ts-ignore
                allWaySegmentsIds = allWaySegmentsIds.concat(this.geoMap.Zones[$settings.Zones[i]].WaySegments);
            }
        }
        for (const i in $settings.RouteAreas) {
            // @ts-ignore
            if (this.geoMap.RouteAreas[$settings.RouteAreas[i]] && this.geoMap.RouteAreas[$settings.RouteAreas[i]].WaySegments) {
                // @ts-ignore
                allWaySegmentsIds = allWaySegmentsIds.concat(this.geoMap.RouteAreas[$settings.RouteAreas[i]].WaySegments);
            }
        }
        if (allWaySegmentsIds != null) {
            for (let i = 0; i < allWaySegmentsIds.length; i++) {
                allWaySegments[allWaySegmentsIds[i]] = this.geoMap.WaySegments[allWaySegmentsIds[i]];
            }
        }
        if (allWaySegments == null)
            return;
        let allstreets = ($settings.AllStreets != undefined && $settings.AllStreets);
        let without_statistics = true;
        if ($settings.WithoutStatistics != undefined)
            without_statistics = $settings.WithoutStatistics;
        if (allstreets) {
            for (const id in allWaySegments) {
                waySegmentsIds.push(id);
            }
        }
        else {
            let Occupancy_set = parseSettingsString($settings.Occupancy);
            let VisitorRate_set = parseSettingsString($settings.VisitorRate);
            let Compliancy_set = parseSettingsString($settings.Compliancy);
            let CompliancyVisitors_set = parseSettingsString($settings.CompliancyVisitors);
            let minOccupancy_set = parseFloat(Occupancy_set[0]);
            let maxOccupancy_set = parseFloat(Occupancy_set[1]);
            let minVisitorRate_set = parseFloat(VisitorRate_set[0]);
            let maxVisitorRate_set = parseFloat(VisitorRate_set[1]);
            let minCompliancy_set = parseFloat(Compliancy_set[0]);
            let maxCompliancy_set = parseFloat(Compliancy_set[1]);
            let minCompliancyVisitors_set = parseFloat(CompliancyVisitors_set[0]);
            let maxCompliancyVisitors_set = parseFloat(CompliancyVisitors_set[1]);
            for (const id in allWaySegments) {
                let waySegment = allWaySegments[id];
                if (waySegment.ParkingCountLeft > 0 || waySegment.ParkingCountRight > 0) {
                    if (((waySegment.Occupancy >= minOccupancy_set && waySegment.Occupancy <= maxOccupancy_set) || (!waySegment.Occupancy && without_statistics)) &&
                        ((waySegment.VisitorRate >= minVisitorRate_set && waySegment.VisitorRate <= maxVisitorRate_set) || (!waySegment.VisitorRate && without_statistics)) &&
                        ((waySegment.Compliancy >= minCompliancy_set && waySegment.Compliancy <= maxCompliancy_set) || (!waySegment.Compliancy && without_statistics)) &&
                        ((waySegment.CompliancyVisitors >= minCompliancyVisitors_set && waySegment.CompliancyVisitors <= maxCompliancyVisitors_set) || (!waySegment.CompliancyVisitors && without_statistics))) {
                        waySegmentsIds.push(id);
                    }
                }
            }
        }
        const recalc_route = {
            "RouteId": route.RouteId,
            "ParkingStreetIds": waySegmentsIds
        };
        CCCClient.SendMessage("PlanRoute_RecalcRoute_Request", 0, recalc_route, 0, {
            Type: "ControlCenter",
            IndexNumber: 1,
            CustomerNumber: CCCClient.NodeCustomerNumber,
            ProjectNumber: CCCClient.NodeProjectNumber
        });
    }
    /*
          ALERTS
    */
    async alert_recalcRoute(route) {
        if (route === undefined)
            return;
        const events = Alerts.show({
            translatedTitle: this.translations['Recalculate route'],
            content: ( /*html*/`
        <div id="${route.RouteId}" class="form-group">
          <label class="form-label">${this.translations['Recalculate route']}: '${route.RouteName}'?</label>
          <div> </div>
          <div>${this.translations['Route will be deleted from']}:</div>
          <ul>
            <li>Database</li>
            <li>${this.translations['Planning']}</li>
          </ul>
        </div>
      `),
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.recalcCancel
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            await this.recalculateRoute(route).catch(AError.handle);
            // const data = { "RouteId": $route.RouteId }
            // CCCClient.SendMessage("PlanRouteDeleteRouteRequest", 0, data, 0, {
            //   Type: "ControlCenter",
            //   IndexNumber: 1,
            //   CustomerNumber: CCCClient.NodeCustomerNumber,
            //   ProjectNumber: CCCClient.NodeProjectNumber
            // })
        });
    }
    async alert_deleteRoute(route_request) {
        const events = Alerts.show({
            translatedTitle: this.translations['Delete route'],
            content: ( /*html*/`
      <div id="${route_request.Request.RouteId}" class="form-group">
        <label class="form-label">${this.translations['Delete route']}: '${route_request.Request.RouteName}'?</label>
      </div>
    `),
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.deleteCancel
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            const data = { "RouteId": route_request.Request.RouteId };
            CCCClient.SendMessage("PlanRoute_DeleteRoute_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
        });
    }
    async alert_sendForm(route) {
        if (route === undefined)
            return;
        var deviceshtml = createSelectScanDeviceListHtmlAll("devices_dropdown");
        const html = ( /*html*/`
      <div id="${route.RouteId}" class="form-group">
        <label class="form-label" for="devices">${this.translations['Send To']}</label>
        ${deviceshtml}
        <label class="form-label" for="routename">${this.translations['Route name']}</label>
        <input class="form-input" autocomplete="off" type="text" value="${route.RouteName}" id="routename">
        <label class="form-label" style="margin-top: 10px">${this.translations['Date (optional)']}</label>
        <div class="columns col col-12">
          <div class="column col-4">
            <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="route_date">
          </div>
          <div class="column col-4">
            <input class="form-input" type="time" disabled id="route_fromtime">
          </div>
          <div class="column col-4">
            <input class="form-input" type="time" disabled id="route_totime">
          </div>
        </div>
        <div style="text-align: center; margin-top: 50px;">
          <button id="btn-send" class="btn btn-primary" style="width: 100px;">${this.translations['Send']}</button>
        </div>
      </div>
    `);
        const events = Alerts.show({
            translatedTitle: this.translations['Send Route To Car'],
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.okCancel
        });
        let $date = $("#route_date");
        let $from = $("#route_fromtime");
        let $to = $("#route_totime");
        const duration_route = estimateRouteDurationFromDistance(route.Length);
        const verifyInput = () => {
            let sendTovalid = false;
            let dateValid = true;
            let nameValid = false;
            let $devices = $('#devices_dropdown');
            if ($devices.val()) {
                sendTovalid = true;
                $devices.removeClass('is-error');
            }
            else {
                $devices.addClass('is-error');
            }
            let routeName = $('#routename').val() || '';
            if (routeName.length > 0 && routeName.length < 75 && /^[^\\/:\*\?"<>\|\.]+$/.test(routeName)) {
                nameValid = true;
                $('#routename').removeClass('is-error');
            }
            else {
                $('#routename').addClass('is-error');
            }
            if ($date.val()) {
                if ($from.val() && new Date($date.val() + " " + $from.val()) > new Date()) {
                    var $fromdate = new Date($date.val() + " " + $from.val());
                    $fromdate.setSeconds($fromdate.getSeconds() + duration_route);
                    $from.removeClass('is-error');
                    $to.val($fromdate.toTimeString().substring(0, 5));
                }
                else {
                    $to.val("-:--");
                    $from.addClass('is-error');
                    dateValid = false;
                }
            }
            let $sendbtn = $('#btn-send');
            if (dateValid && sendTovalid && nameValid) {
                $sendbtn.prop('disabled', false);
            }
            else {
                $sendbtn.prop('disabled', true);
            }
        };
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $date.prop('min', minDate);
        $to.prop("readonly", true);
        $('#devices_dropdown').on("change", (e) => {
            verifyInput();
        });
        $('#routename').on("change", (e) => {
            verifyInput();
        });
        $('#routename').on("input", (e) => {
            verifyInput();
        });
        $date.on("change", (e) => {
            verifyInput();
            $from.prop('disabled', false);
            $to.prop('disabled', false);
        });
        $from.on("change", (e) => {
            verifyInput();
        });
        verifyInput();
        $('#btn-send').on("click", () => {
            const sendtoname = $('#devices_dropdown').val();
            const routename = $('#routename').val();
            let routefrom = "";
            let routeto = "";
            if ($date.val()) {
                routefrom = $date.val() + " " + $from.val();
                routeto = $date.val() + " " + $to.val();
            }
            const data = {
                "ProjectNr": CCCClient.NodeProjectNumber,
                "CustomerNr": CCCClient.NodeCustomerNumber,
                "SendToName": sendtoname,
                "RouteId": route.RouteId,
                "RouteName": routename,
                "CarNumber": route.CarNumber,
                "RouteFromTime": routefrom,
                "RouteToTime": routeto
            };
            CCCClient.SendMessage("PlanRoute_SendRouteToCar_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Alerts.closeAllActiveModals();
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_showRouteInfo(route) {
        if (route === undefined)
            return;
        const request = this.requests.find(req => req.RouteId === route.RouteId);
        if (request === undefined || request.Settings === undefined)
            return;
        const Settings = request.Settings;
        let areazoneHtml = ``;
        if (Settings.Areas != undefined && Settings.Areas.length) {
            areazoneHtml += /*html*/ `<h6>${this.translations['Area(s)']}</h6>`;
            if (this.geoMap.Areas[Settings.Areas[0]] === undefined)
                areazoneHtml += /*html*/ `<span>n/a`;
            else
                areazoneHtml += /*html*/ `<span>${this.geoMap.Areas[Settings.Areas[0]].Name}`;
            for (let i = 1; i < Settings.Areas.length; i++) {
                if (this.geoMap.Areas[Settings.Areas[i]] === undefined)
                    areazoneHtml += /*html*/ `, n/a`;
                else
                    areazoneHtml += /*html*/ `, ${this.geoMap.Areas[Settings.Areas[i]].Name}`;
            }
            areazoneHtml += /*html*/ `</span>`;
        }
        else if (Settings.Zones != undefined && Settings.Zones.length) {
            areazoneHtml += /*html*/ `<h6>${this.translations['Zone(s)']}</h6>`;
            if (this.geoMap.Zones[Settings.Zones[0]] === undefined)
                areazoneHtml += /*html*/ `<span>n/a`;
            else
                areazoneHtml += /*html*/ `<span>${this.geoMap.Zones[Settings.Zones[0]].Name}`;
            for (let i = 1; i < Settings.Zones.length; i++) {
                if (this.geoMap.Zones[Settings.Zones[i]] === undefined)
                    areazoneHtml += /*html*/ `, n/a`;
                else
                    areazoneHtml += /*html*/ `, ${this.geoMap.Zones[Settings.Zones[i]].Name}`;
            }
            areazoneHtml += /*html*/ `</span>`;
        }
        else if (Settings.RouteAreas != undefined && Settings.RouteAreas.length) {
            areazoneHtml += /*html*/ `<h6>${this.translations['RouteArea(s)']}</h6>`;
            if (this.geoMap.RouteAreas[Settings.RouteAreas[0]] === undefined)
                areazoneHtml += /*html*/ `<span>n/a`;
            else
                areazoneHtml += /*html*/ `<span>${this.geoMap.RouteAreas[Settings.RouteAreas[0]].Name}`;
            for (let i = 1; i < Settings.RouteAreas.length; i++) {
                if (this.geoMap.RouteAreas[Settings.RouteAreas[i]] === undefined)
                    areazoneHtml += /*html*/ `, n/a`;
                else
                    areazoneHtml += /*html*/ `, ${this.geoMap.RouteAreas[Settings.RouteAreas[i]].Name}`;
            }
            areazoneHtml += /*html*/ `</span>`;
        }
        function parseSettingsString(string) {
            if (string.includes('/')) {
                const array = string.split("/");
                if (array.length === 2) {
                    return (parseFloat(array[0]) * 100) + "% - " + (parseFloat(array[1]) * 100) + "%";
                }
            }
            return "-";
        }
        const strOccupancy = parseSettingsString(Settings.Occupancy);
        const strVisitorRate = parseSettingsString(Settings.VisitorRate);
        const strCompliancy = parseSettingsString(Settings.Compliancy);
        const strCompliancyVisitors = parseSettingsString(Settings.CompliancyVisitors);
        const strEnforcementIntensity = parseSettingsString(Settings.EnforcementIntensity);
        const html = /*html*/ `
      <div class="columns" style="min-height: 300px; overflow-y: auto;">
        <div class="col-12">
          ${areazoneHtml}
        </div>
        <div class="col-12" style="margin-top: 20px">
          <h6>GeoDataTimeStamp</h6>
          <span>${route.GeoDataTimeStamp}</span>
        </div>
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['Occupancy']}</h6>
          <span>${strOccupancy}</span>
        </div>
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['VisitorRate']}</h6>
          <span>${strVisitorRate}</span>
        </div>
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['Compliancy']}</h6>
          <span>${strCompliancy}</span>
        </div>
        <div class="col-6" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['CompliancyVisitors']}</h6>
          <span>${strCompliancyVisitors}</span>
        </div>  
        <div class="col-6" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['EnforcementIntensity']}</h6>
          <span>${strEnforcementIntensity}</span>
        </div>
        
        <div class="col-10 columns" style="margin-left: 20px; margin-top: 20px;">
          <h6 class="col-6" style="text-align: left;">${this.translations['MaxValue']}</h6>
          <span class="col-6" style="text-align: right;">${request.MaxValue}</span>
          <h6 class="col-6" style="text-align: left;">${this.translations['MaxIteration']}</h6>
          <span class="col-6" style="text-align: right;">${request.MaxIteration}</span>
          <h6 class="col-6" style="text-align: left;">${this.translations['InitialCooling Rate']}</h6>
          <span class="col-6" style="text-align: right;">${request.InitialCoolingRate}</span>
        </div>

      </div>`.replace(/\s\s+/g, ' ');
        Alerts.show({
            translatedTitle: route.RouteName,
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.ok
        });
    }
    async alert_showRequestInfo(request) {
        const $settings = request.Settings;
        let areazoneHtml = ``;
        if ($settings.Areas != undefined && $settings.Areas.length) {
            areazoneHtml += /*html*/ `<h6>Areas</h6>`;
            if (this.geoMap.Areas[$settings.Areas[0]] === undefined)
                areazoneHtml += /*html*/ `<span>n/a`;
            else
                areazoneHtml += /*html*/ `<span>${this.geoMap.Areas[$settings.Areas[0]].Name}`;
            for (let i = 1; i < $settings.Areas.length; i++) {
                if (this.geoMap.Areas[$settings.Areas[i]] === undefined)
                    areazoneHtml += /*html*/ `, n/a`;
                else
                    areazoneHtml += /*html*/ `, ${this.geoMap.Areas[$settings.Areas[i]].Name}`;
            }
            areazoneHtml += /*html*/ `</span>`;
        }
        else if ($settings.Zones != undefined && $settings.Zones.length) {
            areazoneHtml += /*html*/ `<h6>Zones</h6>`;
            if (this.geoMap.Zones[$settings.Zones[0]] === undefined)
                areazoneHtml += /*html*/ `<span>n/a`;
            else
                areazoneHtml += /*html*/ `<span>${this.geoMap.Zones[$settings.Zones[0]].Name}`;
            for (let i = 1; i < $settings.Zones.length; i++) {
                if (this.geoMap.Zones[$settings.Zones[i]] === undefined)
                    areazoneHtml += /*html*/ `, n/a`;
                else
                    areazoneHtml += /*html*/ `, ${this.geoMap.Zones[$settings.Zones[i]].Name}`;
            }
            areazoneHtml += /*html*/ `</span>`;
        }
        else if ($settings.RouteAreas != undefined && $settings.RouteAreas.length) {
            areazoneHtml += /*html*/ `<h6>RouteAreas</h6>`;
            if (this.geoMap.RouteAreas[$settings.RouteAreas[0]] === undefined)
                areazoneHtml += /*html*/ `<span>n/a`;
            else
                areazoneHtml += /*html*/ `<span>${this.geoMap.RouteAreas[$settings.RouteAreas[0]].Name}`;
            for (let i = 1; i < $settings.RouteAreas.length; i++) {
                if (this.geoMap.RouteAreas[$settings.RouteAreas[i]] === undefined)
                    areazoneHtml += /*html*/ `, n/a`;
                else
                    areazoneHtml += /*html*/ `, ${this.geoMap.RouteAreas[$settings.RouteAreas[i]].Name}`;
            }
            areazoneHtml += /*html*/ `</span>`;
        }
        function parseSettingsString(string) {
            if (string.includes('/')) {
                const array = string.split("/");
                if (array.length === 2) {
                    return (parseFloat(array[0]) * 100) + "% - " + (parseFloat(array[1]) * 100) + "%";
                }
            }
            return "-";
        }
        const html = /*html*/ `
      <div class="columns" style="min-height: 300px; overflow-y: auto;">
        
        <div class="col-12">
          ${areazoneHtml}
        </div>
        
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['Occupancy']}</h6>
          <span>${parseSettingsString($settings.Occupancy)}</span>
        </div>
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['VisitorRate']}</h6>
          <span>${parseSettingsString($settings.VisitorRate)}</span>
        </div>
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['Compliancy']}</h6>
          <span>${parseSettingsString($settings.Compliancy)}</span>
        </div>
        
        <div class="col-6" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['CompliancyVisitors']}</h6>
          <span>${parseSettingsString($settings.CompliancyVisitors)}</span>
        </div>  
        <div class="col-6" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['EnforcementIntensity']}</h6>
          <span>${parseSettingsString($settings.EnforcementIntensity)}</span>
        </div> 

        <div class="col-10 columns" style="margin-left: 20px; margin-top: 20px;">
          <h6 class="col-6" style="text-align: left;">${this.translations['MaxValue']}</h6>
          <span class="col-6" style="text-align: right;">${request.MaxValue}</span>
          <h6 class="col-6" style="text-align: left;">${this.translations['MaxIteration']}</h6>
          <span class="col-6" style="text-align: right;">${request.MaxIteration}</span>
          <h6 class="col-6" style="text-align: left;">${this.translations['InitialCoolingRate']}</h6>
          <span class="col-6" style="text-align: right;">${request.InitialCoolingRate}</span>
        </div>
    </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: request.RouteName,
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.ok
        });
    }
    fetchAndCacheRequestNames() {
        return Loading.waitForPromises(requestService.query(`SELECT RouteName FROM planroute_requests`)).then(response => {
            for (let row of response.Rows) {
                this.requestNames.push(row[0]);
            }
            return response;
        });
    }
    fetchRouteNames() {
        return Loading.waitForPromises(requestService.query(`SELECT RouteName FROM planroute_routes`)).then(response => {
            for (let row of response.Rows) {
                this.routeNames.push(row[0]);
            }
            return response;
        });
    }
}
export function css() {
    return ( /*html*/`
    <style>
      #route_list,
      #route_list h5 {
        cursor: default;
      }
      .fixTableHead {
        overflow-y: auto;
        height: 100%;
      }
      .fixTableHead thead th {
        position: sticky;
        background: #F9F9F9;
        top: 0;
      }
      .tableRow {
        background: #ffffff;
      }
      .tableRow:hover {
        background: #F9F9F9;
      }
      .tableRow.selected {
        background: #ECECEC;
      }

      .footer {
        background: rgb(248, 248, 248);
        border-top: 1px solid #eeeeee;
        width: calc(100% + 8px);
        padding: 7px 0;
      }

      .footer .text {
        padding-left: 8px;
        line-height: 35px;
      }

      .tableRowInActive {
        background: #F8F8F8;
        color: #BBBBBB;
      }
      .tableRowInActive.selected {
        background: #EBEBEB;
        color: #BBBBBB;
      }
      .tableRowInActive:hover {
        background: #F1F1F1;
      }

      .collapsible {
        background-color: #eee;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
      }

      .collapsible.active,
      .collapsible:hover {
        background-color: #ccc;
      }

      .contentcollapsible {
        padding: 0 18px;
        display: none;
        overflow: hidden;
        background-color: #ffffff;
      }

      .collapsible::after {
        content: '\\02795';
        /* Unicode character for "plus" sign (+) */
        font-size: 13px;
        color: white;
        float: right;
        margin-left: 5px;
      }

      .collapsible.active::after {
        content: "\\2796";
        /* Unicode character for "minus" sign (-) */
      }

      #new-request .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
      }

      #new-request .slider::hover {
        opacity: 1;
      }

      .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #04AA6D;
        cursor: pointer;
      }

      .accordion-wrapper > a{
        padding: 15px 8px
      }      

      .accordion-wrapper .tableWrapper {
        max-height: 235px;
        overflow-y: auto;
      } 

      .mapWrapper {
        height: calc(100% - 100px);
        overflow-y: auto;
      }

      .aci-map + .legend {
        display: none;
      }

      .wrapTextColumn{
        overflow: hidden; 
        text-overflow: ellipsis; 
        white-space: nowrap;
      }
    </style>  
  `);
}
export function render() {
    return ( /*html*/`
    <div class="flex-child fh">
      <div class="flex-content">
        <div class="splitter two-split fh" style="overflow-y: auto; overflow-x: hidden;">

          <div class="drag-section part-one" style="width: 30%; min-width: 180px;">
            <div class="fixTableHead has-footer-2-i">
              <table style="table-layout: fixed; width: 100%;" class="table">
                <thead>
                  <tr>
                    <th>
                      <div class="columns" style="margin: auto;">
                        <h5 class="col-2" style="margin: auto; text-align: left;">Routes</h5>
                        <div class="col-5" style="margin: auto; text-align: right;">Sorting</div>
                        <select id="routes_sorting" class="col-4 form-input" style="margin-left: 5px;">
                          <option value="cn">Date: New - Old</option>
                          <option value="co">Date: Old - New</option>
                          <option value="az">Name: A-Z</option>
                          <option value="za">Name: Z-A</option>
                        </select>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody id="route_list">
                </tbody>
              </table>
            </div>
            <div class="columns footer aci">
              <div class="column col-12">
                <button id="create-request" disabled="disabled" class="btn fw">
                  <div class="px-3">
                    Service not running
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div id="separator1" class="drag-seperator separator"></div>

          <div class="drag-section part-two" style="width: 70%; min-width: 180px;">

            <div class="fh">
              <div class="mapWrapper" style="height: calc(100% - 200px)">
                <div id="route_map" class="aci-map"></div>
                <div class="legend legend-opaque" id="route_map_legend">
                  <div class="legend-label label-height-lg hidden">Legend</div>
                  <div class="legend-item">
                    <div class="route-preview" style="background-color: rgba(255, 0, 0, 0.7); border-color: #a30000"></div>
                    <span>With Parking</span>
                  </div>
                  <div class="legend-item">
                    <div class="route-preview" style="background-color: rgba(255, 143, 15, 0.7); border-color: #e08722">
                    </div>
                    <span>Without Parking</span>
                  </div>
                </div>
              </div>

              <div id="info_screen" class="columns" style="height: 200px">
                <div id="info_screen_splash" class="columns col-12">
                  <div style="margin: auto; text-align: center;">
                    <div template="${ATemplates.RouteInformation}"></div>
                  </div>
                </div>

                <div id="info_screen_route" class="columns col-12" style="display: none;">
                  <div class="col-12 mt-1" style="margin: auto; text-align: center; width: 80%">
                    <tc-range-slider id="info_route_slider" min="0" max="100" step="1" value1="0" round="10"
                      slider-bg="#CBD5E1" slider-bg-hover="#94A3B8" slider-bg-fill="#009EC5" slider-width="100%"
                      style="--opacity: 0.2">
                    </tc-range-slider>
                  </div>

                  <div class="col-3 wrapTextColumn" style="margin: auto; text-align: center;">
                    <h6>Route Name</h6>
                    <span id="info_route_name">ROUTENAME</span>
                  </div>

                  <div class="col-3 wrapTextColumn" style="margin: auto; text-align: center;">
                    <h6>Route distance</h6>
                    <span id="info_route_distance">DISTANCE ROUTE</span>
                  </div>

                  <div class="col-3 wrapTextColumn" style="margin: auto; text-align: center;">
                    <label for="info_route_info">Info</label><br>
                    <button style="width: 36px; height: 36px;  margin-top: 5px" id="info_route_info"
                      class="btn btn-primary">
                      <i class="fa fa-info" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div class="col-3 wrapTextColumn" style="margin: auto; text-align: center;">
                    <label for="info_route_send">Send</label><br>
                    <button style="width: 36px; height: 36px;  margin-top: 5px" id="info_route_send"
                      class="btn btn-success">
                      <i class="fa fa-share-square" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div class="col-3 wrapTextColumn" style="margin: auto; text-align: center;">
                    <h6>Estimate Duration</h6>
                    <span id="info_route_duration">ESTIMATE DURATION</span>
                  </div>

                  <div class="col-3 wrapTextColumn" style="margin: auto; text-align: center;">
                    <h6>Created</h6>
                    <span id="info_route_created">DATE CREATED</span>
                  </div>

                  <div class="col-3 wrapTextColumn" style="margin: auto; text-align: center;">
                    <label for="info_route_recalc">Recalculate</label><br>
                    <button style="width: 36px; height: 36px; margin-top: 5px" id="info_route_recalc"
                      class="btn btn-orange">
                      <i class="fa fa-rotate" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div class="col-3 wrapTextColumn" style="margin: auto; text-align: center;">
                    <label for="info_route_delete">Delete</label><br>
                    <button style="width: 36px; height: 36px;  margin-top: 5px" id="info_route_delete"
                      class="btn btn-error">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>

                <div id="info_screen_request" class="columns col-12" style="display: none;">
                  <div class="col-5 wrapTextColumn" style="margin: auto; text-align: center;">
                    <h6>Request Name</h6>
                    <span id="info_request_name">REQUESTNAME</span>
                  </div>

                  <div class="col-5 wrapTextColumn" style="margin: auto; text-align: center;">
                    <label for="info_request_info">Info</label><br>
                    <button style="width: 36px; height: 36px; margin-top: 5px" id="info_request_info"
                      class="btn btn-primary">
                      <i class="fa fa-info" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div class="col-5 wrapTextColumn" style="margin: auto; text-align: center;">
                    <h6>Created</h6>
                    <span id="info_request_created">DATE CREATED</span>
                  </div>

                  <div class="col-5 wrapTextColumn" style="margin: auto; text-align: center;">
                    <label for="info_request_delete">Delete</label><br>
                    <button style="width: 36px; height: 36px; margin-top: 5px" id="info_request_delete"
                      class="btn btn-error">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `);
}
