import { sleep } from "../../core/AEngine.js";
export class ARoutePlannerService {
    constructor() {
        this.m_enabled = false;
    }
    async fetchGeoMap() {
        if (this.m_geoMap) {
            console.log("geomap already exists");
            return this.m_geoMap;
        }
        else {
            console.log("fetching geomap...");
            const geoMapPromise = new Promise((resolve) => {
                CCCClient.SendMessage("PlanRoute_GetGeoMap_Request", 1, null, 0, {
                    Type: "ControlCenter",
                    IndexNumber: 1,
                    CustomerNumber: CCCClient.NodeCustomerNumber,
                    ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
                });
                Events.once(`PlanRoute_GetGeoMap_Response`, response => {
                    this.m_geoMap = response.GeoMap;
                    if (!this.m_geoMap.Areas)
                        this.m_geoMap.Areas = {};
                    if (!this.m_geoMap.Zones)
                        this.m_geoMap.Zones = {};
                    if (!this.m_geoMap.RouteAreas)
                        this.m_geoMap.RouteAreas = {};
                    if (!this.m_geoMap.WaySegments)
                        this.m_geoMap.WaySegments = {};
                    resolve(this.m_geoMap);
                });
            });
            return await geoMapPromise;
        }
    }
    async pollRouteService() {
        if (this.m_enabled) {
            return this.m_enabled;
        }
        else {
            const geoStatusPromise = new Promise((resolve, reject) => {
                CCCClient.SendMessage("PlanRoute_GetStatus_Request", 1, null, 0, {
                    Type: "ControlCenter",
                    IndexNumber: 1,
                    CustomerNumber: CCCClient.NodeCustomerNumber,
                    ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
                });
                Events.once(`PlanRoute_GetStatus_Response`, response => {
                    if (response.message == 'running')
                        this.m_enabled = true;
                    else
                        this.m_enabled = false;
                    resolve(this.m_enabled);
                });
                sleep(1000).then(async (_) => {
                    resolve(false);
                });
            });
            return await geoStatusPromise;
        }
    }
    async fetchRequests() {
        const ares = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          Id as RouteId,
          Status,
          StatusText,
          RouteName, 
          ParkingStreetIds,
          Settings,
          PreferRight,
          NumRoutes,
          planroute_requests.MaxValue,
          MaxIteration,
          InitialCoolingRate,
          Created,
          Finished
        FROM planroute_requests
        ORDER BY Status DESC, Created
      `)
        }, {
            valueMapper: {
                Created: (v) => new Date(v)
            }
        });
        return ares.toArray();
    }
    async fetchRoutes() {
        let ares = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT 
          r.RouteId, 
          r.RouteName, 
          r.CarNumber, 
          r.Cost, 
          r.Length,
          r.Requestcreated_datetime, 
          ST_AsGeoJSON(r.RouteGpsLine) AS RouteGpsLine,
          r.Active,
          r.RouteSegments,
          r.GeoDataTimeStamp
        FROM planroute_routes r 
        WHERE r.Finished = 1
        ORDER BY Active DESC, RouteName ASC
      `),
        }, {
            valueMapper: {
                CarNumber: (v) => parseInt(v),
                Cost: (v) => parseInt(v),
                Length: (v) => parseInt(v),
                Requestcreated_datetime: (v) => new Date(v),
                GeoDataTimeStamp: (v) => new Date(v),
            }
        });
        return ares.toArray();
    }
    async fetchRoute(id, carnumber) {
        let ares = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT 
          r.RouteId, 
          r.RouteName, 
          r.CarNumber, 
          r.Cost, 
          r.Length,
          r.Requestcreated_datetime, 
          ST_AsGeoJSON(r.RouteGpsLine) AS RouteGpsLine,
          r.Active,
          r.RouteSegments,
          r.GeoDataTimeStamp
        FROM planroute_routes r 
        WHERE r.Finished = 1 AND r.RouteId = :RouteId
        ORDER BY Active DESC, RouteName ASC
      `),
            Params: { RouteId: id }
        }, {
            valueMapper: {
                CarNumber: (v) => parseInt(v),
                Cost: (v) => parseInt(v),
                Length: (v) => parseInt(v),
                Requestcreated_datetime: (v) => new Date(v),
                GeoDataTimeStamp: (v) => new Date(v),
            }
        });
        return ares.toArray();
    }
    async fetchAndCacheRegime(from, to) {
        const data = {
            "From": from,
            "To": to,
        };
        const regimePromise = new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_ValidateRegimeTime_Request", 1, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_ValidateRegimeTime_Response`, response => {
                const regimes = response;
                resolve(regimes);
            });
        });
        return await regimePromise;
    }
    async fetchDrivenRoutes(from, to, DeviceName) {
        const data = {
            FromDate: from,
            ToDate: to,
            DeviceName: DeviceName
        };
        const routesDrivenPromise = new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_GetDrivenRoutes_Request", 1, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_GetDrivenRoutes_Response`, response => {
                const routesDriven = response;
                resolve(routesDriven);
            });
        });
        return await routesDrivenPromise;
    }
}
