import { _getEle$ } from "../../utils/maps.js";
import { initTableAccordions } from "../../utils/table_accordions.js";
import { AInfoWindowHelper } from "../AInfoWindowHelper.js";
import { AResizeHandler } from "./AResizeHandler.js";
export class AWindowBase {
    async generate(opt) {
        // this.id = idAllocatorService.getNextId({prefix: 'win-'})
        const { alignTo, content } = opt;
        const parent = opt.lifeCycle === 'CONTINUOUS' ? 'body' : '#AjaxContent';
        let $win = $(`
      <div class="awin custom-scroll">
        <div class="awin-header">
          <i class="exit fa-light fa-xmark fa-fw"></i>
        </div>
        <div class="awin-content">
          ${content}
        </div>
        <div class="awin-anchor anchor-tl" anchor="tl"></div> <div class="awin-anchor anchor-tr" anchor="tr"></div>
        <div class="awin-anchor anchor-bl" anchor="bl"></div> <div class="awin-anchor anchor-br" anchor="br"></div>
      </div>
    `);
        // this.initResize($win)
        initTableAccordions($win);
        const fheight = $win.find('.awin-content tr').length * 21;
        $win.attr('summary-height', fheight);
        $win.attr('full-height', fheight * 2);
        AInfoWindowHelper.initDraggableWindow({ $window: $win });
        $win.find('.exit').on('click', () => this.close());
        $win.appendTo(parent ?? '#AjaxContent');
        this.initSize($win, opt);
        this.initPos($win, { alignTo, parent: opt.alignTo ?? parent ?? '#AjaxContent' });
        this.handler = new AResizeHandler(opt).attachTo($win);
        this.$internal = $win;
    }
    initSize($win, opt) {
        var sizeOrder = [opt.minSize, opt.size];
        // Set Default size
        sizeOrder.map(size => {
            var { width, height } = size ?? {};
            $win.css({ ...(height ? { height } : {}), ...(width ? { width } : {}) });
        });
        // Set Minimum Size
        if (opt.minSize) {
            $win.css({
                ...(opt.minSize.height ? { minHeight: opt.minSize.height } : {}),
                ...(opt.minSize.width ? { minWidth: opt.minSize.width } : {}),
            });
        }
    }
    initPos($win, opt) {
        // Move to middle if needed
        const offset = opt.alignTo === 'center' ? this.getCenterOffset($win) : _getEle$(opt.parent).offset();
        $win.css({ ...offset });
    }
    getCenterOffset($win) {
        let { innerWidth: w, innerHeight: h } = window;
        return {
            left: (w - $win.width()) / 2,
            top: (h - $win.height()) / 2,
        };
    }
    show() {
        throw new Error("Method not implemented.");
    }
    close() {
        throw new Error("Method not implemented.");
    }
    destruct() {
        throw new Error("Method not implemented.");
    }
}
