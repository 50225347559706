import { AError } from "../../classes/AError.js";
import { AForm } from "../../core/form/AForm.js";
import { COLUMN_ACTION, COLUMN_BOOLEAN_NULLABLE, COLUMN_HIDDEN, COLUMN_MULTI_SELECT, COLUMN_MULTI_SELECT_TRANSLATE, COLUMN_TEXT } from "../../classes/AGridTypes.js";
import { ASearchHandler } from "../../classes/grid/ASearchHandler.js";
import { AEngine } from "../../core/AEngine.js";
import { AFollowUpOrm } from "../../orm/AFollowUpOrm.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { AConvertToGridColumns, AShowTable, transformButtonCls, transformTextCls } from "../../utils/tools.js";
import { AFormInstance } from "../../core/form/AFormInstance.js";
export class APage {
    constructor() {
        this.followUpOrm = new AFollowUpOrm();
        this.searchHandler = new ASearchHandler();
        this.DeviceTypes = ['CentralVerification', 'Pda'];
        this.SessionModes = ['Enforce', 'FollowUp'];
    }
    set state(value) {
        this._state = value;
        switch (this._state) {
            case "tab-grid-view":
                filterService.setActive(true);
                break;
            case "tab-form-view":
                filterService.setActive(false, { silent: true });
                break;
        }
    }
    changePageState(opt) {
        this.state = opt.tab;
        const $tab = $(`[tab="${opt.tab}"]`);
        if ($tab.length === 0) {
            AError.handle(`Couldn't find tab [tab="${opt.tab}"]`);
        }
        $tab.trigger('click');
    }
    async init() {
        this.translations = await Translate.get([
            'All',
            'Create Follow Up Option',
            'Edit Follow Up Option',
            ...Channels.map(({ ChannelCode }) => {
                return ChannelCode.startsWith('CH|') ? 'FM|' + ChannelCode.substring(3) : ChannelCode;
            }),
            ...Channels.map(({ ChannelCode }) => {
                return ChannelCode;
            }),
        ]);
        await permissionService.refetchUsergroups();
        await this.followUpOrm.validateRootItems();
        Loading.waitForPromises(this.refresh());
    }
    calcGeneration(model) {
        let gen = -1;
        while (model !== undefined) {
            model = model.parent;
            gen++;
        }
        return gen;
    }
    async genFormInputs(opt) {
        const { gen, record } = opt;
        const colorClasses = ['blue', 'purple', 'green', 'yellow', 'orange', 'red', 'grey'];
        const rootOptionCodes = this.grid.store.allRecords
            .map(v => v.data.OptionKey);
        const inputs = [
            {
                id: 'ParentKey',
                type: 'select',
                disabled: true,
                options: [
                    {
                        id: 'null',
                        text: 'None'
                    },
                    ...rootOptionCodes.map(v => {
                        return {
                            id: v,
                            text: v
                        };
                    })
                ]
            },
            { id: 'OptionCode', type: 'text', minlength: 1, maxlength: 64, disabled: record !== undefined && opt.isCreateModal === false },
            {
                id: 'OptionCodeText',
                type: 'text',
                minlength: 1,
                maxlength: 512,
                value: ''
            },
            {
                id: 'OptionType',
                type: 'select',
                options: [
                    'Default',
                    'Fine',
                    'FollowUpToPda',
                    'Requeue'
                ]
            },
            { id: 'Visible', type: 'checkbox' },
            { id: 'AllowCancel', type: 'checkbox' },
            { id: 'EvidenceImagesMandatory', type: 'checkbox' },
            {
                id: 'ColorCls',
                type: 'select',
                options: [
                    {
                        id: 'null',
                        text: 'Inherit'
                    },
                    ...colorClasses.map(v => {
                        return {
                            id: v,
                            text: v
                        };
                    })
                ].slice(gen > 2 ? 0 : 1)
            },
            { id: 'IconCls', type: 'text', minlength: 0, maxlength: 64 },
            { id: 'Icon', type: 'text', minlength: 0, maxlength: 64, disabled: true },
            { id: 'ExternalId', type: 'text', minlength: 0, maxlength: 64 },
            // ENUM('Enforce', 'FollowUp')
            {
                id: 'SessionModes',
                type: 'multiselect',
                disallowNone: true,
                options: [
                    {
                        id: 'Enforce',
                        text: 'Enforce',
                        checked: (record) ? ((record.SessionModes || []).includes('Enforce') || record.SessionModes === null) : true
                    },
                    {
                        id: 'FollowUp',
                        text: 'FollowUp',
                        checked: (record) ? ((record.SessionModes || []).includes('FollowUp') || record.SessionModes === null) : true
                    },
                ]
            },
            // ENUM('CentralVerification', 'Pda')
            {
                id: 'DeviceTypes',
                type: 'multiselect',
                disallowNone: true,
                options: [
                    {
                        id: 'CentralVerification',
                        text: 'CentralVerification',
                        checked: (record) ? ((record.DeviceTypes || []).includes('CentralVerification') || record.DeviceTypes === null) : true
                    },
                    {
                        id: 'Pda',
                        text: 'Pda',
                        checked: (record) ? ((record.DeviceTypes || []).includes('Pda') || record.DeviceTypes === null) : true
                    },
                ]
            },
            // SELECT DISTINCT usergroups
            {
                id: 'Usergroups',
                type: 'multiselect',
                disallowNone: true,
                options: Usergroups.map(g => {
                    return {
                        id: g.UserGroup,
                        text: g.UserGroupText,
                        checked: (record) ? ((record.Usergroups || []).includes(g.UserGroup) || record.Usergroups === null) : true
                    };
                })
            },
            // Channels
            {
                id: 'Channels',
                type: 'multiselect',
                disallowNone: true,
                disabled: true,
                options: Channels.map(ch => {
                    return {
                        id: ch.ChannelCode,
                        text: ch.ChannelName,
                        checked: (record) ? ((record.Channels || []).includes(ch.ChannelCode) || record.Channels === null) : true
                    };
                })
            },
        ];
        return inputs;
    }
    addPrefixInput(opt) {
        const { $input, id, text } = opt;
        const $formGroup = $input.parent();
        const $inputGroup = $(/*html*/ `
      <div class="input-group">
        <span id="${id}" class="input-group-addon">${text}</span>
      </div>
    `);
        $formGroup.append($inputGroup);
        $input.appendTo($inputGroup);
        const $optionCodePrefix = $inputGroup.find(`#${id}`);
        return $optionCodePrefix;
    }
    async cloneTranslationInput(opt) {
        const { $form, $input, id, lang, inputId } = opt;
        const $formGroup = $input.parent();
        const $inputGroup = $(/*html*/ `
      <div class="input-group">
        <span id="${id}" class="input-group-addon">${lang}</span>
      </div>
    `);
        if (opt.$before && opt.$before.length > 0) {
            $inputGroup.insertBefore(opt.$before);
        }
        else {
            $formGroup.append($inputGroup);
        }
        $input.addClass('hidden');
        const $clone = $input.clone();
        $clone.attr('id', inputId);
        $clone.attr('name', inputId);
        $clone.removeClass('hidden');
        $clone.appendTo($inputGroup);
        if (opt.inputValue) {
            $clone.val(opt.inputValue);
        }
        await AForm.initFormValidation($form, [{
                id: inputId,
                type: 'text',
                minlength: 1,
                maxlength: 512,
                value: opt.inputValue
            }]);
        return $inputGroup;
    }
    async initTranslationInputs({ $form, $optionCode, OptionCode }) {
        const $optionCodeText = $form.find('#OptionCodeText');
        const { Translations, AvailableLanguages } = await Translate.fetchTranslationFor(OptionCode);
        const keys = [...new Set(['en', Language, ...Object.keys(Translations)])];
        const $inputGroupArr = await Loading.waitForPromises(keys.map(async (lang) => {
            const $inputGroup = await this.cloneTranslationInput({
                $form: $form,
                lang: lang,
                id: `OptionCodeTextPrefix-${lang}`,
                $input: $optionCodeText,
                inputId: `OptionCodeText-${lang}`,
                inputValue: Translations[lang] ? Translations[lang].Translation || '' : ''
            });
            return $inputGroup;
        }));
        const $addLangGroup = $(/*html*/ `
      <div class="add-lang-group input-group">
        <select class="form-select">
          ${AvailableLanguages.filter(v => !keys.includes(v)).map(v => `<option value="${v}">${v}</option>`).join('')}
        </select>
        <button class="btn btn-grey input-group-btn">
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
    `);
        const $lang = $addLangGroup.find('select');
        const $btnAdd = $addLangGroup.find('.btn');
        $btnAdd.on('click', async (e) => {
            const lang = $lang.val();
            const $selectedOption = $lang.find('option:selected');
            if (($selectedOption.val() || '').length > 0) {
                $selectedOption.remove();
                await this.cloneTranslationInput({
                    $form: $form,
                    lang: lang,
                    id: `OptionCodeTextPrefix-${lang}`,
                    $input: $optionCodeText,
                    inputId: `OptionCodeText-${lang}`,
                    inputValue: '',
                    $before: $addLangGroup,
                });
                $optionCode.trigger('focusout');
            }
        });
        $addLangGroup.before($addLangGroup);
        $inputGroupArr[0].parent().append($addLangGroup);
        return $inputGroupArr[0].closest('.form-group');
    }
    async updateTranslations({ translationKey, $formGroup }) {
        const $inputs = $formGroup.find('.input-group input').toArray().map(inp => $(inp));
        const $langInputs = $inputs.filter($inp => $inp.attr('id')?.startsWith('OptionCodeText-'));
        await Promise.all($langInputs.map($inp => {
            const $lang = $inp.prev();
            if (!$lang.is('span')) {
                console.log({ $lang });
                throw new Error(`Couldn't find lang prefix!`);
            }
            const lang = $inp.attr('id')?.split('-').pop();
            if (!lang) {
                throw new Error(`Couldn't find language for id=${$inp.attr('id')}`);
            }
            const translation = $inp.val();
            return Translate.updateTranslation(lang, translationKey, translation, true);
        }));
    }
    genOptionCode(opt) {
        const { $form, $optionCodePrefix } = opt;
        const options = AForm.extractFormData($form, { ignoreWildcards: false });
        const OptionCode = $optionCodePrefix.text().trim() + options.OptionCode;
        return OptionCode;
    }
    genCreateObject($form, opt) {
        const { ignoreWildcards, $optionCodePrefix, parent } = opt;
        const options = AForm.extractFormData($form, { ignoreWildcards });
        const OptionKey = options.ParentKey + '|' + options.OptionCode;
        const OptionCode = $optionCodePrefix.text().trim() + options.OptionCode;
        Object.assign(options, { OptionKey, OptionCode });
        options.OrderPosition = (parent && parent.children && parent.children.length) ? parent.children.length + 1 : 0;
        return options;
    }
    async displayCreateModal(gen, parent) {
        const Code = (parent.data.OptionKey || parent.data.ParentKey);
        console.log('Code', Code);
        const formData = (parent.data ? {
            ParentKey: Code,
            Usergroups: parent.data.Usergroups || Usergroups.map(v => v.UserGroup),
            SessionModes: parent.data.SessionModes || this.SessionModes,
            DeviceTypes: parent.data.DeviceTypes || this.DeviceTypes,
            Channels: parent.data.Channels,
            Visible: 1,
            ColorCls: null
        } : {});
        const formInputs = await this.genFormInputs({ gen, record: formData, isCreateModal: true });
        const form = new AFormInstance({ ignoreWildcards: true, formInputs });
        const events = Alerts.show({
            translatedTitle: this.translations['Create Follow Up Option'],
            buttons: ALERT_BUTTONS.saveCancel,
            content: await Loading.waitForPromises(form.generate({ translate: true }))
        });
        const $form = form.$form();
        await form.injectFormData({ formData });
        await form.initFormValidation();
        // const $form = events.$ele.find('form')
        // await AForm.injectFormData($form, { formData, formInputs })
        // await AForm.initFormValidation($form, formInputs)
        const $parentKey = $form.find('#ParentKey');
        const $optionCode = $form.find('#OptionCode');
        const $childInputs = $form.find('#IconCls,#Icon,#Visible,#AllowCancel,#OptionType,#EvidenceImagesMandatory,#ColorCls');
        $optionCode.on('keypress', function (e) {
            const c = e.which; // const key: string = String.fromCharCode(c).toLowerCase()
            const isNumeric = (c) => (c >= 48 && c <= 57);
            const isAlphabetic = (c) => (c >= 97 && c <= 122) || (c >= 65 && c <= 90);
            const isSpaceOrBackspace = (c) => (c === 95 || c === 8);
            const charAllowed = (isNumeric(c) || isAlphabetic(c) || isSpaceOrBackspace(c));
            if (!charAllowed || e.ctrlKey) {
                e.preventDefault();
                return false;
            }
        });
        const $optionCodePrefix = this.addPrefixInput({
            id: 'OptionCodePrefix',
            text: 'FO|' + Code.split('|').filter((v, i) => i > 1).join('|'),
            $input: $optionCode
        });
        const $formGroup = await Loading.waitForPromises(this.initTranslationInputs({ $form, $optionCode }));
        // Translation Initializer
        $optionCode.on('focusout', async (e) => {
            $optionCode.val($optionCode.val().toUpperCase());
            const textToTranslate = this.genOptionCode({ $form, $optionCodePrefix });
            if (textToTranslate.length === 0) {
                return;
            }
            try {
                const filterEmpty = ($inp) => $inp.attr('id').includes('-') && ($inp.val() || '').length === 0;
                const $inputs = $formGroup.find('input.is-error').toArray().map(inp => $(inp));
                // Translate 
                const inputs = await Loading.waitForPromises($inputs.filter(filterEmpty).map(async ($inp) => {
                    const lang = $inp.attr('id').split('-').pop();
                    return {
                        $inp,
                        lang,
                        val: await Translate.fetch(textToTranslate, { lang: lang })
                        // .then(v => v.toLowerCase().startsWith('fm|') ? v.substring(3) : v)
                    };
                }));
                // Set Translations
                inputs.map(({ $inp, val }) => { $inp.val(val); $inp.trigger('change'); });
            }
            catch (err) {
                AError.handleSilent(err);
            }
        });
        $parentKey.on('change', (e) => {
            const parentCode = $parentKey.val() || '';
            const hasParentCode = parentCode.length > 0 && parentCode !== 'null';
            $optionCodePrefix.text([
                hasParentCode ? 'FO' : 'FM',
                ...parentCode.split('|').filter((v, i) => i > 1)
            ].join('|') + '|');
            $form.find(':input').each((i, inp) => {
                const $inp = $(inp);
                if (!$inp.is($parentKey) && $childInputs.is($inp)) {
                    $inp.closest('.form-group').toggleClass('hidden', !hasParentCode);
                    // $inp.prop('disabled', !mustFollowUp)
                    $inp.trigger('change');
                }
            });
        });
        $parentKey.trigger('change');
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                if (!form.validate()) {
                    Alerts.incomplete();
                    return false;
                }
                const options = this.genCreateObject($form, {
                    ignoreWildcards: true,
                    $optionCodePrefix,
                    parent
                });
                AEngine.log('options', options);
                const success = await Loading.waitForPromises(this.followUpOrm.create(options));
                if (!success) {
                    Alerts.show({
                        title: ALERT_TITLES.Error,
                        content: await Translate.get(`${options.OptionKey || 'Entry'} Already Exists!`)
                    });
                }
                await Loading.waitForPromises([
                    this.followUpOrm.obfuscateItem(parent.data),
                    this.updateTranslations({ translationKey: options.OptionCode, $formGroup }),
                ]);
                // this.followUpOrm.obfuscateItem(parent.data.OptionCode, {
                //   OptionCode: parent.data.OptionCode,
                //   Usergroups: this.Usergroups.map(u => u.UserGroup),
                //   DeviceTypes: this.DeviceTypes as any,
                //   SessionModes: this.SessionModes as any,
                // })
                return success;
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async displayEditModal(gen, model) {
        const formData = model.data;
        let formInputs = await this.genFormInputs({ gen, record: formData, isCreateModal: false });
        const isRootItem = gen === 1;
        const excluded = ['AllowCancel', 'OptionType', 'EvidenceImagesMandatory', 'SessionModes', 'DeviceTypes', 'Usergroups'];
        if (isRootItem && !model.isLeaf) {
            formInputs = formInputs.filter(formInput => !excluded.includes(formInput.id));
        }
        const form = new AFormInstance({ formInputs, ignoreWildcards: true });
        const events = Alerts.show({
            translatedTitle: this.translations['Edit Follow Up Option'],
            buttons: ALERT_BUTTONS.saveCancel,
            content: await Loading.waitForPromises(form.generate({ translate: true }))
        });
        const $form = form.$form();
        await form.injectFormData({ formData });
        await form.initFormValidation();
        // const $form = events.$ele.find('form')
        // await AForm.injectFormData($form, { formData, formInputs })
        // await AForm.initFormValidation($form, formInputs)
        const $parentCode = $form.find('#ParentCode');
        const $childInputs = $form.find('#IconCls,#Icon,#Visible,#AllowCancel,#OptionType,#EvidenceImagesMandatory,#ColorCls');
        const $formGroup = await Loading.waitForPromises(this.initTranslationInputs({
            $form,
            $optionCode: $form.find('#OptionCode'),
            OptionCode: formData.OptionCode
        }));
        $parentCode.on('change', (e) => {
            const parentCode = $parentCode.val() || '';
            const hasParentCode = parentCode.length > 0 && parentCode !== 'null';
            $form.find(':input').each((i, inp) => {
                const $inp = $(inp);
                if (!$inp.is($parentCode) && $childInputs.is($inp)) {
                    $inp.closest('.form-group').toggleClass('hidden', !hasParentCode);
                    // $inp.prop('disabled', !mustFollowUp)
                    $inp.trigger('change');
                }
            });
        });
        $parentCode.trigger('change');
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                if (!form.validate()) {
                    Alerts.incomplete();
                    return false;
                }
                const options = AForm.extractFormData($form, { ignoreWildcards: true });
                options.OptionKey = formData.OptionKey;
                // const OptionKey = options.ParentKey + '|' + options.OptionCode
                // const OptionCode = $optionCodePrefix.text().trim() + options.OptionCode
                if (isRootItem) {
                    Object.assign(options, {
                        ColorCls: null,
                        Visible: null,
                        AllowCancel: null,
                        OptionType: null,
                        EvidenceImagesMandatory: null,
                        Channels: formData.Channels
                    });
                }
                else if (!model.isLeaf) {
                    Object.assign(options, {
                        AllowCancel: null,
                        OptionType: null,
                        EvidenceImagesMandatory: null
                    });
                }
                options.OrderPosition = formData.OrderPosition ?? null;
                const success = await Loading.waitForPromises(this.followUpOrm.update(options, { updateRestrictions: true }));
                if (!success) {
                    Alerts.show({
                        title: ALERT_TITLES.Error,
                        content: await Translate.get(`${options.OptionKey || 'Entry'} Already Exists!`)
                    });
                }
                await Loading.waitForPromises([
                    this.updateTranslations({ translationKey: options.OptionCode, $formGroup }),
                ]);
                return success;
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async displayDeleteModal(record) {
        const alert = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Delete Follow Up Option')),
            buttons: ALERT_BUTTONS.yesNo,
            content: await Loading.waitForPromises(Translate.get(/*html*/ `
        Are you sure you want to delete this follow up option?
      `))
        });
        alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                await Loading.waitForPromises(this.followUpOrm.delete(record));
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    convertListToTree(list, prevStore) {
        const prevTree = prevStore?.allRecords;
        const l = list;
        var map = {}, node, roots = [], i;
        for (i = 0; i < l.length; i++) {
            map[l[i].OptionKey] = i;
        }
        for (i = 0; i < l.length; i++) {
            node = l[i];
            node.collapsible = false;
            if (node.ParentKey !== null) {
                if (map[node.ParentKey] !== undefined && l[map[node.ParentKey]] !== undefined) {
                    if (l[map[node.ParentKey]].children === undefined) {
                        l[map[node.ParentKey]].children = [];
                        l[map[node.ParentKey]].expanded = true;
                    }
                    node.OrderPosition = l[map[node.ParentKey]].children.length;
                    l[map[node.ParentKey]].children.push(node);
                }
            }
            else {
                node.OrderPosition = i;
                roots.push(node);
            }
        }
        if (prevTree !== undefined) {
            for (i = 0; i < l.length; i++) {
                const found = prevTree.find(r => r.data.OptionKey === l[i].OptionKey);
                if (found !== undefined) {
                    l[i].expanded = (found.isExpanded(this.grid.store));
                }
            }
        }
        return roots;
    }
    async refresh() {
        try {
            validateEnforcementProcessService.validate();
            const ares = await this.followUpOrm.fetchAll();
            ares.addColumns(['ActionDown', 'ActionUp', 'ActionEdit', 'ActionDelete']);
            // Process translations for OptionCodes, SessionModes, DeviceTypes & Channels
            await Loading.waitForPromises(Translate.get(ares.map(v => ([
                v.OptionCode,
                ...(v.Channels || []),
                ...(v.SessionModes || []),
                ...(v.DeviceTypes || []),
            ])).flat().filter(v => v != null)));
            const tree = this.convertListToTree(ares.map(v => v), this.grid?.store);
            const response = ares.Original;
            const COLUMN_BOOL_TREE = {
                htmlEncode: false,
                cellCls: 'np-i text-center',
                align: 'center',
                renderer: ({ value, record }) => {
                    if (record.isLeaf && value === null) {
                        const textCls = transformTextCls('error');
                        return ( /*html*/`
              <i class="fa-regular fa-triangle-exclamation ${textCls}"></i>
            `);
                    }
                    return COLUMN_BOOLEAN_NULLABLE.renderer({ value });
                }
            };
            // appendResponseRows(response, ['ActionDown', 'ActionUp', 'ActionEdit', 'ActionDelete'])
            if (this.grid) {
                // this.grid.store.removeAll(true)
                // tree.map()
                const scrollTop = this.grid.storeScroll();
                this.grid.store.data = tree;
                this.grid.restoreScroll(scrollTop);
                // this.grid.store.add(tree)
                // this.grid.refreshRows()
            }
            else {
                this.grid = AShowTable({
                    appendTo: 'bryntum-table',
                    aci: {
                        resizeToFit: true,
                        resizeToFitReverse: true,
                        skipResizeColumns: [5, 6, 7],
                        flex: 1,
                    },
                    features: {
                        search: true,
                        tree: true,
                    },
                    tbar: [
                        {
                            type: 'text',
                            ref: 'searchField',
                            clearable: true,
                            label: '<i class="b-icon b-icon-search"></i>',
                            showHitIndex: false,
                            listeners: {
                                // input: 'onSearchFieldInput',
                                change: 'onSearchFieldChange',
                                clear: 'onSearchFieldClear',
                                thisObj: this.searchHandler.bind({ ref: 'searchField' })
                            }
                        },
                    ],
                    selectionMode: {
                        multiSelect: false,
                        deselectOnClick: true,
                    },
                    columns: AConvertToGridColumns(response, {
                        'OptionCode': {
                            type: 'tree',
                            flex: 2,
                            collapsible: false,
                            htmlEncode: false,
                            renderer: ({ value, record }) => {
                                const iconCls = record.data.IconCls;
                                const iconHtml = iconCls ? ( /*html*/`<i style="padding-right: 8px;" class="${iconCls}"></i>`) : '';
                                return ( /*html*/`
                  ${iconHtml}
                  <span>${Translate.getCache(record.data.OptionCode)}</span>
                  <section style="padding: 0 8px; cursor: pointer;" class="b-fa b-fa-plus ${transformTextCls('green')}"></section>
                `);
                            }
                        },
                        'OptionType': COLUMN_HIDDEN,
                        'Icon': COLUMN_HIDDEN,
                        'IconCls': COLUMN_HIDDEN,
                        'ParentKey': COLUMN_HIDDEN,
                        'OptionKey': COLUMN_HIDDEN,
                        'Channels': {
                            ...COLUMN_MULTI_SELECT_TRANSLATE,
                        },
                        // 'Channel': {
                        //   htmlEncode: false,
                        //   renderer: ({ value, record }) => {
                        //     return (/*html*/`${value ? this.translations[value] || '' : ''}`)
                        //   }
                        // },
                        'ColorCls': {
                            htmlEncode: false,
                            cellCls: 'np-i',
                            renderer: ({ value, record }) => {
                                let curr = record;
                                let colorCls = value;
                                let i = 0;
                                do {
                                    colorCls = curr?.data?.ColorCls;
                                    curr = curr?.parent;
                                } while (colorCls == null && curr != null && i++ < 20);
                                if (colorCls) {
                                    const clr = transformButtonCls(colorCls);
                                    return (`<button style="text-transform: UPPERCASE;" class="grid-btn-bottomline btn ${clr} fw fh-i">${value || ''}</button>`);
                                }
                                return '';
                            }
                        },
                        'Visible': COLUMN_BOOL_TREE,
                        'AllowCancel': COLUMN_BOOL_TREE,
                        'EvidenceImagesMandatory': COLUMN_BOOL_TREE,
                        'OrderPosition': COLUMN_HIDDEN,
                        'Usergroups': COLUMN_MULTI_SELECT,
                        'SessionModes': COLUMN_MULTI_SELECT,
                        'DeviceTypes': COLUMN_MULTI_SELECT,
                        'ExternalId': COLUMN_TEXT,
                        'ActionUp': COLUMN_ACTION({ iconCls: 'fa-solid fa-chevron-up', btnCls: 'btn-white' }),
                        'ActionDown': COLUMN_ACTION({ iconCls: 'fa-solid fa-chevron-down', btnCls: 'btn-white' }),
                        'ActionEdit': COLUMN_ACTION({ iconCls: 'fa-solid fa-pencil text-primary', btnCls: 'btn-white' }),
                        'ActionDelete': COLUMN_ACTION({ iconCls: 'fa-regular fa-trash text-red', btnCls: 'btn-white' }),
                    }),
                    data: tree
                });
                // this.grid.on('catchAll', (e) => console.log('grid event: ', e.type, e))
                this.grid.on('search', (e) => this.searchHandler.applyFilter(e));
                this.grid.on('clearsearch', (e) => this.searchHandler.onSearchFieldClear(e));
                this.grid.on('cellclick', ({ record, column, target }) => {
                    console.log(record.data);
                    if (record.id && record.id.indexOf('group-header') !== -1) {
                        return;
                    }
                    const gen = this.calcGeneration(record);
                    AEngine.log('Generation', gen);
                    switch (column.data.field) {
                        case 'ActionUp':
                        case 'ActionDown':
                            const sortedChildren = ([...record.parent.children]).sort((a, b) => {
                                return (a.data?.OrderPosition ?? 0) - (b.data?.OrderPosition ?? 0);
                            });
                            let index = sortedChildren.findIndex((c) => c.data?.OptionKey === record.data.OptionKey);
                            const siblingDirection = column.data.field === 'ActionUp' ? -1 : 1;
                            const sibling = sortedChildren[index + siblingDirection];
                            if (index !== -1 && sibling !== undefined) {
                                record.set('OrderPosition', index + siblingDirection);
                                sibling.set('OrderPosition', index);
                                Loading.waitForPromises([
                                    this.followUpOrm.update(record.data, { updateRestrictions: false }),
                                    this.followUpOrm.update(sibling.data, { updateRestrictions: false })
                                ]).catch(err => {
                                    record.set('OrderPosition', record.originalData.OrderPosition);
                                    sibling.set('OrderPosition', sibling.originalData.OrderPosition);
                                    AError.handle(err);
                                }).finally(() => {
                                    this.grid.store.sort('OrderPosition', true);
                                });
                            }
                            break;
                        case 'OptionCode':
                            if ($(target).is('.b-fa-plus')) {
                                this.displayCreateModal(gen + 1, record);
                            }
                            break;
                        case 'ActionEdit':
                            this.displayEditModal(gen, record);
                            break;
                        case 'ActionDelete':
                            this.displayDeleteModal(record.data);
                            break;
                    }
                });
                this.grid.store.sort('OrderPosition', true);
            }
        }
        catch (err) {
            AError.handle(err);
        }
    }
}
export function css() {
    return ( /*html*/`
  <style>
    #OptionCode {
      text-transform: UPPERCASE;
    }
  </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Rapport" class="flex-child bryntum-container has-footer-2" style="width: 100%">
      <div class="fh">
        <div class="fh" style="overflow-y: auto">
          <div class="aci-tabs hidden" tabgroup="views">
            <button class="aci-tab active" tab="tab-grid-view"><span>Grid View</span></button>
            <button class="aci-tab" tab="tab-form-view"><span>Form View</span></button>
          </div>
          <div class="columns col-gapless fh">
            <div class="column col-12">
              <div tabgroup="views" tabview="tab-grid-view" class="fh">
                <div id="bryntum-table" class="fh"></div>
              </div>
              <div tabgroup="views" tabview="tab-form-view" class="fh" style="overflow-y: auto;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div tabgroup="views" tabview="tab-grid-view">
        <div class="columns footer aci">
          <div class="column col-2">
            <div id="count" class="text">Viewing <span>0</span> Records</div>
          </div>
          <div class="column col-2 col-ml-auto">
            <button id="create-btn" class="btn btn-primary col-12 hidden">Create Follow Up Option</button>
          </div>
        </div>
      </div>
    </div>
  `);
}
