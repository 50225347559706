import { AError } from "../../classes/AError.js";
import { COLUMN_ACTION, COLUMN_BOOLEAN, COLUMN_BOOLEAN_NULLABLE, COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_PRICE_NO_CURR, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { ASearchHandler } from "../../classes/grid/ASearchHandler.js";
import { AChannelOrm } from "../../orm/AChannelOrm.js";
import { AFineGroupOrm } from "../../orm/AFineGroupOrm.js";
import { AOffenceOrm } from "../../orm/AOffenceOrm.js";
import { APrdbOrm } from "../../orm/APrdbOrm.js";
import { ARegimeOrm } from "../../orm/ARegimeOrm.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable, formatPrice, transformTextCls } from "../../utils/tools.js";
import { AFormInstance } from "../../core/form/AFormInstance.js";
import { AEngine } from "../../core/AEngine.js";
var AOrmEnum;
(function (AOrmEnum) {
    AOrmEnum[AOrmEnum["All"] = -1] = "All";
    AOrmEnum[AOrmEnum["Channels"] = 2] = "Channels";
    AOrmEnum[AOrmEnum["Offences"] = 4] = "Offences";
    AOrmEnum[AOrmEnum["Prdbs"] = 8] = "Prdbs";
    AOrmEnum[AOrmEnum["FineGroups"] = 16] = "FineGroups";
})(AOrmEnum || (AOrmEnum = {}));
export class APage {
    constructor() {
        this.regimeOrm = new ARegimeOrm();
        this.channelOrm = new AChannelOrm();
        this.offenceOrm = new AOffenceOrm();
        this.prdbOrm = new APrdbOrm();
        this.fineGroupOrm = new AFineGroupOrm();
        this.searchHandler = new ASearchHandler();
    }
    async init() {
        $('#create-btn').on('click', (e) => this.displayCreateModal());
        await this.refreshOptionsOrm(AOrmEnum.All);
        Loading.waitForPromises(this.refresh());
    }
    async refreshOptionsOrm(options) {
        if (options & AOrmEnum.Channels) {
            this.channels = await this.channelOrm.fetchAll();
            this.channelOptions = [
                { id: 'null', text: 'None' },
                ...this.channels.map(v => {
                    return {
                        id: v.ChannelCode,
                        text: v.ChannelName
                    };
                })
            ];
        }
        if (options & AOrmEnum.Offences) {
            this.offences = await this.offenceOrm.fetchAll();
            this.offenceOptions = [
                { id: 'null', text: 'None' },
                ...this.offences.map((o) => {
                    return {
                        id: o.OffenceCode,
                        text: `${o.OffenceCode} | ${o.OffenceText} (${formatPrice(o.OffencePrice)})`
                    };
                })
            ];
        }
        if (options & AOrmEnum.Prdbs) {
            this.prdbs = await this.prdbOrm.fetchAll();
            this.prdbOptions = this.prdbs.map((prdb) => {
                return {
                    id: prdb.PrdbCode,
                    text: `${prdb.PrdbCode} ${prdb.PrdbText}`,
                    checked: false
                };
            });
        }
        if (options & AOrmEnum.FineGroups) {
            this.fineGroups = await this.fineGroupOrm.fetchAll();
            this.fineGroupOptions = [
                { id: 'null', text: 'None' },
                ...this.fineGroups.map((fineGroup) => {
                    return {
                        id: fineGroup.FineGroupCode,
                        text: `${fineGroup.FineGroupCode} ${fineGroup.FineGroupText}`
                    };
                })
            ];
        }
    }
    async displayCreateModal(record) {
        await this.refreshOptionsOrm(AOrmEnum.All);
        let prdbCodeOptions = this.prdbOptions.map(prdbOpt => Object.assign({ checked: false }, prdbOpt));
        if (record !== undefined) {
            const linkedPrdbCodes = await this.regimeOrm.fetchRegimePrdbLinks({ RegimeCode: record.RegimeCode })
                .then(res => res.map(prdbLink => prdbLink.PrdbCode));
            prdbCodeOptions = prdbCodeOptions.map(prdbOpt => {
                if (linkedPrdbCodes.length === 0) {
                    prdbOpt.checked = false;
                }
                else if (linkedPrdbCodes.includes(prdbOpt.id)) {
                    prdbOpt.checked = true;
                }
                return prdbOpt;
            });
        }
        const form = new AFormInstance({
            defaultValue: this.regimeOrm.emptyRegime,
            ignoreWildcards: false,
            formInputs: [
                { id: 'RegimeCode', type: 'text', minlength: 1, maxlength: 50, /*disabled: record !== undefined*/ },
                { id: 'RegimeText', type: 'text', minlength: 0, maxlength: 50, },
                { id: 'HasCapacity', type: 'checkbox' },
                { id: 'MustFollowUp', type: 'checkbox' },
                { id: 'IsRegimeChangeOption', type: 'checkbox' },
                { id: 'ForceFollowUp', type: 'checkbox', isDisabled: ({ formData }) => !formData.MustFollowUp },
                { id: 'PrdbCheck', type: 'checkbox', isDisabled: ({ formData }) => !formData.MustFollowUp },
                {
                    id: 'LinkedPrdbCodes',
                    type: 'multiselect',
                    options: prdbCodeOptions.map((prdbCodeOpt) => {
                        return prdbCodeOpt;
                    }),
                    disallowNone: this.prdbs.Size > 0,
                    overrideHasError: ($inp, hasError) => {
                        return this.prdbs.Size === 0 ? false : (hasError && $inp.closest('form').find('#PrdbCheck').prop('checked'));
                    },
                    isDisabled: ({ formData }) => !formData.MustFollowUp || !formData.PrdbCheck
                },
                { id: 'EnableDefaultMaxStayDuration', type: 'checkbox', width: 'col-auto', hideLabel: true },
                {
                    id: 'DefaultMaxStayDuration',
                    type: 'duration',
                    value: '01:30:00',
                    // disabled: record?.EnableDefaultMaxStayDuration,
                    nullIfDisabled: true,
                    isDisabled: ({ formData }) => !formData.EnableDefaultMaxStayDuration,
                    width: 'col'
                },
                { id: 'RegimeType', type: 'select', options: ['TaxParking', 'IllegalParking', 'TimeLimitedParking'], isDisabled: ({ formData }) => !formData.MustFollowUp },
                {
                    id: 'OffenceCode',
                    type: 'select',
                    options: this.offenceOptions,
                    disallowNone: true,
                    overrideHasError: ($inp, hasError) => {
                        return this.offences.Size === 0 ? false : (hasError && $inp.closest('form').find('#MustFollowUp').prop('checked'));
                    },
                    isDisabled: ({ formData }) => !formData.MustFollowUp
                },
                {
                    id: 'ChannelCode',
                    type: 'select',
                    options: this.channelOptions,
                    disallowNone: true,
                    overrideHasError: ($inp, hasError) => {
                        return this.channels.Size === 0 ? false : hasError && $inp.closest('form').find('#MustFollowUp').prop('checked');
                    },
                    isDisabled: ({ formData }) => !formData.MustFollowUp
                },
                {
                    id: 'FineGroupCode',
                    type: 'select',
                    options: this.fineGroupOptions,
                    disallowNone: true,
                    overrideHasError: ($inp, hasError) => {
                        return this.fineGroups.Size === 0 ? false : hasError && $inp.closest('form').find('#MustFollowUp').prop('checked');
                    },
                    isDisabled: ({ formData }) => !formData.MustFollowUp
                }
            ]
        });
        const events = Alerts.show({
            title: ALERT_TITLES.Info,
            buttons: ALERT_BUTTONS.saveCancel,
            content: await Loading.waitForPromises(form.generate({ translate: true, wrapInColumns: true }))
        });
        await form.injectFormData({ formData: record });
        await form.initFormValidation();
        AEngine.log('regimes.form', form);
        // await AForm.injectFormData($form, {formData: record, formInputs })
        // await AForm.initFormValidation<ARegime>($form, formInputs)
        const $form = form.$form();
        $form.find('#MustFollowUp').trigger('change');
        $form.find('#PrdbCheck').trigger('change');
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                if (!AFormInstance.validate($form)) {
                    Alerts.incomplete();
                    return false;
                }
                let options = form.extractFormData({ cleanData: true });
                // let options: ARegimeSaturated = AForm.extractFormData($form, { ignoreWildcards: false }) as any
                const ormResponse = await Loading.waitForPromises(record === undefined ? this.regimeOrm.create(options) : this.regimeOrm.update(record.RegimeCode, options));
                if (!ormResponse.success) {
                    Alerts.show({
                        title: ALERT_TITLES.Error,
                        content: ormResponse.msg[0] || await Translate.get(`Something went wrong!`)
                    });
                }
                return ormResponse.success;
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async displayDeleteModal(record) {
        const alert = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Delete Regime')),
            buttons: ALERT_BUTTONS.deleteCancel,
            content: await Loading.waitForPromises(Translate.get(/*html*/ `
        Are you sure you want to delete this Regime?
      `))
        });
        alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                await Loading.waitForPromises(this.regimeOrm.delete(record));
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async refresh() {
        try {
            validateEnforcementProcessService.validate();
            const ares = await this.regimeOrm.fetchAll();
            // ares.addColumns(['OffenceText', 'OffenceDescription'], ares.Columns.indexOf('OffenceCode')+1)
            ares.addColumns(['ActionEdit', 'ActionDelete']);
            const response = ares.Original;
            // appendResponseRows(response, ['ActionEdit', 'ActionDelete'])
            const data = AConvertToGridData(response, {
                'ModificationTime': DATA_DATETIME
            });
            if (this.grid) {
                const scrollTop = this.grid.storeScroll();
                this.grid.store.data = data;
                this.grid.restoreScroll(scrollTop);
            }
            else {
                this.grid = AShowTable({
                    appendTo: 'bryntum-table',
                    aci: {
                        resizeToFit: true,
                        // resizeToFitReverse: true,
                        flex: 1,
                    },
                    features: {
                        search: true
                    },
                    tbar: [
                        {
                            type: 'text',
                            ref: 'searchField',
                            clearable: true,
                            label: '<i class="b-icon b-icon-search"></i>',
                            showHitIndex: false,
                            listeners: {
                                // input: 'onSearchFieldInput',
                                change: 'onSearchFieldChange',
                                clear: 'onSearchFieldClear',
                                thisObj: this.searchHandler.bind({ ref: 'searchField' })
                            }
                        },
                    ],
                    selectionMode: {
                        multiSelect: false
                    },
                    columns: AConvertToGridColumns(response, {
                        'HasCapacity': COLUMN_BOOLEAN,
                        'MustFollowUp': COLUMN_BOOLEAN_NULLABLE,
                        'IsRegimeChangeOption': COLUMN_BOOLEAN,
                        'RegimeTypeTranslated': COLUMN_HIDDEN,
                        'RegimeType': {
                            htmlEncode: true,
                            renderer: ({ record, value }) => record.MustFollowUp ? record.RegimeTypeTranslated : ''
                        },
                        'ChannelCodeTranslated': COLUMN_HIDDEN,
                        'ChannelCode': {
                            htmlEncode: false,
                            renderer: ({ record, value }) => record.MustFollowUp ? record.ChannelCodeTranslated : ''
                        },
                        'FineGroupCode': COLUMN_HIDDEN,
                        'FineGroup': {
                            htmlEncode: false,
                            renderer: ({ record, value }) => record.FineGroup ? record.FineGroup : ''
                        },
                        'ForceFollowUp': {
                            htmlEncode: false,
                            renderer: ({ record, value }) => COLUMN_BOOLEAN_NULLABLE.renderer({ value: record.MustFollowUp ? value : null })
                        },
                        'PrdbCheck': {
                            htmlEncode: false,
                            renderer: ({ record, value }) => COLUMN_BOOLEAN_NULLABLE.renderer({ value: record.MustFollowUp ? value : null })
                        },
                        'EnableDefaultMaxStayDuration': COLUMN_HIDDEN,
                        // TODO: Uncomment
                        // 'DefaultMaxStayDuration': {
                        //   ...COLUMN_TIME,
                        //   htmlEncode: false,
                        //   renderer: ({ record, value }) => record.MustFollowUp ? value : null
                        // },
                        'LinkedPrdbs': {
                            type: 'number',
                            htmlEncode: false,
                            renderer: ({ record, value }) => {
                                if (record.MustFollowUp && record.PrdbCheck) {
                                    const cls = (value == 0) ? transformTextCls('red') : '';
                                    return ( /*html*/`<span class="${cls}">${value}</span>`);
                                }
                                return '';
                            }
                        },
                        'IsInUse': {
                            htmlEncode: COLUMN_BOOLEAN.htmlEncode,
                            renderer: ({ value, rowElement }) => {
                                rowElement.style.opacity = value ? '1.0' : '0.6';
                                return COLUMN_BOOLEAN.renderer({ value });
                            }
                        },
                        'OffenceCode': {
                            htmlEncode: false,
                            renderer: ({ record, value }) => {
                                return record.MustFollowUp ? value ?? '' : '';
                            }
                        },
                        'OffenceText': {
                            htmlEncode: false,
                            renderer: ({ record }) => {
                                return record.MustFollowUp ? this.offences.find(o => o.OffenceCode === record.OffenceCode)?.OffenceText ?? '' : '';
                            }
                        },
                        'OffenceDescription': {
                            ...COLUMN_HIDDEN,
                            width: 200,
                            htmlEncode: false,
                            renderer: ({ record }) => record.MustFollowUp ?
                                this.offences.find(o => o.OffenceCode === record.OffenceCode)?.OffenceDescription ?? '' : ''
                        },
                        'OffencePrice': {
                            htmlEncode: false,
                            renderer: ({ record }) => record.MustFollowUp ? COLUMN_PRICE_NO_CURR.renderer({
                                value: this.offences.find(o => o.OffenceCode === record.OffenceCode)?.OffencePrice ?? ''
                            }) : ''
                        },
                        'ModificationUser': COLUMN_TEXT,
                        'ModificationDevice': COLUMN_HIDDEN,
                        'ModificationTime': COLUMN_DATETIME,
                        'ActionEdit': COLUMN_ACTION({
                            iconCls: 'fa-solid fa-pencil text-primary',
                            btnCls: 'btn-white',
                            onClick: ({ record }) => {
                                this.displayCreateModal(record.data);
                            },
                        }),
                        'ActionDelete': COLUMN_ACTION({
                            iconCls: 'fa-regular fa-trash text-red',
                            btnCls: 'btn-white',
                            onClick: ({ record }) => {
                                this.displayDeleteModal(record.data);
                            },
                        }),
                    }),
                    data: data,
                });
                this.grid.on('search', (e) => this.searchHandler.applyFilter(e));
                this.grid.on('clearsearch', (e) => this.searchHandler.onSearchFieldClear(e));
            }
        }
        catch (err) {
            AError.handle(err);
        }
    }
}
export function render() {
    return ( /*html*/`
    <div id="Rapport" class="flex-child bryntum-container has-footer-2" style="width: 100%">
      <div class="fh">
        <div class="fh" style="overflow-y: auto">
          <div class="aci-tabs hidden" tabgroup="views">
            <button class="aci-tab active" tab="tab-grid-view"><span>Grid View</span></button>
            <button class="aci-tab" tab="tab-form-view"><span>Form View</span></button>
          </div>
          <div class="columns col-gapless fh">
            <div class="column col-12">
              <div tabgroup="views" tabview="tab-grid-view" class="fh">
                <div id="bryntum-table" class="fh"></div>
              </div>
              <div tabgroup="views" tabview="tab-form-view" class="fh" style="overflow-y: auto;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div tabgroup="views" tabview="tab-grid-view">
        <div class="columns footer aci">
          <div class="column col-2">
            <div id="count" class="text">Viewing <span>0</span> Regimes</div>
          </div>
          <div class="column col-2 col-ml-auto">
            <button id="create-btn" class="btn btn-primary col-12">Create Regime</button>
          </div>
        </div>
      </div>
    </div>
  `);
}
