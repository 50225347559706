import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.refresh().catch(AError.handle);
    }
    updateChecked(record) {
        const { Id, Importance, Message, Checked, CreatedAt } = record;
        Loading.waitForPromises(requestService.query({
            Query: (`UPDATE admin_alerts SET Checked=:Checked WHERE Id=:Id`),
            Params: {
                Checked: Checked,
                Id: Id
            }
        })).then(() => {
            return adminAlertsService.refresh();
        });
    }
    async refresh() {
        const translations = await Loading.waitForPromises(Translate.get([
            'Visit Page'
        ]));
        return Loading.waitForPromises(requestService.query({
            Query: ( /*SQL*/`
          SELECT Id, Importance, Checked, Message, CreatedAt, id_mr as Actions
          FROM admin_alerts
          ORDER BY id DESC
          LIMIT 1000
        `),
            Language: Language
        })).then((response) => {
            const columns = AConvertToGridColumns(response, {
                'Id': { hidden: true },
                'Importance': { hidden: true },
                'Checked': {
                    type: 'widget',
                    width: 60,
                    widgets: [{
                            type: 'checkbox',
                            field: 'Checked',
                            onAction: (data) => {
                                if (data.userAction) {
                                    const { record } = data.source.cellInfo;
                                    record.Checked = record.Checked === 1 ? 0 : 1;
                                    this.updateChecked(data.source.cellInfo.record);
                                }
                            }
                        }]
                },
                'Message': COLUMN_TEXT,
                'CreatedAt': { region: 'right', ...COLUMN_DATETIME },
                'id_mr': COLUMN_HIDDEN,
                'Actions': {
                    htmlEncode: false,
                    cellCls: 'np-i',
                    region: 'right',
                    renderer: function ({ record, value, size }) {
                        const id_mr = value;
                        return (id_mr) ? ( /*html*/`
              <button onclick="menuHelperService.pressMenuItem('${id_mr}')" class="fw b-blue b-raised b-widget b-button b-outer b-visible-scrollbar b-chrome b-icon-align-start b-text">
                <i class="fa-solid fa-arrow-up-right-from-square mr-1"></i> 
                ${menuHelperService.getMenuItem(id_mr)?.title ?? translations['Visit Page']}
              </button>
            `) : undefined;
                    }
                }
            });
            const data = AConvertToGridData(response, {
                'CreatedAt': DATA_DATETIME,
                'Actions': (id_mr) => id_mr,
            });
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                aci: {
                    flex: 1,
                    resizeToFit: true,
                    resizeToFitReverse: true,
                    skipResizeColumns: ['Message']
                },
                columns,
                data,
                features: {
                    cellMenu: {
                        items: {
                            history: false
                        }
                    }
                }
            });
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Rapport" class="Report bryntum-container full-height-overflow">
      <div id="table-bryntum"></div>
    </div>
  `);
}
